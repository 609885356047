import React, { useContext, useState } from "react";
import {Table, TableRow, TableCell, TableHead, TableBody, TableContainer, Paper} from "@mui/material"
import { survivalTimerConverter, characterIconSelect } from "../../../../common/pubFuncs";
import httpCommon from "../../../../http-common";
import { MobileContext } from "../../../../AppFunctional";
import { ConfirmationButton } from "../../../../common/components/Buttons/ConfirmationButton";
import { ETERNAL_GREEN } from "../../../../common/globalConsts.ts";

export const PlayerTable = ({matchData, isBinding, onClick}) => {

    const [currentSort, setCurrentSort] = useState("teamPlacement");
    const [reversed, setReversed] = useState(false)

    const isMobile = useContext(MobileContext);
    

    matchData.sort((a,b) => a[currentSort] - b[currentSort]);
    if(reversed)
        matchData.reverse();

    function handleSortClick(sort){
        if(currentSort === sort){
            setReversed(!reversed);
        } else {
            setCurrentSort(sort)
        }
    }

    return (
        <TableContainer component={Paper} elevation={5} sx={{overflowY: "auto", overflowX: "hidden",  height: isBinding ? '55vh' : matchData.length > 5 ? '65vh' : 'auto'}}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        {
                            isBinding ? 
                                <TableCell key={"placementh: "} onClick={() => handleSortClick("teamName")}>Team Name</TableCell>
                                :
                                <TableCell key={"placementh: "} onClick={() => handleSortClick("teamPlacement")}>Placement</TableCell>

                        }
                        <TableCell key={"headerh: "} onClick={() => handleSortClick("playerName")}>Player Name</TableCell>
                        {
                            isMobile ? "" :
                            <TableCell key={"legendh: "} onClick={() => handleSortClick("characterName")}>Legend</TableCell>
                        }
                        {
                            isBinding ? 
                                <TableCell sx={{textAlign: 'center'}} key='bindH'>Bind</TableCell>
                            :
                            [
                                <TableCell sx={{textAlign: "right"}} key={"killsh: "} onClick={() => handleSortClick("kills")}>Kills</TableCell>,
                                <TableCell sx={{textAlign: "right"}} key={"damageh: "} onClick={() => handleSortClick("damageDealt")}>Damage</TableCell>,
                                <TableCell sx={{textAlign: "right"}} key={"survivalh: "} onClick={() => handleSortClick("survivalTime")}>Survival</TableCell>,
                                <TableCell sx={{textAlign: "right"}} key={"shotsh: "} onClick={() => handleSortClick("shots")}>Shots</TableCell>,
                                <TableCell sx={{textAlign: "right"}} key={"hitsh: "} onClick={() => handleSortClick("hits")}>Hits</TableCell>,
                                <TableCell sx={{textAlign: "right"}} key={"headshotsh: "} onClick={() => handleSortClick("headshots")}>Headshots</TableCell>,
                                <TableCell sx={{textAlign: "right"}} key={"knockdownsh: "} onClick={() => handleSortClick("knockdowns")}>Knockdowns</TableCell>,
                                <TableCell sx={{textAlign: "right"}} key={"revivesh: "} onClick={() => handleSortClick("revivesGiven")}>Revives</TableCell>,
                                Object.keys(matchData[0]).includes('mmr') ? 
                                <TableCell sx={{textAlign: "right"}} key={"mmrh: "} onClick={() => handleSortClick("mmr")}>LP</TableCell>
                                :
                                ''
                            ]
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        matchData.map(row => {
                            if(!isBinding || row.discordID === -1){
                                return (
                                    <TableRow onClick={isBinding ? console.log("") : async () => {
                                        try {
                                            const {data: player} = await httpCommon.get("/players/id?discordID=" + row.discordID);
                                            if(player)
                                                window.location.href = "/player/" + player.discordName;
                                        } catch(err){}
                                    }}> 
                                        {
                                            isBinding ?
                                                <TableCell className="teamname" key = {"teamname: "}>{row.teamName}</TableCell> 
                                                :
                                                <TableCell className="placement" key = {"placement: "}>{row.teamPlacement}</TableCell>


                                        }
                                        <TableCell className = "playername" key = {"pName: "}>{row.playerName}</TableCell>
                                        {
                                            isMobile ?  
                                            "" :
                                            <TableCell className = "character" key = {"char: " }>
                                                <img src = {characterIconSelect(row.characterName)} alt="" width={"25px"} height={"25px"} style={{filter: "invert(100)"}}></img>
                                            </TableCell>
                                        }
                                        { 
                                            isBinding ?                  
                                                <TableCell sx={{textAlign: 'center'}} key={'bind' + row.discordID}>
                                                    <ConfirmationButton onClick={() => onClick({playerName: row.playerName, teamName: row.teamName})} text={row.discordID === -1 ? "Bind" : "Bound"} color='eternal' variant='outlined'>
                                                        Are you sure you want to bind your Discord account to <span style={{fontWeight: 'bold', fontSize: '1.5em', fontFamily: "monospace", color: ETERNAL_GREEN}}>{row.playerName}</span><br/>
                                                        Once bound, you <span style={{fontWeight: 'bold', fontSize: '1.2em'}}>cannot</span> unbind without creating a ticket.  
                                                    </ConfirmationButton>
                                                </TableCell> 
                                            :
                                            [
                                                <TableCell sx={{textAlign: "right"}} className = "kills" key = {"kills: "}>{row.kills}</TableCell>,
                                                <TableCell sx={{textAlign: "right"}} className = "dmg" key = {"dmg: "}>{row.damageDealt}</TableCell>,
                                                <TableCell sx={{textAlign: "right"}} className = "survTime" key = {"surv: "}>{survivalTimerConverter(row.survivalTime)}</TableCell>,
                                                <TableCell sx={{textAlign: "right"}} className = "shots" key = {"shots : "}>{row.shots}</TableCell>,
                                                <TableCell sx={{textAlign: "right"}} className = "hits" key = {"hits: "}>{row.hits}</TableCell>,
                                                <TableCell sx={{textAlign: "right"}} className = "hs" key = {"hs : "}>{row.headshots}</TableCell>,
                                                <TableCell sx={{textAlign: "right"}} className = "kds" key = {"kds: "}>{row.knockdowns}</TableCell>,
                                                <TableCell sx={{textAlign: "right"}} className = "revives" key = {"rev: "}>{row.revivesGiven}</TableCell>,
                                                Object.keys(matchData[0]).includes('mmr') ? 
                                                    <TableCell sx={{textAlign: "right"}} className = "mmr" key = {"mmr: "}>{row.mmr}</TableCell>
                                                    :
                                                    ''
                                            ]
                                        }
                                    </TableRow>
                                );
                            } else return ""
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}