// rank icons
export const TierS = require("./rank_icons/x100/TierS_x100.png");
export const TierG = require("./rank_icons/x100/TierG_x100.png");
export const TierA = require("./rank_icons/x100/TierA_x100.png");
export const TierB = require("./rank_icons/x100/TierB_x100.png");
export const TierC = require("./rank_icons/x100/TierC_x100.png");
export const TierD = require("./rank_icons/x100/TierD_x100.png");
export const TierE = require("./rank_icons/x100/TierE_x100.png");
export const TierF = require("./rank_icons/x100/TierF_x100.png");
// top icons
export const Top1 = require("./rank_icons/x2100/Top1.png");
export const Top20 = require("./rank_icons/x2100/Top20.png");

// chevrons
export const UpChevron = require("./icons/up-arrow.png");
export const DashChevron = require("./icons/stephen.png");
export const DownChevron = require("./icons/down-arrow.png");

// arrows
export const DownArrow = require("./icons/down-arrow.png")

// legend images
export const Alter = require("./character_portraits/alter_big.png");
export const Ash = require("./character_portraits/ash_big.png");
export const Ballistic = require("./character_portraits/ballistic_big.png");
export const Bangalore = require("./character_portraits/bangalore_big.png");
export const Bloodhound = require("./character_portraits/bloodhound_big.png");
export const Catalyst = require("./character_portraits/catalyst_big.png");
export const Caustic = require("./character_portraits/caustic_big.png");
export const Conduit = require("./character_portraits/conduit_big.png");
export const Crypto = require("./character_portraits/crypto_big.png");
export const Fuse = require("./character_portraits/fuse_big.png");
export const Gibraltar = require("./character_portraits/gibraltar_big.png");
export const Horizon = require("./character_portraits/horizon_big.png");
export const Lifeline = require("./character_portraits/lifeline_big.png");
export const Loba = require("./character_portraits/loba_big.png");
export const MadMaggie = require("./character_portraits/maggie_big.png");
export const Mirage = require("./character_portraits/mirage_big.png");
export const Newcastle = require("./character_portraits/newcastle_big.png");
export const Octane = require("./character_portraits/octane_big.png");
export const Pathfinder = require("./character_portraits/pathfinder_big.png");
export const Rampart = require("./character_portraits/rampart_big.png");
export const Revenant = require("./character_portraits/revenant_big.png");
export const Seer  = require("./character_portraits/seer_big.png");
export const Valkyrie = require("./character_portraits/valkyrie_big.png");
export const Vantage = require("./character_portraits/vantage_big.png");
export const Wattson = require("./character_portraits/wattson_big.png");
export const Wraith = require("./character_portraits/wraith_big.png");

// legend icons
export const AlterIcon = require("./character_icons/alter.png");
export const AshIcon = require("./character_icons/ash.png");
export const BallisticIcon = require("./character_icons/ballistic.png");
export const BangaloreIcon = require("./character_icons/bangalore.png");
export const BloodhoundIcon = require("./character_icons/bloodhound.png");
export const CatalystIcon = require("./character_icons/catalyst.png");
export const CausticIcon = require("./character_icons/caustic.png");
export const ConduitIcon = require("./character_icons/conduit.png");
export const CryptoIcon = require("./character_icons/crypto.png");
export const FuseIcon = require("./character_icons/fuse.png");
export const GibraltarIcon = require("./character_icons/gibraltar.png");
export const HorizonIcon = require("./character_icons/horizon.png");
export const LifelineIcon = require("./character_icons/lifeline.png");
export const LobaIcon = require("./character_icons/loba.png");
export const MadMaggieIcon = require("./character_icons/madmaggie.png");
export const MirageIcon = require("./character_icons/mirage.png");
export const NewcastleIcon = require("./character_icons/newcastle.png");
export const OctaneIcon = require("./character_icons/octane.png");
export const PathfinderIcon = require("./character_icons/pathfinder.png");
export const RampartIcon = require("./character_icons/rampart.png");
export const RevenantIcon = require("./character_icons/revenant.png");
export const SeerIcon = require("./character_icons/seer.png");
export const ValkyrieIcon = require("./character_icons/valkyrie.png");
export const VantageIcon = require("./character_icons/vantage.png");
export const WattsonIcon = require("./character_icons/wattson.png");
export const WraithIcon = require("./character_icons/wraith.png");


// social media
export const Twitter = require("./twitter.png");
export const TwitterBorder = require("./twitter2.png");
export const Twitch = require("./twitch.png");
export const TwitchBorder = require("./twitch2.png");

// other icons
export const VIP = require("./icons/vip.png");
export const Patreon = require("./icons/patron1.png");
export const Skull = require("./icons/skull.png");
export const Gun = require("./icons/gun.png");
export const Headshot = require("./icons/headshot.png");
export const Hit = require("./icons/hit.png");

// other images
export const Sadge = require("./sadge.jpeg");
export const AnimatedLogo = require("./gif/eec_animated_crop_small.gif");
export const DiscordLogo = require("./discord3.png")
export const StaticLogo = require("./eec_static_small.png");

// participant badges
export const Season1Badge = require('./participant_badges/seasonBadges/season1.png');
export const Season2Badge = require('./participant_badges/seasonBadges/season2.png');
export const Season3Badge = require('./participant_badges/seasonBadges/season3.png');
export const LegacyLeagueBadge = require('./participant_badges/leagueBadges/LLseason1.png');
export const SeasonBadges = {'Season1': Season1Badge, 'current': Season2Badge, 'legacyLeague': LegacyLeagueBadge};

// legacy league
export const LegacyLeagueCalendar = require('./legacyLeague/EECLLCal.png');
export const LegacyLeagueCalendarMobile = require('./legacyLeague/EECCalMobile.png');
export const LegacyPoints = require('./legacyLeague/LeaguePointsChartTrim.png');
export const LegacyLogoBox = require('./legacyLeague/EECLLLogoBox.png');
export const LegacyBox = require('./legacyLeague/EECLLBox.png');
export const LegacyTournamentFlow = require('./legacyLeague/EECLLTournamentFlow.png');
export const LegacyTournamentFlowMobile = require('./legacyLeague/EECLLMobileFlow.png');
export const LegacyLeagueLargeBadge = require('./legacyLeague/LegacyLeagueLarge.png');