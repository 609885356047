// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    flex: 1 1;
    height: 105px;
    width: 105px;
    margin-left: 15%;
    position: relative;
    overflow: inherit;
    border-radius: 50%;
}

.nav-link {
    color: gray;
}

.nav-link:hover {
    /* text-shadow: 0px 0px 15px #0dd37d; */
    color: #0dd37d;
}

.glow {
    color: #0dd37d;
    text-shadow: 0px 0px 25px #0dd37d;
}


.box-glow {
    color: #0dd37d;
    box-shadow: 0px 0px 25px #0dd37d;
}

.pfp-glow:hover {
    box-shadow: 0px 0px 50px #0dd37d;
}

.logo-glow:hover {
    box-shadow: 0px 0px 30px #0dd37d;
}

.header-show {
    top: 0;
    transition: top 0.2s ease-out;
}

.header-hide {
    top: -80px;
    transition: top 0.2s ease-out;
    display: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/HeaderStyles.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,uCAAuC;IACvC,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,iCAAiC;AACrC;;;AAGA;IACI,cAAc;IACd,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,MAAM;IACN,6BAA6B;AACjC;;AAEA;IACI,UAAU;IACV,6BAA6B;IAC7B,eAAe;AACnB","sourcesContent":[".logo {\n    flex: 1;\n    height: 105px;\n    width: 105px;\n    margin-left: 15%;\n    position: relative;\n    overflow: inherit;\n    border-radius: 50%;\n}\n\n.nav-link {\n    color: gray;\n}\n\n.nav-link:hover {\n    /* text-shadow: 0px 0px 15px #0dd37d; */\n    color: #0dd37d;\n}\n\n.glow {\n    color: #0dd37d;\n    text-shadow: 0px 0px 25px #0dd37d;\n}\n\n\n.box-glow {\n    color: #0dd37d;\n    box-shadow: 0px 0px 25px #0dd37d;\n}\n\n.pfp-glow:hover {\n    box-shadow: 0px 0px 50px #0dd37d;\n}\n\n.logo-glow:hover {\n    box-shadow: 0px 0px 30px #0dd37d;\n}\n\n.header-show {\n    top: 0;\n    transition: top 0.2s ease-out;\n}\n\n.header-hide {\n    top: -80px;\n    transition: top 0.2s ease-out;\n    display: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
