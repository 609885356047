import React, { FC, useState } from "react";
import { TableRow, TableCell, Collapse, Grow, Box } from '@mui/material';
import { PLAYER_TYPE, TEAM_TYPE } from "../../globalConsts";
import { useNavigate } from "react-router";
import { mmrToRankIMG } from "../../pubFuncs";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export const LegacyLeaderboardRow: FC<{team: TEAM_TYPE, rank: number}> = ({team, rank}): JSX.Element => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const navigate = useNavigate();

    const rankIcon = mmrToRankIMG(9000, rank - 1);

    return (
        <>
            <TableRow onClick={() => setExpanded(!expanded)}> 
                <TableCell sx={{textAlign: 'center', borderBottom: 'none'}}>
                    {rank}
                    <img src={rankIcon} style={{width: "20px", marginRight: "8px"}}/>
                </TableCell>
                <TableCell sx={{textAlign: 'center', borderBottom: 'none'}}>{team.teamName}</TableCell>
                <TableCell sx={{textAlign: 'center', borderBottom: 'none'}}>{Math.round(team.teamLP)}</TableCell>
                <TableCell sx={{textAlign: 'center', borderBottom: 'none'}}>
                    {
                        expanded ? <ExpandLess/> : <ExpandMore/>
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={4}>
                    <Collapse in={expanded} unmountOnExit>
                    <Box display='flex' flexDirection='row'>
                        {
                            Object.values(team.players).map((p: PLAYER_TYPE) => {
                                return (
                                    <Box display='flex' flexDirection='row' className='ms-auto me-auto' onClick={() => navigate("/player/"+p.discordName+'?season=LegacyLeague')}>
                                        {p.discordName}: {Math.round(p.LeaguePoints || 500)}
                                    </Box>
                                )
                            })
                        }

                    </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
} 