import React, {useState} from "react";
import { Paper, TableContainer, Table, TableRow, TableHead, TableBody, TextField, Grid, Button} from "@mui/material";
import { PlayerAccountTableHeaderCell } from "./PlayerAccountTableComponents/PlayerAccountTableHeaderCell";
import { CollapseRow } from "./PlayerAccountTableComponents/CollapseRow";

export const PlayerAccountTable = ({fullAccounts, fetchFullAccounts, toggleOpen, open}) => {
    const [sortBy, setSortBy] = useState('crudPerms');
    const [reversed, setReversed] = useState(true);
    const [filterText, setFilterText] = useState('');

    let filteredList = fullAccounts;
    if(filterText !== ""){
        filteredList = filteredList.filter(account => {
            const lowerFilter = filterText.toLowerCase();
            const name = account.discordName.toLowerCase().includes(lowerFilter);
            const id = account.discordID.toString().toLowerCase().includes(lowerFilter);
            let apiKey = false;
            if(account.apiKey) apiKey = account.apiKey.toString().toLowerCase().includes(lowerFilter);

            return name || id || apiKey;
        })
    }

    let sortedList = filteredList;
    sortedList.sort((a, b) => {
        if(!a[sortBy]) return -1
        if(!b[sortBy]) return 1
        return a[sortBy] > b[sortBy] ? 1 : -1
    });
    if(reversed) sortedList.reverse();

    const headerClick = (sort) => {
        if(sortBy === sort)
            setReversed(!reversed);
        else {
            setSortBy(sort)
            if(reversed) setReversed(!reversed);
        }
    }

    return (
        <Grid container spacing={0}>
            <Grid item xs={10}>
                <TextField label="Player Search" variant="filled" className="m-2" color='eternal' size="small" sx={{width: '25%'}} onChange={(event) => setFilterText(event.target.value)}/>
            </Grid>
            <Grid item xs={2}>
                {
                    Boolean(toggleOpen) ? 
                    <Button color='eternal' variant='contained' size='large' onClick={toggleOpen}>OPEN JURY</Button>
                    : ""
                }
            </Grid>
            <Grid item xs={12}>
                <TableContainer className='mt-2' component={Paper} elevation={5} sx={{height: open ? '53vh' : '64vh', overflowY: 'scroll', width: '100%', marginLeft: 'auto', marginRight: 'auto'}}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow key={"rowHeader"}>
                                <PlayerAccountTableHeaderCell title="Discord Name" onClick={() => headerClick('discordName')} label={'discordName'} sortBy={sortBy} reversed={reversed} cellKey={"discordNameHeader"}/>
                                <PlayerAccountTableHeaderCell title="Discord ID" onClick={() => headerClick('discordID')} label={'discordID'} sortBy={sortBy} reversed={reversed} cellKey={"discordIDHeader"}/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                sortedList.map(account => <CollapseRow account={account} sortBy={sortBy} headerClick={headerClick} reversed={reversed} fetchFullAccounts={fetchFullAccounts} key={'collapse'+account.discordID}/>) 
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )

}