import React, { FC, useContext } from "react";
import { Alter, Ash, Ballistic, Bangalore, Bloodhound, Catalyst, Caustic, Conduit, Crypto, Fuse, 
    Gibraltar, Horizon, Lifeline, Loba, MadMaggie, Mirage, Newcastle, Octane, Pathfinder,
    Rampart, Revenant, Seer, Valkyrie, Vantage, Wattson, Wraith
} from "../../../../images/imgIndex";
import {Card, CardHeader, CardContent, Grid, Paper} from "@mui/material"
import { colorContext } from "../BoundPage.tsx";

const characterMap = {
    'alter': Alter, "ash": Ash, "ballistic": Ballistic, "bangalore": Bangalore, "bloodhound": Bloodhound,
    "catalyst": Catalyst, "caustic": Caustic, "conduit": Conduit, "crypto": Crypto,
    "fuse": Fuse, "gibraltar": Gibraltar, "horizon": Horizon, "lifeline": Lifeline,
    "loba": Loba, "madmaggie": MadMaggie, "mirage": Mirage, "newcastle": Newcastle,
    "octane": Octane, "pathfinder": Pathfinder, "rampart": Rampart, "revenant": Revenant,
    "seer": Seer, "valkyrie": Valkyrie, "vantage": Vantage, "wattson": Wattson, "wraith": Wraith
}

interface Props {
    statMap: Map<string, string | number | string[]>;
}

export const LegendCard: FC<Props> = ({statMap}) => {

    const [color, ] = useContext<[string, Function]>(colorContext);

    const legendList: string[] = statMap.get("char") as string[];
    const otherLegends = legendList.slice(1, 4);
    
    return (
        <Card sx={{borderRadius: 5, border: color + " 2px solid"}}>
            <CardHeader 
                titleTypographyProps={{
                    fontFamily: "Orbitron",
                    fontSize: 30
                }}
                title="Legends"
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper elevation={5}>
                            <img key = {"legend_list: "} src={characterMap[legendList[0]]} alt="" className='img-fluid mb-2' style={{marginTop:"-5%"}}/>
                        </Paper> 
                    </Grid>
                    {otherLegends.map(legend => {
                        return (
                            <Grid item xs={4}>
                                <Paper elevation={5}>
                                    <img key = {"legend_list: "+ legend} src = {characterMap[legend]} alt = "" className='img-fluid mb-2' style={{marginTop:"-5%"}}/>
                                </Paper> 
                            </Grid>
                        )
                    })}
                </Grid>
            </CardContent>
        </Card>
    )

}