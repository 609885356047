import React from "react";
import {Typography} from '@mui/material';
import { Sadge } from "../../../images/imgIndex";

export const MissingPermsPage = () => {
    return (
        <div className="page">
            <div className="other-page"/>
            <Typography textAlign='center' fontSize='3em' marginTop={'20vh'} className="glow text-light">
                <img src={Sadge} alt='sadge'/> <br/>
                What are you doing here
            </Typography>
        </div>
    )
}