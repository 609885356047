import React from "react";
import { ConfirmationDialog } from "./ConfirmationSnackbar";
import httpCommon from "../../../http-common";

export const PlayerSnackBar = ({open, player, setPlayer, setPlayerOpen, captainID, fetchTeam}) => {
    const message = "Are you sure you want to remove " + (player.discordName ||  "") + " from your team?";
    
    const accept = async () => {
        try {
            await httpCommon.post("/teams/update", {captain: captainID, player: player.discordID, action: "remove"});
        } catch(err) {
            console.log(err);
        }

        setPlayerOpen(false);
        setTimeout(() => {
            setPlayer("");
        }, 500);
        await fetchTeam().catch(console.error);
    }

    const deny = () => {
        setPlayerOpen(false);
        setTimeout(() => {
            setPlayer("");
        }, 500);
    }

    return (
        <ConfirmationDialog open={open} message={message} accept={accept} deny={deny} />
    )
}