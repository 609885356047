import React from "react";
import {Card, CardContent, Typography, Paper} from '@mui/material';

export const TeamCard = ({team}) => {
    const numTeamm8s = team.teammates.length + 1;

    return (
        <Card component={Paper} elevation={5}>
            <CardContent>
                <Typography sx={{textWrap: 'nowrap'}}>
                    {team.team.teamname}
                </Typography>
                # of Players: {numTeamm8s}
            </CardContent>
        </Card>
    )
}