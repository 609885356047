import axios from "axios";
const backendTag = process.env.REACT_APP_BACKEND;

const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
const id = loginInfo ? loginInfo.id : -1;

export default axios.create({
  baseURL: "https://"+backendTag+".eternalesports.club/api/",
  headers: {
    "Content-type": "application/json",
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET',
    'apiKey': localStorage.getItem('apiKey'),
    'discordID':  id.toString()
  }
});