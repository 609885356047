import { SnackbarContent } from "notistack";
import React, { forwardRef } from "react";

export const NotificationCustomizedSnackbar = forwardRef((props, ref) => {

    const {
        message
    } = props;

    return (
        <SnackbarContent ref={ref} {...props} >
            {message}
        </SnackbarContent>
    )

});