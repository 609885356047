import React, { FC, useContext, useEffect, useState } from "react";
import { Box, Typography } from '@mui/material';
import { ETERNAL_GREEN, ETERNAL_PINK, ETERNAL_RED, ETERNAL_YELLOW } from "../../../globalConsts.ts";
import { MobileContext } from "../../../../AppFunctional";

interface Props {
    time: number;
}

export const CountDown: FC<Props> = ({time}) : JSX.Element => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [loading, setLoading] = useState(true);

    const isMobile = useContext(MobileContext);

    const getTime = () => {
        const timeUntil = time * 1000 - Date.now();
    
        setDays(Math.floor(timeUntil / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((timeUntil / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((timeUntil / 1000 / 60) % 60));
        setSeconds(Math.floor((timeUntil / 1000) % 60));
        setLoading(false);
    };

    useEffect(() => {
        const interval = setInterval(() => getTime(), 1000);
        return () => clearInterval(interval);
    }, []);

    if(loading) return <></>

    return (
        <Box>
            <Typography fontSize={isMobile ? 15 : 25}>
                {/* {days < 0 && hours < 0 && minutes < 0 && seconds < 0 ? 'RIGHT NOW' : ''} */}
                {days > 0 ? <Typography component='span' color={ETERNAL_GREEN}>{days}<Typography component='span' color='white'> Days, </Typography> </Typography> : '' }
                {hours >= 0 ? <Typography component='span' color={ETERNAL_GREEN}>{hours}<Typography component='span' color='white'> Hours, </Typography> </Typography> : '' }
                {minutes >= 0 ? <Typography component='span' color={ETERNAL_PINK}>{minutes}<Typography component='span' color='white'> Minutes, </Typography> </Typography> : '' }
                {seconds >= 0 ? <Typography component='span' color={ETERNAL_YELLOW}>{seconds}<Typography component='span' color='white'> Seconds </Typography> </Typography> : '' }
            </Typography>
        </Box>
    )
} 