import React, { useEffect, useState } from "react";
import { timeConverter } from "../../../../../pubFuncs";
import {TableRow, TableCell} from '@mui/material';
import { TimeoutButton } from "../../../../Buttons/TimeoutButton";
import httpCommon from "../../../../../../http-common";

export const LowPrioRow = ({account, fetchFullAccounts}) => {

    const [reason, setReason] = useState('');
    const [author, setAuthor] = useState('');

    useEffect(() => {
        const fetchReason = async () => {
            const {data:reason}= await httpCommon.get('/commands/reason?discordID='+ account.discordID);
            setAuthor(reason.author);
            setReason(reason.reasonText);
        }

        fetchReason().catch(console.error);
    }, [account.discordID]);

    const freeOnClick = async () => {
        try {
            await httpCommon.post('/players/free', {discordID: account.discordID});
            fetchFullAccounts();
        } catch(err){
            console.log(err);
        }
    }
    
    return (
        <TableRow>
            <TableCell>
                {'Low Prio until: ' + timeConverter(account.LOW_PRIO)}
            </TableCell> 
            <TableCell>
                {reason}
            </TableCell>
            <TableCell>
                Given By: {author}
            </TableCell>
            <TableCell>
                <TimeoutButton onClick={() => freeOnClick()}>Free</TimeoutButton>
            </TableCell>
        </TableRow>
    )
}