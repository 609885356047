import React, {useState} from 'react';
import {TableRow, TableCell, Table, Collapse, Popover, TableBody, Paper, Typography } from '@mui/material';
import { timeConverter } from '../../../../pubFuncs';
import { TimeoutButton } from '../../../Buttons/TimeoutButton';
import httpCommon from "../../../../../http-common";
import { useCopyToClipboard } from '../../../../hooks/useCopyToClipboard';

export const CheckinTableRow = ({checkin, fetchCheckins}) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const copy = useCopyToClipboard();

    const removeCheck = async () => {
        try {
            await httpCommon.post("/Checkin/removeCheck", {checkinID: checkin.id});
            fetchCheckins();
        } catch(err){
            console.log(err);
        }
    }

    const readdCheck = async () => {
        try {
            await httpCommon.post("/Checkin/readdCheck", {checkinID: checkin.id});
            fetchCheckins();
        } catch(err){
            console.log(err);
        }

    }

    const copyCheckin = (event) => {
        setAnchorEl(event.currentTarget);
        copy(checkin.players.replace("\"", ""));
        setTimeout(() => setAnchorEl(null), 2000);
    }

    return (
        <>
            <TableRow key={"row"+checkin.id}  className="select" >
                <TableCell onClick={() => setOpen(!open)}>{checkin.teamname}</TableCell>
                <TableCell onClick={() => setOpen(!open)}>{checkin.mmr}</TableCell>
                <TableCell onClick={() => setOpen(!open)}>{timeConverter(checkin.checkinDate)}</TableCell>
                <TableCell >
                    {checkin.removed ? 
                        <TimeoutButton onClick={() => readdCheck()}>Re Add</TimeoutButton> 
                        : 
                        <TimeoutButton onClick={() => removeCheck()} color="error">Remove</TimeoutButton> 
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} unmountOnExit timeout='auto'>
                        <Table component={Paper} className="mb-2 mt-2">
                            <TableBody>
                                <TableCell>{checkin.id}</TableCell>
                                <TableCell>{checkin.scrimBlock}</TableCell>
                                <TableCell onClick={(event) => {
                                        copyCheckin(event)
                                    }}>
                                    {checkin.playerNames}
                                    <Popover
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Typography fontFamily="monospace" sx={{ p: 2 }}>Copied to Clipboard</Typography>
                                    </Popover>
                                </TableCell>
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>

    )
}