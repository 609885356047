import React, { useContext, useState } from "react";
import {Grid} from "@mui/material"
import { BindTable } from "./PlayerPageComponents/BindTable";
import { BindPlayerTable } from "./PlayerPageComponents/BindPlayerTable";
import { playerDiscordContext, setJustBoundContext } from "./PlayerPageFunctional.tsx";
import httpCommon from "../../../http-common";
import { useNavigate } from "react-router";
import { ETERNAL_GREEN } from "../../globalConsts.ts";

export const UnBoundPage = ({loggedInUser, discordID}) => {
    const playerDiscord = useContext(playerDiscordContext);
    const boundContext = useContext(setJustBoundContext);
    const navigate = useNavigate();
    
    const [bindErr, setBindErr] = useState("");
    const [bindMatchID, setBindMatchID] = useState(null);

    const handleBindAccount = async (bindAccount) => {
        if(!bindAccount){
            setBindErr("no name");
        } else {
            setBindErr('');

            try {
                await httpCommon.post("players/bind", {discordID, bindAccount});
                setBindMatchID(null);
                navigate("/player/"+playerDiscord);
                boundContext(true);
            } catch(err){
                const resp = err.response.data;
                setBindErr(resp);
            }
        }
    }

    let playerSearch = "";
    let notLoggedIn = "";
    let bindErrMSG = "";

    if(bindErr === "no name"){
        bindErrMSG = "Please select a name";
    } else if(bindErr === "bound already"){
        bindErrMSG = "You are already bound! Refresh :)";
    } else if(bindErr === "no account found"){
        bindErrMSG = "This name is associated with multiple accounts, please try a different one";
    } else if(bindErr === "no account found"){
        bindErrMSG = "ima be real idfk wtf is happening rn";
    } 

    if(playerDiscord === loggedInUser){
        playerSearch = (
            <Grid item xs={12} sx={{display: "flex", flexDirection: "row"}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>     
                        <div className="text-light" style={{ textAlign: "center", fontSize: '1.2em'}}>
                            <span style={{color: ETERNAL_GREEN}}>AFTER</span> you have played a game of scrims or solo queue, <br/>
                            please search your name, navigate to the latest game you've played and press <span style={{color: ETERNAL_GREEN}}>BIND</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "center", color: "red"}}>
                        {bindErrMSG}
                    </Grid>
                    <Grid item xs={12}>
                        {
                            Boolean(bindMatchID) ? 
                                <BindPlayerTable mid={bindMatchID} onClick={handleBindAccount} setBindMatchID={setBindMatchID}/>
                                :
                                <BindTable setBindMatchID={setBindMatchID}/>
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
    } else if(!loggedInUser){
        notLoggedIn = <span>If this is your account, please log in to bind</span>
    } 

    return (
        <Grid container spacing={2} style={{height: "100%"}}>
            <Grid item xs={12} style={{marginTop: "0%", textAlign: "center", fontSize: '3em', fontFamily: "Orbitron"}}>
                <div>{playerDiscord}<span className="text-light"> has not bound</span></div>
                <div>{notLoggedIn}</div>
            </Grid>
            {playerSearch}
        </Grid>
    );

}