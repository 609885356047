import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { LegacyLeagueCalendar, LegacyLeagueCalendarMobile } from "../../../../images/imgIndex";
import { Box } from '@mui/material';
import {useWindowSize} from "../../../hooks/useWindowSize";
import { MobileContext } from "../../../../AppFunctional";

interface Props {
    imgHeight?: string | number;
    boxWidth?: string | number;
    setCalWidth?: Function;
    className?: string;
}

export const LLCalendar : FC<Props> = ({imgHeight, boxWidth, setCalWidth, className}) : JSX.Element => {
    const refContainer = useRef<HTMLInputElement>(null);
    const [width, height] = useWindowSize();

    const isMobile = useContext(MobileContext);
    
    imgHeight = imgHeight || 'auto';
    boxWidth = boxWidth || 'auto';
    className = className || '';

    useEffect(() => {
        if(refContainer.current && setCalWidth)
            setCalWidth(refContainer.current.offsetWidth)
    },[setCalWidth, refContainer, width, height]);

    return (
        <Box display='flex' width={boxWidth} ref={refContainer} className={className}>
            <img alt='Calendar' src={isMobile ? LegacyLeagueCalendarMobile : LegacyLeagueCalendar} style={{height: isMobile ? undefined : imgHeight, width: isMobile ? '100%' : 'auto', marginLeft: 'auto', marginRight: 'auto', resize: 'none'}}/>
        </Box>
    )
}