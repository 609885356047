function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

const RandomYesGen = () => {
    const yesList = ["affirmative", "amen", "fine", "okay", "true", "yea",
                    "yes", "aye", "by all means", "gladly", "sure thing",
                    "sure thing", "most assuredly", "oui", "yep", "we are of one mind",
                    "you got it", "our thoughts are in parallel", "I guess so",
                    "you took the words right out of my mouth", "agreed",
                    "I guess so", "definitely", "sure", "ok", "huh?",
                    "there is nothing in this world that I could agree with any more",
                    ":3 ofc!! uwu"];

    const randomIndex = getRandomInt(yesList.length);
    return yesList[randomIndex];
}

const RandomNoGen = () => {
    const noList = ["nay", "nix", "no", "never", "negative", "nop",
                    "absolutely not", "by no means", "never", "no way", "not at all",
                    "not by any means", "negatory", "non", "no", "huh?",
                    "there is nothing in this world that I would disagree with more", 
                    "??? no ???", "I hear you, but no", "naw lol", "xd :3 no teehee",];

    const randomIndex = getRandomInt(noList.length);
    return noList[randomIndex];
}

export {RandomYesGen, RandomNoGen};