import React from "react";
import {Box, Divider} from "@mui/material";

export const QNABox = (props) => {

    const { question, children, } = props;

    return (
        <>
            <Box fontWeight='bold' marginTop={'10px'} fontSize={'1.2em'}>
                {question}
            </Box>
            {children}
            <Divider/>
        </>
    )
}