import React, { FC, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { DISCORD_INFO_TYPE } from '../../globalConsts';

export const BindPage: FC<{}> = (): React.ReactNode => {

    const loggedIn: boolean = useMemo(() => {
        return JSON.parse(localStorage.getItem('loggedIn') || 'false');
    }, []);

    const loginInfo: DISCORD_INFO_TYPE = useMemo(() => {
        return JSON.parse(localStorage.getItem('loginInfo') || '{}');
    }, []);

    const nav = useNavigate();

    useEffect(() => {
        if(loggedIn && Object.keys(loginInfo).length > 0)
            nav('/player/'+loginInfo.username);
    }, []);

    return (
        <>
            <div className = 'other-page'/>
            {
                <Box margin='auto' width='25vw' display={loggedIn ? 'none' : 'flex'}>
                    <Typography color='white' fontSize='2em' textAlign='center'>Please login to bind your apex account</Typography>
                </Box>
            }
        </>
    )
}