import React from "react";
import { Patreon, VIP } from "../../../../images/imgIndex";
import {Card, CardContent} from "@mui/material"

export const HiddenElement = ({permLevel, onUserProfile, color}) => {
    let img = VIP;
    let verabage = "Available to Discord Subscribers";
    if(permLevel === 0 && onUserProfile){
        img = Patreon;
    } else if (permLevel <= 1 && !onUserProfile){
        verabage = "Available to Vips";
    }
    return (
        <Card sx={{borderRadius:5, border: color + " 2px solid"}} className="select">
            <CardContent>
                <div style={{display: "flex", flexDirection: "column"}} onClick={() => window.open("https://discord.com/channels/1031340622692499506/shop", "_blank")}>
                    <div style={{margin:"auto", fontSize: "25px", textShadow: "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000", textAlign: "center"}}>{verabage}</div>
                    <img key = {"vip"} src={img} alt="" style={{width: "25%", margin: "auto"}}/>
                </div>
            </CardContent>
        </Card>
    );

}