import React, { useState } from "react";
import {Table, TableHead, TableRow, TableBody, TableCell, Paper} from "@mui/material";
import { PlayerTable } from "./PlayerTable";
import { PLACEMENT_POINTS } from "../../../../common/globalConsts.ts";

export const TeamTable = ({matchData}) => {

    const [expandedRows, setExpandedRows] = useState({});
    const [updateToggle, setUpdateToggle] = useState(false);

    let rows = [];
    let matchGroup = {}
    matchData.forEach(playerRow => {
        if(!matchGroup[playerRow.teamPlacement])
            matchGroup[playerRow.teamPlacement] = [playerRow];
        else
            matchGroup[playerRow.teamPlacement].push(playerRow);
    });

    const matchGroups = Object.values(matchGroup);

    const sortedGroups = matchGroups.sort((a, b) => {
        const bVal = PLACEMENT_POINTS[b[0].teamPlacement] + b.reduce((prev, cur) => prev += Number(cur.kills), 0);
        const aVal = PLACEMENT_POINTS[a[0].teamPlacement] + a.reduce((prev, cur) => prev += Number(cur.kills), 0);
        return bVal - aVal;
    });

    sortedGroups.forEach((group, index) => {
        const playerList = group;
        const placement = group[0].teamPlacement;
        const team = group[0].teamName;
        let kills = 0;
        let damage = 0;
        playerList.forEach(player => {
            kills += player.kills;
            damage += player.damageDealt;
        });

        const score = PLACEMENT_POINTS[group[0].teamPlacement] + group.reduce((prev, cur) => prev += Number(cur.kills), 0);

        rows.push(
            <TableRow className = "select" onClick={() => {
                const currentExpandedRows = expandedRows;
                if(!currentExpandedRows[team]){
                    currentExpandedRows[team] = true;
                } else {
                    currentExpandedRows[team] = !currentExpandedRows[team];
                }

                setExpandedRows(currentExpandedRows);
                setUpdateToggle(!updateToggle);
            }}>
                <TableCell sx={{textAlign: "center"}}>{score}</TableCell>
                <TableCell sx={{textAlign: "center"}}>{team}</TableCell>
                <TableCell sx={{textAlign: "center"}}>{kills}</TableCell>
                <TableCell sx={{textAlign: "center"}}>{damage}</TableCell>
            </TableRow>
        );

        if(expandedRows[team])
            rows.push(
                <TableRow>
                    <TableCell colSpan={4}>
                        <Paper elevation={8}>
                            <PlayerTable matchData={playerList} />
                        </Paper>
                    </TableCell>
                </TableRow>
            )
    });

    return (
        <Table>
            <TableHead style={{textAlign: "center"}}>
                <TableRow>
                    <TableCell sx={{textAlign: "center", fontSize: "25px"}}>Score</TableCell>
                    <TableCell sx={{textAlign: "center", fontSize: "25px"}}>Team</TableCell>
                    <TableCell sx={{textAlign: "center", fontSize: "25px"}}>Kills</TableCell>
                    <TableCell sx={{textAlign: "center", fontSize: "25px"}}>Damage</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows}
            </TableBody>
        </Table>
    )
}