import React, { useContext } from "react";
import "../Pages.css"
import { LeaderboardCard } from "./LeaderboardCard";
import { Grid } from "@mui/material";
import { MobileContext } from "../../../AppFunctional";

export const LeaderboardPageFunctional = () => {
    const isMobile = useContext(MobileContext);
    return (
        <div className = "page">
            <div className="other-page"/>
            <Grid container spacing={1}>
                <Grid item xs={12} className="glow" style={{fontSize: '3em', fontFamily: "Orbitron", textAlign: "center"}}>Leaderboard</Grid>
                <Grid item xs={12} style={{ alignContent: "center"}}>
                    <LeaderboardCard width={isMobile ? "100%" : "40%"}/>
                </Grid>
            </Grid>
        </div>
    );
}