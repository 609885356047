import React, { useEffect, createContext, useReducer } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/HeaderStyles.css"
import "./App.css"
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router";
import axios from "axios";
import { ThemeProvider, createTheme } from "@mui/material";
import websocket from "./common/websocket";
import { MatchesPageFunctional } from "./common/components/MatchesPage/MatchesPageFunctional";
import { LeaderboardPageFunctional } from "./common/components/LeaderboardPage/LeaderboardPageFunctional"
import { PlayerPageFunctional } from "./common/components/PlayerPage/PlayerPageFunctional.tsx";
import { MatchViewPageFunctional } from "./common/components/MatchViewPage/MatchViewPageFunctional";
import { SupportersPageFunctional } from "./common/components/SupportersPage/SupportersPageFunctional";
import { HeaderFunctional } from "./common/components/Header/HeaderFunctional";
import { SnackbarProvider } from "notistack";
import { NotificationCustomizedSnackbar } from "./common/components/Notifications/NotificationCustomSnackbar";
import FormData from 'form-data';
import { HelpPageFunctional } from "./common/components/HelpPage/HelpPageFunctional";
import { FAQPage } from "./common/components/HelpPage/FAQPage";
import { AdminPanelFunctional } from "./common/components/AdminPanel/AdminPanelFunctional";
import { NotInDiscord } from "./common/components/HomePage/NotInDiscord";
import httpCommon from "./http-common";
import { StaffPageFunctional } from "./common/components/StaffPanel/StaffPageFunctional";
import { useWindowSize } from "./common/hooks/useWindowSize";
import {isMobile} from 'react-device-detect';
import { VIPPanel } from "./common/components/VIPPanel/VIPPanel";
import { LegacyLeague } from "./common/components/HomePage/LegacyLeagueLandingPage.tsx";
import { ETERNAL_GREEN } from "./common/globalConsts.ts";
import { LegacyLeaderboardPage } from './common/components/LegacyLeaderboardPage/LegacyLeaderboardPage.tsx';
import { BindPage } from "./common/components/BindPage/BindPage.tsx";

export const MobileContext = createContext(false);
export const RefreshContext = createContext(false);

export const AppFunctional = () => {
    const [refreshToggle, forceUpdate] = useReducer(x => x + 1, 0);

    // const successCallback = (position) => {
    //     console.log(position);
    // };
    
    // const errorCallback = (error) => {
    //     console.log(error);
    // };
    
    // navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

    useEffect(() => {
        const fragment = new URLSearchParams(window.location.hash);
		const [accessToken, tokenType] = [fragment.get('access_token'), 'Bearer'];

		const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
		const loggedIn = JSON.parse(localStorage.getItem("loggedIn"));

		if (loginInfo == null) {
			localStorage.setItem("loggedIn", false);
		}

		if (loggedIn && accessToken) {
			window.location.href = "/";
		}

		if(loggedIn && loginInfo){
			websocket.createWebSocket(loginInfo.id, forceUpdate);
		} else if(!loggedIn && !accessToken){
			websocket.createWebSocket(-1, forceUpdate);
		}

        if (!loggedIn && accessToken) {
            const fetchDiscordOnLogin = async () => {
                const discordAxios = axios.create({
                    baseURL: 'https://discord.com/api/',
                    headers: {
                        authorization: `${tokenType} ${accessToken}`
                    }
                })
                const {data: discordResult} = await discordAxios.get('users/@me');
                // ping discord api for member information
                let guildResponseJSON = {};
                try {
                    const {data: guildResponse} = await discordAxios.get('users/@me/guilds/1031340622692499506/member');
                    guildResponseJSON = guildResponse;
                } catch(err){
                    console.log(err);
                    if(err.response && err.response.data.code && err.response.data.code === 10004){
                        window.open("https://discord.gg/eternaleec","Login to Discord","menubar=1,resizable=0,width=500,height=500");
                        window.location.href="/signin";
                        return;
                    }
                    
                }

                // store authorization and log player in
                localStorage.setItem("loggedIn", true);
                localStorage.setItem("loginInfo", JSON.stringify(discordResult));

                const discordID = guildResponseJSON.user.id;
                // encode URI to remove bad stuff before stringifying
                guildResponseJSON.nick = encodeURI(guildResponseJSON.nick);
                guildResponseJSON.bio = encodeURI(guildResponseJSON.bio);

                // ping discord for user connections 
                const {data: socials} = await discordAxios.get('users/@me/connections');

                try {
                    console.log(await httpCommon.post("/eternalAccounts/create", {discordID, guildMemberInfo: guildResponseJSON, socials}));
                } catch(err) {
                    console.log(err);
                }
                
                // grab eternal api key
                const {data: {apiKey}} = await httpCommon.get("/eternalAccounts/key?discordID="+discordID);
                localStorage.setItem('apiKey', apiKey);

                // post avatar
                const avatarResponse = await fetch("https://cdn.discordapp.com/avatars/" + discordID + "/" + discordResult.avatar);
                const imgBlob = await avatarResponse.blob();
                const imgFile = new File([imgBlob], 'imgBlob');
                const data = new FormData();
                data.append('blob', imgFile, imgFile.name);

                await httpCommon.post('/eternalAccounts/img?discordID='+discordID, data, {headers: {'Content-Type': imgFile.type}});
                websocket.createWebSocket(discordID, forceUpdate);
                window.location.href = "/";
            }

			fetchDiscordOnLogin().catch(console.error);
		}
    },[]);


    // MUI theme
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            eternal: {
                main: ETERNAL_GREEN,
                light: ETERNAL_GREEN,
                dark: ETERNAL_GREEN,
                contrastText: '#000',
            }
        },
        components: {
            MuiTypography: {
                defaultProps: {
                    fontFamily: "Orbitron",
                    fontSize: '1em'
                }
            },
            MuiDivider: {
                defaultProps: {
                    color: 'white',
                    background: 'white'
                }
            }
        }
    });

    const [width, /*unused height*/] = useWindowSize();
    const isWindowMobile = isMobile || width < 1000;

    return (
        <MobileContext.Provider value={isWindowMobile}> 
            <RefreshContext.Provider value={refreshToggle}>
                <ThemeProvider theme={darkTheme}>
                    <SnackbarProvider 
                        maxSnack={isWindowMobile ? 1 : 4}
                        dense={isWindowMobile}
                        preventDuplicate
                        Components={{
                            myNoti: NotificationCustomizedSnackbar
                        }}
                    >
                        <div style={{ overflow: "hidden" }}>
                            <Router>
                                <div className="header-div">
                                    <HeaderFunctional/>
                                </div>
                                <div className="page-div">
                                    <Routes>
                                        <Route exact path="/signin" element={<NotInDiscord />} />
                                        <Route exact path='/' element={<LegacyLeague />} />
                                        {/* <Route exact path="/" element={isWindowMobile ? <MobileHomePage/> : <HomePage />} /> */}
                                        <Route exact path="/leaderboard" element={<LeaderboardPageFunctional/>} />
                                        <Route exact path='/legacyleaderboard'  element={<LegacyLeaderboardPage />} />
                                        <Route exact path="/matches" element={<MatchesPageFunctional/>} />
                                        <Route exact path="/supporters" element={<SupportersPageFunctional/>} />
                                        <Route path="/matches/*" element={<MatchViewPageFunctional/>} />
                                        <Route path="/player/*" element={<PlayerPageFunctional/>} />
                                        <Route exact path="/adminPanel" element={<AdminPanelFunctional />} />
                                        <Route exact path="/staffPanel" element={<StaffPageFunctional />} />
                                        {/* <Route path="/team" element={<TeamPageFunctional/>}/> */}
                                        {/* <Route path="/team/*" element={<TeamPageFunctional/>}/> */}
                                        <Route exact path="/help" element={<HelpPageFunctional />} />
                                        <Route exact path='/faq' element={<FAQPage />} />
                                        <Route exact path='/vip' element={<VIPPanel />} />
                                        <Route exact path='/bind' element={<BindPage />} />
                                        <Route path="*" element={<div className="other-page"/>} />
                                    </Routes>
                                </div>
                                <div className="footer-div">
                                    {/* {isWindowMobile ? "" : <Footer />} */}
                                </div>
                            </Router>
                        </div>
                    </SnackbarProvider>
                </ThemeProvider>
            </RefreshContext.Provider>
        </MobileContext.Provider>
    );
}