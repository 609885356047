import React, { useState } from "react";
import {Table, TableHead, TableRow, TableBody, TableCell, Paper} from "@mui/material";
import { PlayerTableMobile } from "./PlayerTableMobile";
import * as Icon from "react-bootstrap-icons";

export const TeamTableMobile = ({matchData}) => {

    const [expandedRows, setExpandedRows] = useState({});
    const [updateToggle, setUpdateToggle] = useState(false);

    let rows = [];
    let matchGroup = {}
    matchData.forEach(playerRow => {
        if(!matchGroup[playerRow.teamPlacement])
            matchGroup[playerRow.teamPlacement] = [playerRow];
        else
            matchGroup[playerRow.teamPlacement].push(playerRow);
    });
    let i = 0;
    Object.keys(matchGroup).forEach(key => {
        const playerList = matchGroup[key];
        const placement = key;
        const team = matchGroup[key][0].teamName;
        let kills = 0;
        playerList.forEach(player => {
            kills += player.kills;
        });

        let color = "white"
        let textMod = "normal"
        if(i === 0){
            color = "gold"
            textMod = "bold"
        }else if(i === 1){
            color = "silver"
            textMod = "bold"
        }else if(i === 2){
            color = "#CD7F32"
            textMod = "bold"
        }

        rows.push(
            <TableRow className = "select" onClick={() => {
                const currentExpandedRows = expandedRows;
                if(!currentExpandedRows[key]){
                    currentExpandedRows[key] = true;
                } else {
                    currentExpandedRows[key] = !currentExpandedRows[key];
                }

                setExpandedRows(currentExpandedRows);
                setUpdateToggle(!updateToggle);
            }}>
            <TableCell style={{width: "10%"}} key={"teamPlacement: " + placement}><span style={{color: color, fontWeight: textMod}}>{placement}</span></TableCell>
            <TableCell style={{width: "80%"}} key={"teamName: " + team}>{team}</TableCell>
            <TableCell style={{width: "10%"}} key={"totalKills: " + kills}>{kills}</TableCell>
        </TableRow>
        );

        if(expandedRows[key])
            rows.push(
                <TableRow>
                    <TableCell colSpan={3}>
                        <Paper elevation={8}>
                            <PlayerTableMobile matchData={playerList} />
                        </Paper>
                    </TableCell>
                </TableRow>
            )
        i++;
    });

    return (
        <Table>
            <TableHead style={{textAlign: "center"}}>
                <TableCell style={{width: "10%"}}>
                    <Icon.Award size={25}/>
                </TableCell>
                <TableCell style={{width: "80%"}}>Team</TableCell>
                <TableCell style={{width: "10%"}}>Kills</TableCell>
            </TableHead>
            <TableBody>
                {rows}
            </TableBody>
        </Table>
    )
}