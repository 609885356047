import { Lobby, StatToken } from "../LobbyTypes"
import React, { FC, useContext, useEffect, useState } from "react";
import { Grid, Typography, Box, Select, FormControl, InputLabel, MenuItem, Paper, Button, TextField } from '@mui/material';
import httpCommon from "../../../../../../http-common";
import { timeConverter } from "../../../../../pubFuncs";
import { ETERNAL_GREEN, ETERNAL_RED } from "../../../../../globalConsts.ts";
import { pullLobbiesContext, setSelectedLobbyContext } from "../LobbyPanel.tsx"
import {ConfirmationButton} from "../../../../Buttons/ConfirmationButton.js";

interface Props {
    lobby: Lobby;
}

export const SelectedLobby: FC<Props> = ({lobby}): JSX.Element => {
    const [allTokens, setAllTokens] = useState<StatToken[]>([]);

    const pullLobbies = useContext(pullLobbiesContext);
    const setSelectedLobby = useContext(setSelectedLobbyContext);

    useEffect(() => {
        const fetchTokens = async () => {
            const {data: allTokens} = await httpCommon.get('/statTokens/autopoll');
            setAllTokens(allTokens);
        }

        fetchTokens().catch(console.error);
    }, []);

    const startPolling = async () => {
        let time = Date.now() / 1000 + 18000
        if(lobby.autopollUntil) time = lobby.autopollUntil + 18000; 
        await httpCommon.post('/lobbies/autopoll', {id: lobby.id, autopollUntil: time});
        await pullLobbies();
    }

    const stopPolling = async () => {
        await httpCommon.post('/lobbies/autopoll', {id: lobby.id, autopollUntil: null});
        await pullLobbies();
    }

    const setStatToken = async (token: string | null) => {
        if(token === '') token = null;
        await httpCommon.post('/lobbies/token', {id: lobby.id, statToken: token});
        await pullLobbies();
    }

    const deleteLobby = async () => {
        setSelectedLobby(null);
        await httpCommon.post('/lobbies/delete', {id: lobby.id});
        await pullLobbies();
        
    }

    const tokenList = allTokens.map(token => <MenuItem value={token.statToken}>{token.statToken}</MenuItem>);
    if(!tokenList.find(t => t.props.value === '')){
        tokenList.push(<MenuItem value={''}>None</MenuItem>)
    }

    return (
        <Box component={Paper} elevation={5} display='flex' flexDirection='column' width='85%'>
            <Typography fontSize='2em' className="mb-3 ms-auto me-auto mt-3">{lobby.name}</Typography>
            <FormControl className="ms-5 me-5 mb-3">
                {/* @ts-ignore */}
                <InputLabel id='statTokenLabel' color='eternal' shrink>Stat Token</InputLabel>
                <Select
                    notched
                    labelId='statTokenLabel'
                    label='Stat Token'
                    onChange={(event) => setStatToken(event.target.value as string)}
                    value={lobby.statToken}
                    // @ts-ignore
                    color='eternal'
                >
                    {tokenList}
                </Select>
            </FormControl>
            <Box className='ms-5 me-5 mb-2' border={'2px solid '+ (Boolean(lobby.autopollUntil) ? ETERNAL_GREEN : ETERNAL_RED)} borderRadius={2}>
                <Box className='m-2'>{Boolean(lobby.autopollUntil) ? `Polling until ${timeConverter(lobby.autopollUntil)}` : 'Not Autopolling'}</Box>
            </Box>
            {/* @ts-ignore */}
            <Button color="eternal" variant='outlined' className="ms-5 me-5 mb-1" onClick={() => startPolling()} disabled={!Boolean(lobby.statToken)}>{Boolean(lobby.autopollUntil) ? 'Add 5 hours to autopolling' : 'Start autopolling for 5 hours'}</Button>
            <Button color="warning" variant='outlined' className="ms-5 me-5 mb-1" onClick={() => stopPolling()} disabled={!Boolean(lobby.autopollUntil)}>Stop Autopolling</Button>
            <ConfirmationButton color='error' variant='outlined' className="ms-5 me-5 mb-1" text="Delete Lobby" onClick={() => deleteLobby()}>Are you sure you want to delete <span style={{color:ETERNAL_GREEN}}>{lobby.name}</span></ConfirmationButton>
        </Box>
    )
}