import React, { useEffect, useState, useContext, createContext, useCallback } from "react";
import { Loading } from "../MiscComponents/Loading";
import httpCommon from "../../../http-common";
import { TeamPageDesktop } from "../../../components/desktop/pages/TeamPageDesktop";
import { TeamPageMobile } from "../../../components/mobile/pages/TeamPageMobile";
import { MobileContext, RefreshContext } from "../../../AppFunctional";
import { useHref } from "react-router";

export const createdTeamContext = createContext();

export const TeamPageFunctional = () => {
    const [createdTeam, setCreatedTeam] = useState(false);
    const [discordID, setDiscordID] = useState(-1);
    const [loading, setLoading] = useState(true);

    const [team, setTeam] = useState(null);
    const [playerObjects, setPlayerObjects] = useState([]);

    const [sentInvites, setSentInvites] = useState([]);

    const [listOfPlayers, setListOfPlayers] = useState([]);
    const [isCaptain, setIsCaptain] = useState(false);

    const href = useHref();
    const onPrivate = href === "/team" || href === "/team/";
    const lookingAtTeam = href.replace("/team/", "");

    const isMobile = useContext(MobileContext);
    const refreshToggle = useContext(RefreshContext);

    const loggedIn = JSON.parse(localStorage.getItem('loggedIn'));
    if(loggedIn === true){
        const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
        if(discordID === -1)
            setDiscordID(loginInfo.id);
    } else {
        window.location.href = "/";
    }

    const fetchTeam = useCallback(async () => {
        try {
            const {data: team} = onPrivate ? await httpCommon.get('/teams/discordID?discordID=' + discordID) : await httpCommon.get('/teams/team?teamname='+lookingAtTeam);
            setTeam(team);
        } catch(err){
            if(err.response)
                if(err.response.status === 404){
                    setTeam(null);
                    setLoading(false);
                    console.log("missing team");
                }
        }
    }, [discordID, lookingAtTeam, onPrivate])

    useEffect(() => {
        fetchTeam().catch(console.error);
    },[refreshToggle, createdTeam, fetchTeam]);

    useEffect(() => {
        if(Boolean(team)){
            const fetchInvites = async () => {
                try {
                    const {data: {invites: sentInvites}} = await httpCommon.get("/teams/mySentInvites?discordID=" + team.team.captain);
                    setSentInvites(sentInvites);
                } catch(err){
                    console.log(err);
                }
            }

            const teammates = team.teammates.map(player => player.player);
            teammates.push(team.team.captain);
            setIsCaptain(team.team.captain === discordID);
            const fetchPlayers = async (teammates) => {
                const teammateObjs = [];
                for(const teammate of teammates){
                    try {
                        const {data: playerAccount} = await httpCommon.get('/players/id?discordID='+teammate);
                        teammateObjs.push(playerAccount);
                    } catch(error){
                        console.log(error);
                    }
                };
                setPlayerObjects(teammateObjs);
                setLoading(false);
            }
            fetchPlayers(teammates).catch(console.error);
            fetchInvites().catch(console.error);
        }
    }, [team, refreshToggle, createdTeam, discordID]);

    useEffect(() => {
        const fetchListOfPlayers = async () => {
            try {
                const {data: listOfPlayers} = await httpCommon.get("/eternalAccounts/discordMembers");
                setListOfPlayers(listOfPlayers);
            } catch(err) {
                console.log(err);
            }
        }

        if(listOfPlayers.length === 0)
            fetchListOfPlayers().catch(console.error);
    }, [refreshToggle, createdTeam, listOfPlayers.length]);

    if(loading){
        return (
            <div>
                <div className="other-page"/>
                <Loading/>
            </div>
        );
    }

    return (
        isMobile ? 
            (
                <createdTeamContext.Provider value={setCreatedTeam}>
                    <div className="other-page"/>
                    <TeamPageMobile team={team} players={playerObjects} 
                        discordID={discordID} isCaptain={isCaptain} 
                        fetchTeam={fetchTeam} listOfPlayers={listOfPlayers}
                        sentInvites={sentInvites} onPrivate={onPrivate}/>
                </createdTeamContext.Provider>
            ) 
            : 
            (
                <createdTeamContext.Provider value={setCreatedTeam}>
                    <div className="other-page"/>
                    <TeamPageDesktop team={team} players={playerObjects} 
                        discordID={discordID} isCaptain={isCaptain} 
                        fetchTeam={fetchTeam} listOfPlayers={listOfPlayers}
                        sentInvites={sentInvites} onPrivate={onPrivate}/>
                </createdTeamContext.Provider>
            )
    )
}