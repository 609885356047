import React, {useContext, useEffect, useState} from "react";
import {Grid, Tabs, Tab, Card, CardContent} from '@mui/material';
import httpCommon from "../../../http-common";
import { MobileContext } from "../../../AppFunctional";
import { useNavigate } from "react-router-dom";
import { Checkin } from "../AdminPanel/AdminPanelComponents/Checkin/Checkin";
import { HelpInbox } from "../AdminPanel/AdminPanelComponents/HelpInbox/HelpInbox";
import { Jail } from "../AdminPanel/AdminPanelComponents/Jail/Jail";
import { NotLoggedInPage } from "../AdminPanel/NotLoggedInPage";
import { Loading } from "../MiscComponents/Loading";
import { MissingPermsPage } from "../AdminPanel/MissingPermsPage";
import { ETERNAL_GREEN } from "../../globalConsts.ts";
import { TabBadge } from "../Badge/CustomBadge.tsx";
import { PlayerReport } from './PlayerReport/PlayerReport.tsx';

export const StaffPageFunctional = () => {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    const loggedIn = JSON.parse(localStorage.getItem("loggedIn"));
    const isMobile = useContext(MobileContext);
    const width = isMobile ? "100%" : "80%";

    const [permLevel, setPermLevel] = useState(0);
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState(urlParams.get('tab') || 'checkin');
    const [numOpenTickets, setNumOpenTickets] = useState(0);
    const [listOfPlayers, setListOfPlayers] = useState([]);

    useEffect(() => {
        const fetchPerms = async () => {
            const {data: {permLevel}} = await httpCommon.get("/eternalAccounts/perms");
            setPermLevel(permLevel);
            await fetchOpenTickets();
            await fetchListOfPlayers();
            setLoading(false);
        }

        const fetchListOfPlayers = async () => {
            const {data: listOfPlayers} = await httpCommon.get('/eternalaccounts/discordmembers');
            setListOfPlayers(listOfPlayers);
        }

        const fetchOpenTickets = async () => {
            const {data: {numTickets}} = await httpCommon.get('/help/num');
            setNumOpenTickets(numTickets);
        }

        fetchPerms().catch(console.error);
    }, []);

    const tabOnClick = (value) => {
        setTab(value);
        urlParams.set('tab', value)
        navigate('/staffPanel?'+urlParams, { replace: true })
    }

    // various cases of other pages 
    if(loading) return (<div className="page"><div className="other-page"><Loading/></div></div>)
    if(!loggedIn) return (<NotLoggedInPage />);
    // staff has perm level 3
    if(permLevel < 3) return (<MissingPermsPage />);

    return (
        <div className = "page">
            <div className="other-page"/> 
            <Grid container spacing={0}>
                <Grid item xs={12} className="glow" style={{fontSize: '3em', fontFamily: "Orbitron", textAlign: "center"}}>Staff Panel</Grid>
                <Grid item xs={12}>
                    <Card style={{width, margin: "auto", height:"80vh"}}>
                        <CardContent>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Tabs value={tab} variant="scrollable" scrollButtons={true} allowScrollButtonsMobile 
                                        onChange ={(event, value) => tabOnClick(value)} textColor='inherit'
                                        TabIndicatorProps={{style: {backgroundColor: ETERNAL_GREEN, height: 3}}}
                                    >
                                        <Tab label="Checkin" value="checkin" />
                                        <Tab label={
                                            <TabBadge badgeContent={permLevel > 2 ? numOpenTickets : 0} color='eternal' archorOrigin={{vertical: 'top'}} overlap="circular">
                                                Help Inbox
                                            </TabBadge>
                                        } value="help"/>
                                        <Tab label="JAIL" value="jail"/>
                                        <Tab label='Player Report' value='report' />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={12}>
                                    {tab === "checkin" && <Checkin />}
                                    {tab === "help" && <HelpInbox listOfPlayers={listOfPlayers}/>}
                                    {tab === "jail" && <Jail />}
                                    {tab === 'report' && <PlayerReport listOfPlayers={listOfPlayers} />}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card> 
                </Grid>
                </Grid>
        </div>
    )

}