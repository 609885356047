import React, { useState } from "react";
import {TableRow, TableCell, Table, Collapse, TableHead, TableBody, Paper } from '@mui/material';
import { CrudPermissionsCell } from "./CrudPermissionsCell";
import { BlurCell } from "./BlurCell";
import { MMRCell } from "./MMRCell";
import { UnbindButton } from "./UnbindButton";
import {  DiamondOutlined } from "@mui/icons-material";
import { PunishRow } from "./PunishRow";
import { LowPrioRow } from "./LowPrioRow";
import { QSCell } from "./QSCell";
import { useHref } from "react-router";

export const CollapseRow = ({account, fetchFullAccounts}) => {
    const [open, setOpen] = useState(false);
    
    const href = useHref();
    const isStaff = href.includes("/staffPanel");

    return ( 
        <>
            <TableRow key={"row"+account.discordID} onClick={() => setOpen(!open)} className="select" >
                <TableCell key={"discordName"+account.discordName} sx={{ borderBottom: "none" }}>
                    <span className="me-2">{account.discordName}</span>{account.VIP ? <DiamondOutlined /> : ""}
                </TableCell>
                <TableCell key={"discordID"+account.discordID} sx={{ borderBottom: "none" }}>{account.discordID}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} unmountOnExit timeout='auto'>
                        <Table size="small" component={Paper} className="mb-2 mt-2">
                            {
                                (Boolean(account.apiKey) || Boolean(account.nidHash) || Boolean(account.crudPerms)) && !isStaff ? (
                                <TableHead>
                                    <TableRow>
                                        <TableCell key={"apiKeyHeader"}>API Key</TableCell>
                                        <TableCell key={"nidHashHeader"}>nidHash</TableCell>
                                        <TableCell colSpan={2} key={"crudPermsHeader"}>CRUD Perms</TableCell>
                                    </TableRow>
                                </TableHead>
                                ) : ""
                            }
                            <TableBody>
                                {
                                    isStaff ? 
                                        ""
                                    :
                                        <TableRow>
                                            <BlurCell account={account} attribute={'apiKey'} key={"apiKey"+account.discordID} />
                                            <BlurCell account={account} attribute={'nidHash'} key={"nidHash"+account.discordID} />
                                            <CrudPermissionsCell account={account} key={"crudPerms"+account.discordID} />
                                        </TableRow>
                                }
                                <TableRow>
                                    <MMRCell account={account} isStarting={false} key={"MMR"+account.discordID} fetchFullAccounts={fetchFullAccounts} isStaff={isStaff}/>  
                                    <MMRCell account={account} isStarting={true} key={"startingMMR"+account.discordID} fetchFullAccounts={fetchFullAccounts} isStaff={isStaff}/>  
                                    <QSCell account={account} isStaff={isStaff}/>
                                    <UnbindButton account={account} fetchFullAccounts={fetchFullAccounts} isStaff={isStaff}/>
                                </TableRow>
                                {
                                    account.LOW_PRIO !== 0 ?
                                        <LowPrioRow account={account} fetchFullAccounts={fetchFullAccounts} />
                                        : 
                                        <PunishRow account={account} fetchFullAccounts={fetchFullAccounts}/>
                                } 
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}