import React, { useContext, useEffect, useState } from "react";
import { MobileContext } from "../../../AppFunctional";
import {Grid, Typography, Box, FormControl, InputLabel, Select, MenuItem, TextField, ListSubheader } from "@mui/material";
import { TimeoutButton } from "../Buttons/TimeoutButton";
import { Link } from "react-router-dom";
import httpCommon from "../../../http-common";
import { ConfirmationDialog } from "./HelpPageComponents.js/ConfirmationDialog";
import { HelpPageLogin } from "./HelpPageLogin";
import { ETERNAL_GREEN } from "../../globalConsts.ts";

export const HelpPageFunctional = () => {
    const [topic, setTopic] = useState("");
    const [topicError, setTopicError] = useState("");

    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");

    const [clips, setClips] = useState("");
    const [screenshots, setScreenshots] = useState("");
    const [achievements, setAchievements] = useState("");

    const [oldApex, setOldApex] = useState('');
    const [newApex, setNewApex] = useState('');
    const [oldDiscord, setOldDiscord] = useState('');
    const [newDiscord, setNewDiscord] = useState('');

    const [permLevel, setPermLevel] = useState(0);

    const isMobile = useContext(MobileContext);
    
    const [open, setOpen] = useState(false);

    const topicList = ['Player Misconduct', 'Low Priority Appeal', 'Binding', 'Tier Application', 'General'];
    const topicListSend = ['PlayerMisconduct', 'LowPriorityAppeal', 'Binding', 'TierApplication', 'General'];

    const width = isMobile ? '98%' : '25%';

    useEffect(() => {
        const fetchPerms = async () => {
            const {data: {permLevel}} = await httpCommon.get("/eternalAccounts/perms");
            setPermLevel(permLevel);
        }

        fetchPerms().catch(console.error);
    }, []);

    const loggedIn = JSON.parse(localStorage.getItem('loggedIn'));
    if(!loggedIn) return <HelpPageLogin />

    const submit = async () => {
        let error = false;
        if(topic === ""){
            error = true;
            setTopicError('Missing Topic');
        } else setTopicError("");
        if(description === ""){
            error = true;
            setDescriptionError('Missing Description');
        } else setDescriptionError("");

        if(error) return;

        const discordID = JSON.parse(localStorage.getItem('loginInfo')).id;
        const resp = await httpCommon.post('/help/post', {discordID, topic: topicListSend[topicList.indexOf(topic)], description, clips, screenshots, achievements, oldApex, newApex, oldDiscord, newDiscord});
        
        if(resp.data && resp.data === 'created request'){
            setOpen(true);
        }
    }

    const clearForms = () => {
        setDescription("");
        setDescriptionError("");
        setClips("");
        setScreenshots("");
        setAchievements("");
        setOldApex('');
        setNewApex('');
        setOldDiscord('');
        setNewDiscord('');
        setOpen(false);
    }


    return (
        <div className="page">
            <div className="other-page"/>
            <Grid container spacing={2} sx={{textAlign: 'center', marginBottom: '50px'}}>
                <Grid item xs={12}>
                    <ConfirmationDialog open={open} text='Thank you for submitting your support ticket!' hasDeny={false} onClick={() => clearForms()}/>
                    <Typography variant="h1" fontSize="3em" gutterBottom >Contact Support</Typography>
                </Grid>
                <Grid item xs={12} sx={{color: 'white'}}>
                    <Typography fontFamily="">
                        What do you need help with?
                    </Typography>
                    <Box>
                        <FormControl sx={{width}}>
                            <InputLabel id='select-label' color='eternal' error={topicError !== ""}>{topicError === "" ? "Topic" : topicError}</InputLabel>
                            <Select 
                                error={topicError !== ""}
                                labelId='select-label'
                                value={topic}
                                label={topicError === "" ? "Topic" : topicError}
                                onChange={(event) => setTopic(event.target.value)}
                                color='eternal'
                            >
                                <ListSubheader>General</ListSubheader>
                                <MenuItem color='eternal' value='Player Misconduct'>Player Misconduct</MenuItem>
                                <MenuItem color='eternal' disabled={permLevel === 0} value='Low Priority Appeal'>{permLevel === 0 ? 'Low Priority Appeal - VIP Only' : 'Low Priority Appeal' }</MenuItem>
                                <ListSubheader>Accounts and MMR</ListSubheader>
                                <MenuItem color='eternal' value='Binding'>Binding</MenuItem>
                                <MenuItem color='eternal' value='Tier Application'>Tier Application</MenuItem>
                                <ListSubheader>Other</ListSubheader>
                                <MenuItem color='eternal' value='General'>Miscellaneous</MenuItem>

                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{color: 'white', marginTop: '15px'}}>
                    <Typography fontFamily="">
                        <Link to="/faq" style={{color: ETERNAL_GREEN}}>Before you submit your question, did we already answer it?</Link>
                    </Typography>
                </Grid>
                {
                    topic !== "Tier Application" && topic !== 'Binding' ? 
                    (
                        <Grid item xs={12} sx={{color: 'white', marginTop: '15px'}}>
                            <Typography fontFamily="" gutterBottom>
                                Please submit any information relevant to your question
                            </Typography>
                            <Box maxWidth={width} marginLeft={'auto'} marginRight={'auto'} marginTop={'10px'}>
                                <TextField required value={description} id='description' multiline fullWidth color='eternal' label={descriptionError === "" ? "Description" : descriptionError} onChange={(event) => setDescription(event.target.value)} error={descriptionError !== ""}/>
                            </Box>
                            <Box maxWidth={width} marginLeft={'auto'} marginRight={'auto'} marginTop={'10px'}>
                                <TextField id='clips' value={clips} multiline fullWidth color='eternal' label='Links to Clips' onChange={(event) => setClips(event.target.value)}/>
                            </Box>
                            <Box maxWidth={width} marginLeft={'auto'} marginRight={'auto'} marginTop={'10px'}>
                                <TextField id='screenshots' value={screenshots} multiline fullWidth color='eternal' label='Links to Screenshots' onChange={(event) => setScreenshots(event.target.value)}/>
                            </Box>
                        </Grid>
                    )
                    : topic === 'Binding' ? 
                        (
                            <Grid item xs={12} sx={{color: 'white', marginTop: '15px'}}>
                                <Typography fontFamily="" gutterBottom>
                                    If you would like to rebind your Apex account to a new Discord Account <br/>OR rebind your Discord account to a new Apex Account, <br/>please enter info for those accounts here. Leave any blank that do not apply.
                                </Typography>
                                <Box maxWidth={width} marginLeft={'auto'} marginRight={'auto'} marginTop={'10px'}>
                                    <TextField required value={description} id='description' multiline fullWidth color='eternal' label={descriptionError === "" ? "Description" : descriptionError} onChange={(event) => setDescription(event.target.value)} error={descriptionError !== ""}/>
                                </Box>
                                <Box maxWidth={width} marginLeft={'auto'} marginRight={'auto'} marginTop={'10px'}>
                                    <TextField value={oldDiscord} id='description' multiline fullWidth color='eternal' label='Old Apex Account' onChange={(event) => setOldDiscord(event.target.value)}/>
                                </Box>
                                <Box maxWidth={width} marginLeft={'auto'} marginRight={'auto'} marginTop={'10px'}>
                                    <TextField value={newDiscord} id='description' multiline fullWidth color='eternal' label='New Apex Account' onChange={(event) => setNewDiscord(event.target.value)}/>
                                </Box>
                                <Box maxWidth={width} marginLeft={'auto'} marginRight={'auto'} marginTop={'10px'}>
                                    <TextField value={oldApex} id='description' multiline fullWidth color='eternal' label='Old Discord Account' onChange={(event) => setOldApex(event.target.value)}/>
                                </Box>
                                <Box maxWidth={width} marginLeft={'auto'} marginRight={'auto'} marginTop={'10px'}>
                                    <TextField value={newApex} id='description' multiline fullWidth color='eternal' label='New Discord Account' onChange={(event) => setNewApex(event.target.value)}/>
                                </Box>
                            </Grid>
                        )
                        :
                        (
                            <Grid item xs={12} sx={{color: 'white', marginTop: '15px'}}>
                                <Typography fontFamily="" gutterBottom>
                                    Please submit links to Battlefy or Liquipedia along with a description
                                </Typography>
                                <Box maxWidth={width} marginLeft={'auto'} marginRight={'auto'} marginTop={'10px'}>
                                    <TextField required value={description} id='description' multiline fullWidth color='eternal' label={descriptionError === "" ? "Description" : descriptionError} onChange={(event) => setDescription(event.target.value)} error={descriptionError !== ""}/>
                                </Box>
                                <Box maxWidth={width} marginLeft={'auto'} marginRight={'auto'} marginTop={'10px'}>
                                    <TextField id='clips' value = {achievements} multiline fullWidth color='eternal' label='Links to Battlefy or Liquipedia' onChange={(event) => setAchievements(event.target.value)}/>
                                </Box>
                            </Grid>
                        )
                }
                <Grid item xs={12}>
                    <Box maxWidth={width} marginLeft={'auto'} marginRight={'auto'}>
                        <TimeoutButton onClick={submit}>Submit</TimeoutButton>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}