import React, { useContext, useEffect, useState } from "react";
import httpCommon from "../../../../../http-common";
import { Loading } from "../../../MiscComponents/Loading";
import { PlayerAccountTable } from "../PlayerAccounts/PlayerAccountTable";
import { Grid, Collapse, Paper, Autocomplete, TextField } from '@mui/material';
import { useHref } from "react-router";
import { TimeoutButton } from "../../../Buttons/TimeoutButton";
import { adminPanelPlayers } from "../../AdminPanelFunctional";

export const Jail = () => {
    const [inmates, setInmates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);

    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [length, setLength] = useState(0);
    const [reason, setReason] = useState('');
    const listOfPlayers = useContext(adminPanelPlayers);

    const href = useHref();
    const isStaff = href.includes("/staffPanel");

    const fetchInmates = async () => {
        const {data: {inmates: fetchedInmates}} = await httpCommon.get('/players/inmates');
        setInmates(fetchedInmates);
        setLoading(false);
    }
    
    useEffect(() => {
        fetchInmates().catch(console.error);
    },[]);

    if(loading) return <Loading />

    const toggleOpen = () => {
        setOpen(!open);
    }

    const punishPlayer = async (player) => {
        try {
            await httpCommon.post('/players/punish', {discordID: player[0], length, reason});
        } catch(err){
            console.log(err);
        }
    }

    // sort by low prio date oldest -> newest
    inmates.sort((a, b) => a.LOW_PRIO - b.LOW_PRIO);
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} display='flex' justifyContent='center' alignItems='center' >
                <Collapse in={open && !isStaff} unmountOnExit timeout='auto' sx={{width: '100%'}}>
                    <Paper elevation={25}> 
                        <Grid container spacing={1}>
                            <Grid item xs={12} className="ms-3 me-3">
                                <Autocomplete
                                    multiple
                                    size='small'
                                    options={listOfPlayers.map(member => member[1])}
                                    renderInput={(params) => <TextField {...params} label="Discord Name" color="eternal"/>}
                                    id="Autocomplete"
                                    onChange={(event, value) => setSelectedPlayers(value)}
                                />
                            </Grid>
                            <Grid item xs={2} className="ms-3">     
                                <TextField 
                                    value={length} 
                                    type="number" 
                                    onChange={(event) => setLength(event.target.value)} 
                                    label='Length'
                                    color='eternal'
                                    size='small' 
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <TextField 
                                    value={reason}  
                                    multiline
                                    onChange={(event) => setReason(event.target.value)} 
                                    label='Reason'
                                    color='eternal'
                                    size='small' 
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2} className="me-3">
                                <TimeoutButton 
                                    color='error'
                                    variant='contained'
                                    onClick={async () => {
                                        for(const p of selectedPlayers){
                                            await punishPlayer(listOfPlayers.find(m => m[1] === p));
                                        }
                                        await fetchInmates();
                                    }}
                                >Punish</TimeoutButton>
                            </Grid>
                        </Grid>
                        
                    </Paper>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <PlayerAccountTable fullAccounts={inmates} fetchFullAccounts={fetchInmates} toggleOpen={isStaff ? "" : toggleOpen} open={open}/>
            </Grid>
        </Grid>
    )
}