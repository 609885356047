import React, {useDeferredValue, useEffect, useState} from "react";
import http from "../../../http-common";
import { Loading } from "../MiscComponents/Loading";
import { Card, CardContent, Grid, TextField } from "@mui/material";
import { MemoLeaderboardTable } from "./LeaderboardTable";

export const LeaderboardCard = ({width}) => {
    const [players, setPlayers] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(true);

    const deferredSearchText = useDeferredValue(searchText);
    const isStale = searchText !== deferredSearchText;

     // component did mount
    useEffect(() => {
        const fetchData = async () => {
            const {data: players} = await http.get('/Players');
            setPlayers(players);
            setLoading(false);
        }
        fetchData().catch(console.error);
    }, []);

    // is loading
    if(loading){
        return(
            <Loading/>
        )
    }

    return (
        <Card sx={{borderRadius: 5, width, margin: "auto", height:"75vh"}}>
            <CardContent id="leaderboardPane" className = "select">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField fullWidth color='eternal' label={isStale ? 'loading' : 'Player Search'} variant='outlined' size='small' onChange={(event) => setSearchText(event.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <MemoLeaderboardTable width={width} searchText={deferredSearchText} players={players}/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}