import React, { useEffect, useState } from "react";
import httpCommon from "../../../../../http-common";
import { Loading } from "../../../MiscComponents/Loading";
import { TokenTable } from "./TokenTable";
import {Grid, Tabs, Tab} from '@mui/material';
import { TokenAdd } from "./TokenAdd";
import { ETERNAL_GREEN } from "../../../../globalConsts.ts";

export const Tokens = () => {
    const [tokens, setTokens] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState('scrim');

    const fetchTokens = async () => {
        const {data: tokens} = await httpCommon.get('/statTokens');
        setTokens(tokens);
        setLoading(false);
    }

    useEffect(() => {
        fetchTokens().catch(console.error);
    }, []);

    if(loading) return <Loading />

    const filteredTokens = tokens.filter(t => t.category === tab);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Tabs value={tab} onChange ={(event, value) => setTab(value)} textColor='inherit'
                    TabIndicatorProps={{style: {backgroundColor: ETERNAL_GREEN, height: 3}}}
                >
                    <Tab label="Scrims" value="scrim"/>
                    <Tab label="Solo Queue" value="soloq"/>
                    <Tab label="Add Token" value='add' />
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                {tab !== 'add' && <TokenTable tokens={filteredTokens} fetchTokens={fetchTokens}/>}
                {tab === 'add' && <TokenAdd fetchTokens={fetchTokens}/>}
            </Grid>
        </Grid>
    )
}