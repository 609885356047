import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper} from '@mui/material';
import { memo, useState } from 'react';
import { useNavigate } from 'react-router';
import { UpChevron, DashChevron, DownChevron } from "../../../images/imgIndex";
import { mmrToRankIMG } from '../../pubFuncs';

export const MemoLeaderboardTable = memo(function LeaderboardTable ({width, searchText, players}){
    const navigate = useNavigate();
    const [reversed, setReversed] = useState(false);
    
    // sort players
    let sortPlayers = players;
    if (!reversed) {
        sortPlayers.sort((a,b) => {
            return b.MMR - a.MMR;
        });
    } else {
        sortPlayers.sort((a,b) => {
            return a.MMR - b.MMR;
        });
    }
    // filter players 
    let filterPlayers = sortPlayers.filter((p) => 
        {
            if(p){
                const discordName = p.discordName || "";
                const IGN = p.IGN || "";
                return discordName.toLowerCase().includes(searchText.toLowerCase()) || IGN.toLowerCase().includes(searchText.toLowerCase())
            } 
            return false;
        }
    );

    return (
        <TableContainer component={Paper} elevation={5} sx={{overflowY: "auto", overflowX: "hidden", color: "white", overflowY: "scroll", height: "67vh"}} >
        <Table size="small" stickyHeader>
            <TableHead>
                    {
                        width == '40%' ? 
                            <TableRow onClick={() => setReversed(!reversed)}>
                                <TableCell style={{textAlign: "center", fontSize: "25px"}}>Rank</TableCell>
                                <TableCell style={{textAlign: "center", fontSize: "25px"}}>Player Name</TableCell>
                                <TableCell style={{textAlign: "center", fontSize: "25px"}}>IGN</TableCell>
                                <TableCell style={{textAlign: "center", fontSize: "25px"}}>MMR</TableCell>
                            </TableRow>
                        :
                            <TableRow onClick={() => setReversed(!reversed)}>
                                <TableCell align="center" sx={{fontSize: "25px"}}>Rank</TableCell>
                                <TableCell align="center" sx={{width: "10px", fontSize: "25px"}}>Name</TableCell>
                                <TableCell align="center" sx={{fontSize: "25px"}}>MMR</TableCell>
                            </TableRow>
                    }
            </TableHead>
            <TableBody>
                {filterPlayers && filterPlayers.map((player) => {
                    let rank2 = players.indexOf(player) + 1;
                    if(reversed){
                        rank2 = players.length - players.indexOf(player);
                    }
                    let selectedChevron = DashChevron;
                    let colorChange = "";
                    if(player.lastMMRChange < 0){
                        selectedChevron = DownChevron;
                        colorChange = "invert(27%) sepia(88%) saturate(7418%) hue-rotate(357deg) brightness(97%) contrast(123%)";
                    } else if (player.lastMMRChange > 0) {
                        selectedChevron = UpChevron;
                        colorChange = "invert(53%) sepia(78%) saturate(2975%) hue-rotate(88deg) brightness(119%) contrast(127%)"
                    } 

                    let rankIcon = mmrToRankIMG(player.MMR, rank2);

                    let rows = (
                        <TableRow onClick={() =>  navigate('../player/'+player.discordName)} key={"row: " + player.discordID}> 
                            <TableCell style={{textAlign: "center"}} key={"rank: " + player.discordID}>
                                <img src={rankIcon} style={{width: "20px", marginRight: "8px"}}/>
                                {rank2}
                            </TableCell>
                            <TableCell style={{textAlign: "center"}} key={"names: " + player.discordID}>{player.discordName}</TableCell> 
                            <TableCell style={{textAlign: "center"}} key={"ign: " + player.discordID}>{player.IGN}</TableCell>
                            <TableCell style={{textAlign: "center"}} key={"mmr: " + player.discordID}>
                                {player.MMR}
                                <img src={selectedChevron} style={{width: "25px", filter: colorChange}}/>
                            </TableCell>
                        </TableRow>
                    )
                    if(width !== "40%"){
                        rows = (
                            <TableRow onClick={() =>  navigate('../player/'+player.discordName)} key={"row: " + player.discordID}> 
                                <TableCell align="center" key={"rank: " + player.discordID}>
                                    <img src={rankIcon} style={{width: "20px", marginRight: "8px"}}/>
                                    {rank2}
                                </TableCell>
                                <TableCell align="center" key={"names: " + player.discordID}>
                                    <div style={{textOverflow: "ellipsis", textAlign: "center", textWrap: "nowrap", width: "125px"}}>{player.discordName}</div>
                                </TableCell>
                                <TableCell align="center" key={"mmr: " + player.discordID}>
                                    {player.MMR}
                                    <img src={selectedChevron} style={{width: "25px", filter: colorChange}}/>
                                </TableCell>
                            </TableRow>
                        )
                    }
                    return rows;
                })}
            </TableBody>
        </Table>
    </TableContainer>
    )
});