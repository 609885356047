import React, {useState} from "react";

import {Avatar, AppBar, Toolbar, IconButton, Box, Typography, Link, Button, Badge, Menu, MenuItem} from '@mui/material'
import { DiscordLogo } from "../../images/imgIndex";
import { HeaderMenuItem } from "../../common/components/Header/HeaderMenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import MenuIconOpen from "@mui/icons-material/MenuOpen";
import { ETERNAL_GREEN } from '../../common/globalConsts.ts';

export const HeaderMobile = ({username, bound, loading, loggedIn, invites, permLevel, avatarImage, checkinOpen, showNotifications, toggleNotifications, numOpenTickets}) => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorBurger, setAnchorBurger] = useState(null);
    const [anchorHelp, setAnchorHelp] = useState(null);

    let redirectLink = "https://discord.com/api/oauth2/authorize?client_id=1205950724811522129&response_type=token&redirect_uri=https%3A%2F%2Feternalesports.club&scope=identify+email+connections+guilds.members.read+guilds";
    if(window.location.origin.includes("b3140286"))
        redirectLink = "https://discord.com/api/oauth2/authorize?client_id=1205950724811522129&response_type=token&redirect_uri=https%3A%2F%2Fb3140286.eternalesports.club&scope=identify+email+connections+guilds.members.read+guilds";
    else if(window.location.origin.includes("73d9c3fb"))
        redirectLink = "https://discord.com/api/oauth2/authorize?client_id=1205950724811522129&response_type=token&redirect_uri=https%3A%2F%2F73d9c3fb.eternalesports.club&scope=identify+email+connections+guilds.members.read+guilds";


    return (
        <AppBar component="nav" position="static" sx={{ background: 'transparent', boxShadow: 'none', mt: 1}} enableColorOnDark>
            <Toolbar style={{fontSize: '2.2em', fontFamily: "Orbitron", textAlign: "center"}}>
            <Box sx={{mr: 4}}>
                <IconButton 
                    sx={{ width: 75, height: 75 }}
                    onClick={(event) => {
                        setAnchorBurger(event.currentTarget);
                    }}     
                > 
                    {anchorBurger === null ? <MenuIcon fontSize="large"/> : <MenuIconOpen fontSize="large" />}
                </IconButton>
                <Menu
                        elevation={5}
                        slotProps={{paper: {sx: {borderRadius: 5, padding: 0}}}}
                        anchorEl={anchorBurger}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        keepMounted
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        open={Boolean(anchorBurger)}
                        onClose={() => setAnchorBurger(null)}
                        
                    >
                    {/* nav items */}
                    <HeaderMenuItem text="Home" to="/" setAnchorElNav={setAnchorElNav}/>
                    <HeaderMenuItem text="Leaderboard" to="/leaderboard" setAnchorElNav={setAnchorElNav}/>
                    <HeaderMenuItem text="Matches" to="/matches" setAnchorElNav={setAnchorElNav}/>
                    <HeaderMenuItem text="Supporters" to="/supporters" setAnchorElNav={setAnchorElNav}/>
                </Menu>
            </Box>
            {/* sign in button */}
            <Box sx={{ml: 'auto', display: loggedIn ? 'none': ''}}>
                <Button 
                    endIcon={
                        <Box 
                            component='img'
                            src={DiscordLogo} 
                            sx={{filter: "invert(100)", width: "45px"}}
                        />  
                    }
                    className="select"
                    sx={{backgroundColor: "grey", "&:hover": {backgroundColor: "#00e682"}}}
                    variant="contained"
                    onClick={() => window.location.href = redirectLink}
                >
                    <Typography fontSize='2em' color="white">
                        Sign In
                    </Typography>
                </Button>
            </Box>
            {/* menu */}
            <Box sx={{ml: 'auto', display: loggedIn ? '': 'none'}}>
                <IconButton 
                    className="logo logo-glow" 
                    sx={{ width: 75, height: 75 }}
                    onClick={(event) => {
                        setAnchorElNav(event.currentTarget);
                    }}     
                >   
                    <Badge badgeContent={permLevel > 2 ? numOpenTickets : 0} color='eternal' archorOrigin={{vertical: 'top', horizontal: 'left'}} overlap="circular">
                        <Badge badgeContent={Boolean(anchorElNav) ? 0 : invites.length} color="error" anchorOrigin={{vertical: "top", horizontal: "right"}} overlap="circular">
                            <Avatar 
                                src={avatarImage} 
                                alt="Player Page"
                                sx={{ width: 75, height: 75 }} 
                            />  
                        </Badge>
                    </Badge>
                </IconButton>
                <Menu
                    elevation={5}
                    slotProps={{paper: {sx: {borderRadius: 5, padding: 0}}}}
                    anchorEl={anchorElNav}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    keepMounted
                    transformOrigin={{vertical: 'top', horizontal: 'left'}}
                    open={Boolean(anchorElNav)}
                    onClose={() => setAnchorElNav(null)}
                    
                >
                    <HeaderMenuItem text="Bind" to={"/player/"+username} hide={bound || loading} alwaysHighlight={true} setAnchorElNav={setAnchorElNav}/>
                    {/* profile items */}
                    <HeaderMenuItem text="Profile" to={"/player/"+username} setAnchorElNav={setAnchorElNav}/>
                    
                    <HeaderMenuItem text='VIP' to='/vip' hide={permLevel < 2} setAnchorElNav={setAnchorElNav}/>
                    {/* <NavLink 
                        style={({ isActive }) => {
                            return {
                                color: ((isActive || !bound) && !loading) ? ETERNAL_GREEN : "grey",
                                textDecoration: "none",
                                textShadow: ((isActive || !bound) && !loading) ? "0px 0px 25px #0fff95" : "none"
                            }
                        }} 
                        to={(bound || loading) ? "/team" : "/player/" + username}>
                        <MenuItem>
                            <Box sx={{mr: 4}}>
                                <Typography fontSize='2em' sx={{"&:hover": { color: ETERNAL_GREEN }}}>{(bound || loading) ? "Team" : "Bind"}</Typography>
                            </Box>
                        </MenuItem>
                    </NavLink> */}

                    <Box sx={{ml: 2}} className='select'>
                        <Box sx={{mr: 4}} onClick={(event) => setAnchorHelp(event.currentTarget)}>
                            <Typography sx={({ isActive }) => {
                                return {
                                    color: (isActive) ? ETERNAL_GREEN : "grey",
                                    textDecoration: "none",
                                    textShadow: (isActive) ? "0px 0px 25px #0fff95" : "none",
                                    "&:hover": { color: ETERNAL_GREEN },
                                    fontSize: '2em'
                                }
                            }}>Help</Typography>
                        </Box> 
                        <Menu
                            elevation={5}
                            slotProps={{paper: {sx: {borderRadius: 5,padding: 0}}}}
                            anchorEl={anchorHelp}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            keepMounted
                            transformOrigin={{vertical: 'top',horizontal: 'right'}}
                            open={Boolean(anchorHelp)}
                            onClose={() => setAnchorHelp(null)}
                            
                        >
                            {/* nav items */}
                            <HeaderMenuItem text="Contact Support" to="/help" setAnchorElNav={setAnchorHelp}/>
                            <HeaderMenuItem text="FAQs" to="/faq" setAnchorElNav={setAnchorHelp}/>
                        </Menu>
                    </Box>

                    
                    {/* <MenuItem onClick={() => toggleNotifications()}>
                        <Box sx={{display: loggedIn ? '': 'none'}}>
                            <Badge badgeContent={invites.length} color="error" anchorOrigin={{vertical: "top", horizontal: "right"}} sx={{'& .MuiBadge-badge': {top: 10}}}>
                                <Typography fontSize='2em' 
                                    sx={{
                                        "&:hover": { color: ETERNAL_GREEN },
                                        color: showNotifications ? ETERNAL_GREEN : "grey",
                                    }} 
                                    >
                                    Notifications
                                </Typography>
                            </Badge>
                        </Box>
                    </MenuItem> */}
                    <HeaderMenuItem to={"/staffpanel"} text="Staff" setAnchorElNav={setAnchorElNav} hide={(permLevel !== 3)} permLevel={permLevel} numOpenTickets={numOpenTickets}/>
                    <HeaderMenuItem to={"/adminpanel"} text="Admin" setAnchorElNav={setAnchorElNav} hide={(permLevel < 4)} permLevel={permLevel} numOpenTickets={numOpenTickets}/>
                    {/* logout */}
                    <Link 
                        className="select"
                        sx={{color: "grey", textDecoration: 'none', "&:hover": { color: ETERNAL_GREEN }}}
                        onClick={() =>{
                            localStorage.clear();
                            localStorage.setItem("loggedIn", false);
                            setAnchorElNav(null);
                            window.location.href = "/";
                        }}
                    >
                        <MenuItem>
                            <Box sx={{display: loggedIn ? '': 'none'}}>
                                <Typography fontSize='2em' sx={{"&:hover": { color: ETERNAL_GREEN }}}>
                                    Sign Out
                                </Typography>
                            </Box>
                        </MenuItem>
                    </Link>
                </Menu>
            </Box>   
            
        </Toolbar>
    </AppBar>
    )
}