import React, { FC } from "react";
import { LLBox } from "./LLBox.tsx";
import { Typography, List, ListItem } from '@mui/material';
import { ETERNAL_GREEN, ETERNAL_PINK } from "../../../globalConsts.ts";

interface Props {
    className? : string;
    width?: number | string;
    fontSize?: number;
}

export const LLQualBox: FC<Props> = ({className, width, fontSize}) : JSX.Element => {
    width = width || '70%';
    fontSize = fontSize || 20;
    return (
        <LLBox className={className} width={width}>
            <Typography className='me-3 mt-auto mb-auto ms-3' color='white' textAlign='left' fontSize={fontSize}>
                <List sx={{listStyleType: 'disc', listStylePosition: 'inside', '& .MuiListItem-root': {display: 'list-item', padding: 0, margin: 0, marginLeft: 1}}}>
                    <ListItem>Players must <Typography component='span' color={ETERNAL_PINK}>not</Typography> be <Typography component='span' color={ETERNAL_PINK}>Y4 ALGS S2 PL</Typography>,  <Typography component='span' color={ETERNAL_PINK}>Y4 Split 1 LAN</Typography>, or <Typography component='span' color={ETERNAL_PINK}>EWC LAN</Typography></ListItem>
                    <ListItem>Qualifier Blocks replace <Typography component='span' color={ETERNAL_GREEN}>7pm</Typography> and <Typography component='span' color={ETERNAL_GREEN}>10:30pm</Typography> scrims</ListItem>
                    <ListItem>All players start with <Typography component='span' color={ETERNAL_GREEN}>500 LP</Typography> (League Points)</ListItem>
                    <ListItem><Typography component='span' color={ETERNAL_GREEN}>LP</Typography> is <Typography component='span' color={ETERNAL_GREEN}>per player</Typography> just like CC points</ListItem>
                    <ListItem><Typography component='span' color={ETERNAL_GREEN}>Substitutes</Typography> risk their <Typography component='span' color={ETERNAL_GREEN}>own LP</Typography> when playing for teams</ListItem>
                </List>
            </Typography>
        </LLBox>
    )
}