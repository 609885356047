import React, { FC, useContext, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField,  } from '@mui/material';
import { TimeoutButton } from '../../../../Buttons/TimeoutButton';
import httpCommon from '../../../../../../http-common';
import { pullLobbiesContext } from '../LobbyPanel.tsx';

interface Props{
    open: boolean;
    setOpen: Function;
}

export const NewLobbyDialog: FC<Props> = ({open, setOpen}): JSX.Element => {

    const [lobbyName, setLobbyName] = useState('');
    const pullLobbies = useContext(pullLobbiesContext);

    const onLobbySubmit = async () => {
        try {
            await httpCommon.post('/lobbies/create', {name: lobbyName});
            pullLobbies();
            setOpen(false);
        } catch(err){
            console.log(err);
        }
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Create a Lobby</DialogTitle>
            <DialogContent>
                {/* @ts-ignore */}
                <TextField color='eternal' autoFocus required margin='dense' id='lobbyName' name='Lobby name' label='Lobby Name' fullWidth variant='outlined' value={lobbyName} onChange={(event) => setLobbyName(event.target.value)}/>
            </DialogContent>
            <DialogActions>
                <TimeoutButton onClick={onLobbySubmit}>Submit</TimeoutButton>
            </DialogActions>
        </Dialog>
    )
} 