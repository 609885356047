import React, { FC, useEffect, useState, useContext, createContext } from "react";
import {Grid, Popover } from "@mui/material";
import { LegendCard } from "./PlayerPageComponents/LegendCard.tsx";
import httpCommon from "../../../http-common.js";
import { Loading } from "../MiscComponents/Loading.js";
import { SocialsCard } from "./PlayerPageComponents/SocialsCard.tsx";
import { RecentGamesCard } from "./PlayerPageComponents/RecentGamesCard.tsx";
import { StatsCard } from "./PlayerPageComponents/StatsCard.tsx";
import { RankCard } from "./PlayerPageComponents/RankCard.tsx";
import { GraphCard } from "./PlayerPageComponents/GraphCard.tsx";
import { TeammatesCard } from "./PlayerPageComponents/TeammatesCard.tsx";
import { SettingsModal } from "./PlayerPageComponents/SettingsModal.tsx";
import { MobileContext } from "../../../AppFunctional.js";
import { playerDiscordContext, playerIDContext } from "./PlayerPageFunctional.tsx";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard.js";
import { PlayerPageHeader } from "./PlayerPageComponents/PlayerPageHeader.tsx";
import { TRACK_MMR_TYPE } from "../../globalConsts.ts";
import { useNavigate } from "react-router";

const currentSeasonName = 'Season 2 (Current)';
export const currentSeasonContext = createContext<string>(currentSeasonName);
export const colorContext = createContext<[string, Function]>(['', (inCol: string) => console.log('set color: ', inCol)]);
export const setCurrentSeasonContext = createContext<Function>(() => console.log('set current season'));

interface Props {
    lookingAtMMR: number;
    startingMMR: number;
}

export const BoundPage: FC<Props> = ({lookingAtMMR, startingMMR}) => {

    const playerDiscord = useContext<string>(playerDiscordContext);
    const discordID = useContext<number | string>(playerIDContext);

    const navigate = useNavigate();
    const copy = useCopyToClipboard();

    const urlParams = new URLSearchParams(window.location.search);

    const [showCopyPopover, setShowCopyPopover] = useState<boolean>(false);
    const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
    const [popoverTarget, setPopoverTarget] = useState<Element | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [color, setColor] = useState<string>('#000000');
    const [displayName, setDisplayName] = useState<string>(playerDiscord); 
    const [connectionSettings, setConnectionSettings] = useState<any>({});
    const [connectionList, setConnectionList] = useState<any>({});
    const [statMap, setStatMap] = useState<Map<string, string | number | string[]>>(new Map());
    const [trackData, setTrackData] = useState<TRACK_MMR_TYPE[]>([]);
    const [season, setSeason] = useState<string | null>(urlParams.get('season') || currentSeasonName);
    const [seasonParticipation, setSeasonParticipation] = useState<any>({});

    const isMobile = useContext<boolean>(MobileContext);

    const setCurrentSeason = (seasonName: string | null) => {
        if(!seasonName){
            setSeason(null);
            urlParams.delete('season');
        } else {
            setSeason(seasonName);
            urlParams.set('season', seasonName);
        }
        navigate(`/player/${playerDiscord}?${urlParams}`, { replace: true })
    }


    useEffect(() => {
        const pullCustomizations = async () => {
            if(discordID !== -1) {
                const {data: {profileCustomization: customizations}} = await httpCommon.get("/eternalAccounts/profile?discordID="+discordID);
                if(Boolean(customizations.displayName)) setDisplayName(customizations.displayName);
                if(Boolean(customizations.color)) setColor(customizations.color);
                if(Boolean(customizations.connectionSettings)) setConnectionSettings(customizations.connectionSettings);
            }
        }

        pullCustomizations().catch(console.error);
    }, [discordID, playerDiscord]);

    useEffect(() => {
        const pullStatMap = async () => {
            if(discordID !== -1){
                let sendSeason = season;
                if(season === currentSeasonName) sendSeason = 'current'
                const {data: resultStats} = await httpCommon.get("/match_data/stats?discordID=" + discordID + "&season="+sendSeason);
                const numGames = resultStats.numGames || 0;
                const total_kills = resultStats.kills || 0;
                const total_dmg = resultStats.damageDealt || 0;
                const total_placement = resultStats.teamPlacement || 0;
                const character_list = resultStats.characterName || [];
                const avg_accuracy = (resultStats.hits || 0) / (resultStats.shots || 1) * 100;
    
                const avg_kills = total_kills / numGames;
                const avg_dmg = total_dmg / numGames;
                const avg_placement = total_placement / numGames;
    
                const statMap = new Map();
                statMap.set('avg_kills', Number((avg_kills).toFixed(1)));
                statMap.set('avg_dmg',  Number((avg_dmg).toFixed(1)));
                statMap.set('avg_acc',  Number((avg_accuracy).toFixed(1)));
                statMap.set('char', character_list);
                statMap.set('num_matches', numGames);
                statMap.set('avg_place', Number((avg_placement).toFixed(1)));
                statMap.set('total_kills', total_kills);
                statMap.set('total_dmg', total_dmg);
                statMap.set('playerNames', resultStats.playerName || []);
    
                setStatMap(statMap);
            }
            
        }

        const pullParticipations = async () => {
            if(discordID !== -1){
                const {data: seasonMap} = await httpCommon.get('/matches/part?discordID='+discordID);
                setSeasonParticipation(seasonMap);
            }
        }

        const pullTrackData = async () => {
            if(discordID !== -1){
                const {data: careerData} = await httpCommon.get('/track_mmr/career?discordID='+discordID);
                const trackData: TRACK_MMR_TYPE[] = [];
                if(!season)
                    Object.keys(careerData).forEach(k => trackData.push(...careerData[k]));
                else{
                    const seasonMap = {};
                    seasonMap[currentSeasonName] = 'current';
                    const data = careerData[seasonMap[season] || season];
                    if(data)
                        trackData.push(...data);
                }

                setTrackData(trackData);
            }
            await pullStatMap().catch(console.error);
            await pullParticipations().catch(console.error);
            setLoading(false);
        }

        pullTrackData().catch(console.error);
    }, [discordID, playerDiscord, season]);

    const onTitleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        setPopoverTarget(event.target as HTMLInputElement);
        setShowCopyPopover(true);
        copy(window.location);
        setTimeout(() => {
            setShowCopyPopover(false);
            setPopoverTarget(null);
        }, 1000);
    }
    
    // is loading
    if(loading){
        return(
            <div className = "page">
                <div className="other-page"/>  
                <Loading/>
            </div>
        )
    }

    if(isMobile){
        return (
            <colorContext.Provider value={[color, setColor]}>
                <currentSeasonContext.Provider value={currentSeasonName}>   
                    <setCurrentSeasonContext.Provider value={setCurrentSeason}>
                        <Grid container spacing={2} sx={{width: "100%", marginLeft: "auto", marginRight: "auto"}}>
                            <Grid item xs={12}>
                                <PlayerPageHeader 
                                    displayName={displayName} 
                                    onTitleClick={onTitleClick} 
                                    setShowSettingsModal={setShowSettingsModal}
                                    seasonParticipation={seasonParticipation}
                                />
                                <SettingsModal 
                                    statMap={statMap} 
                                    settingsModal={[showSettingsModal, setShowSettingsModal]}
                                    displayName={[displayName, setDisplayName]}
                                    connectionSettings={[connectionSettings, setConnectionSettings]}
                                    connectionList={connectionList}
                                />
                                <Popover open={showCopyPopover} anchorEl={popoverTarget}>Copied to Clipboard</Popover>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <StatsCard 
                                            statMap={statMap} 
                                            season={[season, setCurrentSeason]} 
                                            seasonParticipation={seasonParticipation}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RankCard currentMMR={lookingAtMMR} season={season}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SocialsCard connections={[connectionSettings, setConnectionList]} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LegendCard statMap={statMap} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RecentGamesCard trackData={trackData} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <GraphCard trackData={trackData} startingMMR={season === 'LegacyLeague' ? 500 : startingMMR} season={season}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TeammatesCard />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </setCurrentSeasonContext.Provider>
                </currentSeasonContext.Provider>
            </colorContext.Provider>
        );
    }

    return (
        <colorContext.Provider value={[color, setColor]}>
            <currentSeasonContext.Provider value={currentSeasonName}>   
                <setCurrentSeasonContext.Provider value={setCurrentSeason}>
                    <Grid container spacing={2} sx={{width: "80%", marginLeft: "auto", marginRight: "auto"}}>
                        <Grid item xs={12}>
                            <PlayerPageHeader 
                                displayName={displayName} 
                                onTitleClick={onTitleClick} 
                                setShowSettingsModal={setShowSettingsModal}
                                seasonParticipation={seasonParticipation}
                            />
                            <SettingsModal 
                                statMap={statMap} 
                                settingsModal={[showSettingsModal, setShowSettingsModal]}
                                displayName={[displayName, setDisplayName]}
                                connectionSettings={[connectionSettings, setConnectionSettings]}
                                connectionList={connectionList}
                            />
                            <Popover open={showCopyPopover} anchorEl={popoverTarget}>Copied to Clipboard</Popover>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <LegendCard statMap={statMap} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SocialsCard connections={[connectionSettings, setConnectionList]} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <StatsCard 
                                                statMap={statMap} 
                                                season={[season, setCurrentSeason]} 
                                                seasonParticipation={seasonParticipation}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <RankCard currentMMR={lookingAtMMR} season={season}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RecentGamesCard trackData={trackData} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <GraphCard trackData={trackData} startingMMR={season === 'LegacyLeague' ? 500 : startingMMR} season={season}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TeammatesCard />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </setCurrentSeasonContext.Provider>
            </currentSeasonContext.Provider>
        </colorContext.Provider>
    );
}