import React, { useContext, useEffect, useState } from "react";
import httpCommon from "../../../../../http-common";
import { Loading } from "../../../MiscComponents/Loading";
import { MobileContext } from "../../../../../AppFunctional";
import { TeamPageMobile } from "../../../../../components/mobile/pages/TeamPageMobile";
import { TeamPageDesktop } from "../../../../../components/desktop/pages/TeamPageDesktop";
import {Paper} from '@mui/material';

export const SelectedTeam = ({team, setSelectedTeam}) => {
    const captain = team.team.captain;
    const players = team.teammates.map(tm => tm.player);

    const [playerObjects, setPlayerObjects] = useState([]);
    const [sentInvites, setSentInvites] = useState([]);
    const [listOfPlayers, setListOfPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const isMobile = useContext(MobileContext);

    const fetchTeam = async () => {
        try {
            const {data: team} = await httpCommon.get('/teams/discordID?discordID=' + captain);
            setSelectedTeam(team.team.teamname);
        } catch(err){
            if(err.response)
                if(err.response.status === 404){
                    setSelectedTeam(null);
                    setLoading(false);
                    console.log("missing team");
                }
        }
    }


    useEffect(() => {
        const fetchInvites = async () => {
            try {
                const {data: {invites: sentInvites}} = await httpCommon.get("/teams/mySentInvites?discordID=" + captain);
                setSentInvites(sentInvites);
            } catch(err){
                console.log(err);
            }
        }

        const fetchListOfPlayers = async () => {
            try {
                const {data: listOfPlayers} = await httpCommon.get("/eternalAccounts/discordMembers");
                setListOfPlayers(listOfPlayers);
            } catch(err) {
                console.log(err);
            }
        }

        const fetchPlayers = async () => {
            const teammates = players;
            teammates.push(captain)
            
            const teammateObjs = [];
            for(const teammate of teammates){
                try {
                    const {data: playerAccount} = await httpCommon.get('/players/id?discordID='+teammate);
                    teammateObjs.push(playerAccount);
                } catch(error){
                    console.log(error);
                }
            };
            setPlayerObjects(teammateObjs);
            await fetchInvites();
            await fetchListOfPlayers();
            setLoading(false);
        }

        fetchPlayers().catch(console.error);

    }, [captain, players]);

    
    if(!team) return "Team Doesn't Exist";
    if(loading) return <Loading/>

    return (
        isMobile ? 
            (
                <Paper elevation={5} sx={{height: '70vh'}}>
                    <TeamPageMobile team={team} players={playerObjects} 
                        discordID={captain} isCaptain 
                        fetchTeam={fetchTeam} listOfPlayers={listOfPlayers}
                        sentInvites={sentInvites} onPrivate/>
                </Paper>
            ) 
            : 
            (
                <Paper elevation={5} sx={{height: '70vh'}}>
                    <TeamPageDesktop team={team} players={playerObjects} 
                        discordID={captain} isCaptain 
                        fetchTeam={fetchTeam} listOfPlayers={listOfPlayers}
                        sentInvites={sentInvites} onPrivate/>
                </Paper>
            )
    )

}