import React, { useState } from "react";
import {TableCell, TextField, TableRow, Grid} from '@mui/material';
import { TimeoutButton } from "../../../../Buttons/TimeoutButton";
import httpCommon from "../../../../../../http-common";

export const PunishRow = ({account, fetchFullAccounts}) => {
    const [punishLength, setPunishLength] = useState(0);
    const [reason, setReason] = useState("");

    const punishPlayer = async () => {
        try {
            await httpCommon.post('/players/punish', {discordID: account.discordID, length: punishLength, reason});
            fetchFullAccounts();
        } catch(err){
            console.log(err);
        }
    }

    return (
        <TableRow>
            <TableCell colSpan={4}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>     
                        <TextField 
                            value={punishLength} 
                            type="number" 
                            onChange={(event) => setPunishLength(event.target.value)} 
                            label='Length'
                            color='eternal'
                            size='small' 
                            InputLabelProps={{shrink: true}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            value={reason}  
                            multiline
                            onChange={(event) => setReason(event.target.value)} 
                            label='Reason'
                            color='eternal'
                            size='small' 
                            InputLabelProps={{shrink: true}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TimeoutButton onClick={() => punishPlayer()} color='error'>Punish</TimeoutButton>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    )
}