import React, { useState } from "react";
import {Table, TableRow, TableBody, Card, CardContent, Grid} from "@mui/material";
import * as Icon from "react-bootstrap-icons";
import { survivalTimerConverter, characterIconSelect } from "../../../../common/pubFuncs";
import httpCommon from "../../../../http-common";
import { Gun, Headshot, Hit, Skull } from "../../../../images/imgIndex";

export const PlayerTableMobile = ({matchData}) => {

    const [currentSort, setCurrentSort] = useState("teamPlacement");
    const [reversed, setReversed] = useState(false)

    matchData.sort((a,b) => a[currentSort] - b[currentSort]);
    if(reversed)
        matchData.reverse();

    function handleSortClick(sort){
        if(currentSort === sort){
            setReversed(!reversed);
        } else {
            setCurrentSort(sort)
        }
    }

    return (
        <Table>
            <TableBody>
                {
                    matchData.map(row => {
                        return (
                            <TableRow colSpan={4}> 
                                <Card style={{marginTop: "10px"}}> 
                                    <CardContent>
                                        <Grid container spacing={1}>
                                            <Grid item xs={9} className = "playername" key = {"pName: "} onClick={async () => {
                                                try {
                                                    const {data: player} = await httpCommon.get("/players/id?discordID=" + row.discordID);
                                                    if(player)
                                                        window.location.href = "/player/" + player.discordName;
                                                } catch(err){}
                                            }}>
                                                {row.playerName}
                                                <img src = {characterIconSelect(row.characterName)} alt={row.characterName} width={"25px"} height={"25px"} style={{marginLeft: "5px", filter: "invert(100)"}}/>
                                            </Grid>
                                            <Grid item xs={3} className="placement" key = {"placement: "  } onClick={() =>  handleSortClick("teamPlacement")}>
                                                {row.teamPlacement}
                                                <Icon.Award size="25" style={{marginLeft: "3px"}}/>
                                            </Grid>
                                            <Grid xs={12}>
                                                <hr/>
                                            </Grid>
                                            <Grid item xs={3} sx={{textAlign: "center"}} className = "kills" key = {"kills: "  } onClick={() =>  handleSortClick("kills")}>
                                                <img src={Skull} style={{width: "20px", filter: "invert(180)"}} alt='skull'/>                             
                                                <br/>
                                                {row.kills}
                                            </Grid>
                                            <Grid item xs={3} sx={{textAlign: "center"}} className = "dmg" key = {"dmg: "  } onClick={() =>  handleSortClick("damageDealt")}>
                                                <Icon.Crosshair size="20" style={{marginRight: "3px"}}/>
                                                <br/>
                                                {row.damageDealt}
                                            </Grid>
                                            <Grid item xs={3} sx={{textAlign: "center"}} className = "survTime" key = {"surv: "  } onClick={() =>  handleSortClick("survivalTime")}>
                                                <Icon.Clock size="20" style={{marginRight: "3px"}}/>
                                                <br/>
                                                {survivalTimerConverter(row.survivalTime)}
                                            </Grid>
                                            <Grid item xs={3} sx={{textAlign: "center"}} className = "shots" key = {"shots : "  }onClick={() =>  handleSortClick("shots")}>
                                                <img src={Gun} style={{width: "20px", filter: "invert(180)"}} alt='gun'/>
                                                <br/>
                                                {row.shots}
                                            </Grid>
                                            <Grid item xs={3} sx={{textAlign: "center"}} className = "hits" key = {"hits: "  } onClick={() =>  handleSortClick("hits")}>
                                                <img src={Hit} style={{width: "20px"}} alt='hit'/>
                                                <br/>
                                                {row.hits}
                                            </Grid>
                                            <Grid item xs={3} sx={{textAlign: "center"}} className = "hs" key = {"hs : "  } onClick={() =>  handleSortClick("headshots")}>
                                                <img src={Headshot} style={{width: "25px"}} alt='headshot'/>
                                                <br/>
                                                {row.headshots}
                                            </Grid>
                                            <Grid item xs={3} sx={{textAlign: "center"}} className = "kds" key = {"kds: "  } onClick={() =>  handleSortClick("knockdowns")}>
                                                <Icon.PersonFillDown size="25" style={{marginRight: "3px"}}/>
                                                <br/>
                                                {row.knockdowns}
                                            </Grid>
                                            <Grid item xs={3} sx={{textAlign: "center"}} className = "revives" key = {"rev: "  } onClick={() =>  handleSortClick("revivesGiven")}>
                                                <Icon.PersonFillUp size="25" style={{marginRight: "3px"}}/>
                                                <br/>
                                                {row.revivesGiven}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </TableRow>
                        );
                    })
                }
            </TableBody>
        </Table>
    )
}