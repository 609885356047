import React, { FC } from "react";
import { LegacyPoints } from "../../../../images/imgIndex";
import { Box } from '@mui/material';

interface Props {
    imgHeight?: number | string;
    className?: string;
}

export const LLPoints: FC<Props> = ({imgHeight, className}) : JSX.Element => {
    imgHeight = imgHeight || 'auto'
    return (
        <Box className={className}>
            <img alt='Scoring' src={LegacyPoints} style={{maxHeight: imgHeight}} />
        </Box>
    )
}