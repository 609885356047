import React, { FC, useContext, useEffect, useState } from "react";
import {Card, CardContent, Grid, ToggleButton, ToggleButtonGroup, TextField, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper} from "@mui/material";
import { HiddenElement } from "./HiddenElement.js";
import * as Icon from "react-bootstrap-icons"
import { Skull } from "../../../../images/imgIndex.js";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { buildCategories } from "../../../pubFuncs.js";
import httpCommon from "../../../../http-common.js";
import { useNavigate } from "react-router";
import { onUserProfileContext, permLevelContext, playerIDContext } from "../PlayerPageFunctional.tsx";
import { ETERNAL_GREEN, MATCH_DATA_TYPE, TRACK_MMR_TYPE } from "../../../globalConsts.ts";
import { colorContext } from "../BoundPage.tsx";

interface Props {
    trackData: any[];
}

export const RecentGamesCard: FC<Props> = ({trackData}) => {
    const navigate = useNavigate();
    const [buttonState, setButtonState] = useState<number>(6);
    const [gamesFilter, setGamesFilter] = useState<string>("");
    const [matchData, setMatchData] = useState<MATCH_DATA_TYPE[]>([]);

    const [color, ] = useContext<[string, Function]>(colorContext);
    const onUserProfile = useContext<boolean>(onUserProfileContext);
    const permLevel = useContext<number>(permLevelContext);

    const discordID = useContext<number | string>(playerIDContext);

    const numedTrackData = trackData.slice(trackData.length - (buttonState === -1 ? trackData.length : buttonState), trackData.length).reverse();

    useEffect(() => {
        const fetchMatchData = async () => {
            const {data: resultMatchData} = await httpCommon.get("/match_data/playerID?discordID="+discordID);
            setMatchData(resultMatchData);
        }

        fetchMatchData().catch(console.error);
    }, [discordID]);

    if((permLevel >= 2) || (permLevel === 1 && onUserProfile)){
        return(
            <Card sx={{borderRadius:5, border: color + " 2px solid"}}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={9}>
                                    {/* @ts-ignore */}
                                    <TextField label="Date" size="small" color="eternal" onChange={(event) => setGamesFilter(event.target.value)}/>
                                </Grid>
                                <Grid item xs={2}>
                                    <ToggleButtonGroup 
                                    onChange={(event, value) => {
                                        if(value !== null)
                                            setButtonState(value);
                                    }}
                                    exclusive
                                    value={buttonState}
                                    size="small"
                                    >
                                        <ToggleButton value={6}>6</ToggleButton>
                                        <ToggleButton value={12}>12</ToggleButton>
                                        <ToggleButton value={-1}>All</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper} elevation={5} sx={{overflowY: "auto", overflowX: "hidden", maxHeight: 270}}>
                                <Table stickyHeader size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                <Icon.Clock size="23"/>
                                            </TableCell>
                                            <TableCell align="center">
                                                <img src={Skull} style={{width: "25px", filter: "invert(180)"}} alt="skull"/> 
                                            </TableCell>
                                            <TableCell align="center">
                                                <Icon.Award size="25"/>
                                            </TableCell>
                                            <TableCell align="center">
                                                <ShowChartIcon/>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            numedTrackData && numedTrackData.map((match, index) => {
                                                const mid = match.mid;
                                                const matchMMR = Math.round(match.matchMMR);
                                                const match_start = buildCategories(match.match_start).split(" ");

                                                if(match_start[0].includes(gamesFilter)){
                                                    const currentMatchData = matchData.filter(m => m.mid === mid);

                                                    let placement = 0;
                                                    let kills = 0;
                                                    if(currentMatchData.length > 0){
                                                        placement = currentMatchData[0].teamPlacement;
                                                        kills = currentMatchData[0].kills;
                                                    }

                                                    return (
                                                        <TableRow className="select" onClick={() => navigate('../matches/id/'+mid)}> 
                                                            <TableCell sx={{whiteSpace: "nowrap", overflow: "hidden"}} align="center">
                                                                <span style={{color: ETERNAL_GREEN}}>{buildCategories(match.match_start)}</span> 
                                                            </TableCell>
                                                            <TableCell sx={{fontSize: 18, whiteSpace: "nowrap", overflow: "hidden"}} align="center" key={"date: " + index}>
                                                                <span style={{color: ETERNAL_GREEN}}>{kills}</span>
                                                            </TableCell> 
                                                            <TableCell sx={{fontSize: 18, whiteSpace: "nowrap", overflow: "hidden"}} align="center" key = {"map: " + index}>
                                                                <span style={{color: ETERNAL_GREEN}}>{placement}</span>
                                                            </TableCell>
                                                            <TableCell sx={{fontSize: 18, whiteSpace: "nowrap", overflow: "hidden"}} align="center"  key = {"mmr: " + index}>
                                                                <span style={{color: ETERNAL_GREEN}}>{matchMMR}</span>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                } else return ""
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    } 

    return (
        <HiddenElement permLevel={permLevel} onUserProfile={onUserProfile} color={color}/>
    )
}