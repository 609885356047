import { Box, Tooltip } from '@mui/material';
import { SeasonBadges } from "../../../../images/imgIndex.js";
import React, { FC, useContext } from 'react';
import { SEASON_NAME_MAP, SEASON_NAME_MAP_2 } from '../../../globalConsts.ts';
import { setCurrentSeasonContext } from '../BoundPage.tsx';

interface Props {
    seasonParticipation: any;
}

export const ParticipationBadges: FC<Props> = ({seasonParticipation}) => {
    const keys = Object.keys(seasonParticipation);

    const setCurrentSeason = useContext(setCurrentSeasonContext);

    return (
        <Box display='flex' flexDirection='row'>
            {
                keys && keys.map(k => {
                    let tooltiptext = SEASON_NAME_MAP[k];
                    if(!tooltiptext) tooltiptext = k;

                    if(seasonParticipation[k]) return (
                        <Box className="mt-auto mb-auto me-4" onClick={() => {
                                setCurrentSeason(SEASON_NAME_MAP_2[k])
                            }}>
                            <Tooltip title={tooltiptext} arrow>
                                <img src={SeasonBadges[k]} alt={k} width={40} height={40}/>
                            </Tooltip>
                        </Box>
                    );
                    else return ""
                })
            }
        </Box>
    )
}