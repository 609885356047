import React, { useContext, useState } from "react";
import {Card, CardContent, Grid, Tabs, Tab} from '@mui/material'
import { MatchesTable } from "./MatchesTable";
import { MobileContext } from "../../../AppFunctional";
import { ETERNAL_GREEN } from "../../globalConsts.ts";

export const MatchesPageFunctional = () => {
    const [tab, setTab] = useState("scrims");

    const isMobile = useContext(MobileContext);
    const width = isMobile ? "100%" : "40%";

    return (
        <div className = "page">
            <div className="other-page"/> 
            <Grid container spacing={0}>
                <Grid item xs={12} className="glow" style={{fontSize: '3em', fontFamily: "Orbitron", textAlign: "center"}}>Match Archive</Grid>
                <Grid item xs={12}>
                    <Card style={{width, margin: "auto", height:"80vh"}}>
                        <CardContent>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Tabs value={tab} onChange ={(event, value) => setTab(value)} textColor='eternal' TabIndicatorProps={{style: {
                                        backgroundColor: ETERNAL_GREEN, height: 3
                                    }}}>
                                        <Tab label="Scrims" value="scrims"/>
                                        <Tab label="Solo Queue" value="soloq"/>
                                        <Tab label='Legacy League 1' value='legacyleague'/>
                                        
                                    </Tabs>
                                </Grid>
                                <Grid item xs={12}>
                                    <MatchesTable type={tab} isBinding={false}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card> 
                </Grid>
            </Grid>
        </div>
    )
}