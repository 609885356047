import { Box } from '@mui/material';
import React, { FC, useContext } from 'react';
import { ParticipationBadges } from './ParticipationBadges.tsx';
import { MobileContext } from '../../../../AppFunctional';
import { DiamondOutlined, Settings } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { isVIPContext, onUserProfileContext } from '../PlayerPageFunctional.tsx';

interface Props {
    displayName: string;
    onTitleClick: Function;
    setShowSettingsModal: Function;
    seasonParticipation: Array<{key: boolean}>;
}

export const PlayerPageHeader: FC<Props> = ({displayName, onTitleClick, setShowSettingsModal, seasonParticipation}) => {
    const isMobile = useContext<boolean>(MobileContext);
    const isVIP = useContext<boolean>(isVIPContext);
    const onUserProfile = useContext<boolean>(onUserProfileContext);

    if(isMobile)
        return (
            <Box display='flex' flexDirection='column'>
                <span className="glow select" style={{fontSize: '3em', fontFamily: "Orbitron"}} onClick={(event) => onTitleClick(event)}>
                    {isVIP ? 
                        <Tooltip title={'VIP'} arrow>
                            <DiamondOutlined sx={{fontSize: '3em', color: 'white'}} className='mt-auto mb-auto me-3'/>
                        </Tooltip>
                        : ""}
                    {displayName}
                    {onUserProfile ? <Settings sx={{fontSize: '1em', color: 'white'}} className="select mt-auto mb-auto ms-4" onClick={() => setShowSettingsModal(true)}/> : ""} 
                </span> 
                <ParticipationBadges seasonParticipation={seasonParticipation}/>
            </Box>
        )
    
    return (
        <Box display='flex' flexDirection='row'>
            {isVIP ? 
                <Tooltip title={'VIP'} arrow>
                    <DiamondOutlined sx={{fontSize: '3em', color: 'white'}} className='mt-auto mb-auto me-3'/>
                </Tooltip> 
                : ""}
            <span className="glow select me-3" style={{fontSize: '3em', fontFamily: "Orbitron"}} onClick={(event) => onTitleClick(event)}>
                {displayName}
            </span>  
            <ParticipationBadges seasonParticipation={seasonParticipation}/>
            <Box width={32} className='ms-auto me-5' fontSize='3em'>
                {onUserProfile ? <Settings sx={{fontSize: '1em', color: 'white'}} className="select mt-auto mb-auto ms-4" onClick={() => setShowSettingsModal(true)}/> : ""} 
            </Box>
        </Box>
    );
}