import React, { FC, useContext, useId, useState } from 'react';
import { Box, Typography, Paper, Autocomplete, TextField, Grid } from '@mui/material';
import { MobileContext } from '../../../../AppFunctional';
import { TimeoutButton } from '../../Buttons/TimeoutButton';
import httpCommon from '../../../../http-common';

type PLAYER_NAME_T = {
    discordID: number | string;
    discordName: string;
}

interface Props {
    listOfPlayers: PLAYER_NAME_T[];
}

export const PlayerReport: FC<Props> = ({listOfPlayers}): React.ReactNode => {
    const [reportingPlayers, setReportingPlayer] = useState<PLAYER_NAME_T[]>([]);
    const [clips, setClips] = useState<string>('');
    const [addInfo, setAddInfo] = useState<string>('');
    const isMobile = useContext(MobileContext);
    
    return (
        <Box>
            <Box height={50} width={isMobile ? '100%' : '50vw'} margin='auto' marginTop={2}>
                <Typography fontSize='2em' textAlign='center' color='white'>Low Prio Report</Typography>
            </Box>
            <Box height={50} width={isMobile ? '100%' : '50vw'} margin='auto' marginTop={2}>
                <Paper elevation={5} >
                    <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' margin='auto'>
                        <Typography fontSize='1.5em' textAlign='center' color='white' className='mt-2'>Players to Report</Typography>
                        <Autocomplete
                            key='autocompleteKey'
                            multiple
                            size="small"
                            options={listOfPlayers.map(member => member[1])}
                            // @ts-ignore
                            renderInput={(params) => <TextField {...params} label="Discord Name(s)" color="eternal" required/>}
                            id="Autocomplete"
                            onChange={(event, value) => setReportingPlayer(() => {
                                const retList: PLAYER_NAME_T[] = [];
                                for(const player of value){
                                    const foundPlayer = listOfPlayers.find(f => f[1] === player);
                                    retList.push({
                                        discordID: foundPlayer ? foundPlayer[0] || '' : '', 
                                        discordName: foundPlayer ? foundPlayer[1] || '' : '' 
                                    });
                                }
                                return retList;
                            })}
                            className="m-2"
                            sx={{width: '98%'}} 
                        />
                    </Box>
                    <Box margin='auto'>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            {/* @ts-ignore */}
                            <TextField label='Clips' color='eternal' size='small' sx={{width: '98%'}} multiline required className='m-2' value={clips} onChange={(event) => setClips(event.target.value)}/>
                        </Box>
                    </Box>
                    <Box margin='auto'>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            {/* @ts-ignore */}
                            <TextField label='Additional Information' color='eternal' size='small' sx={{width: '98%'}} multiline className='m-2' value={addInfo} onChange={(event) => setAddInfo(event.target.value)}/>
                        </Box>
                    </Box>
                    <Box margin='auto'>
                        <TimeoutButton color='eternal' size='small' className='m-2' onClick={async () => {
                            await httpCommon.post('/help/post', {topic: 'Staff', ReportedUsers: reportingPlayers, clips, addInfo})
                        }}>Submit</TimeoutButton>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}