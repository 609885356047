import React from "react";
import {Menu, MenuItem, Typography, Link, Box, Badge} from "@mui/material";
import { HeaderMenuItem } from "./HeaderMenuItem";
import { ETERNAL_GREEN } from "../../globalConsts.ts";

export const HeaderMenu = ({anchorElNav, setAnchorElNav, username, loggedIn, permLevel, numOpenTickets}) => {

    return (
        <Menu
            elevation={5}
            slotProps={{
                paper: {
                    sx: {
                        borderRadius: 5,
                        padding: 0
                    }
                }
            }}
            anchorEl={anchorElNav}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={() => setAnchorElNav(null)}
        >
            <HeaderMenuItem to={"/player/"+username} text="Profile" setAnchorElNav={setAnchorElNav} />
            
            {/* <NavLink 
                style={({ isActive }) => {
                    return {
                        color: ((isActive || !bound) && !loading) ? ETERNAL_GREEN : "grey",
                        textDecoration: "none",
                        textShadow: ((isActive || !bound) && !loading) ? "0px 0px 25px " + {ETERNAL_GREEN} : "none"
                    }
                }} 
                to={(bound || loading) ? "/team" : "/player/" + username}>
                <MenuItem>
                    <Box sx={{mr: 4}}>
                                <Typography fontSize='2em' sx={{"&:hover": { color: ETERNAL_GREEN }}}>{(bound || loading) ? "Team" : "Bind"}</Typography>
                    </Box>
                </MenuItem>
            </NavLink> */}
            {/* <MenuItem onClick={() => toggleNotifications()}>
                <Box sx={{display: loggedIn ? '': 'none'}}>
                    <Badge badgeContent={invites.length} color="error" anchorOrigin={{vertical: "top", horizontal: "right"}} sx={{'& .MuiBadge-badge': {top: 10}}}>
                        <Typography fontSize='2em' 
                            sx={{
                                "&:hover": { color: ETERNAL_GREEN },
                                color: showNotifications ? ETERNAL_GREEN : "grey",
                            }} 
                            >
                            Notifications
                        </Typography>
                    </Badge>
                </Box>
            </MenuItem> */}
            <HeaderMenuItem to={"/staffpanel"} text="Staff" setAnchorElNav={setAnchorElNav} hide={(permLevel !== 3)} permLevel={permLevel} numOpenTickets={numOpenTickets}/>
            <HeaderMenuItem to={"/adminpanel"} text="Admin" setAnchorElNav={setAnchorElNav} hide={(permLevel < 4)} permLevel={permLevel} numOpenTickets={numOpenTickets}/>
            <Link 
                className="select"
                sx={{color: "grey", textDecoration: 'none', "&:hover": { color: ETERNAL_GREEN }}}
                onClick={() =>{
                    localStorage.clear();
                    localStorage.setItem("loggedIn", false);
                    setAnchorElNav(null);
                    window.location.href = "/";
                }}
            >
                <MenuItem>
                    <Box sx={{display: loggedIn ? '': 'none'}}>
                        <Typography fontSize='2em' sx={{"&:hover": { color: ETERNAL_GREEN }}}>
                            Sign Out
                        </Typography>
                    </Box>
                </MenuItem>
            </Link>
        </Menu>
    )
}