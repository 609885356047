import React, { useState } from "react";
import { TimeoutButton } from "./TimeoutButton";
import {Dialog, DialogActions, Button, DialogTitle} from '@mui/material';

export const ConfirmationButton = (props) => {
    const [showConfirmation, setShowConfirmation] = useState(false);

    const { onClick, text, children, ...other} = props;

    const handleOk = () => {
        setShowConfirmation(false);
        onClick();
    }

    const handleNo = () => {
        setShowConfirmation(false);
    }


    return (
        <>
            <Dialog
                sx={{ '& .MuiDialog-paper': {width: '60%', maxHeight: 500}}}
                maxWidth='xs'
                open={showConfirmation}
            >
                <DialogTitle sx={{fontFamily: "", textAlign: 'center'}}>
                    {children}
                </DialogTitle>
                <DialogActions>
                    <TimeoutButton onClick={handleOk} color='eternal' variant='outlined'>Yes</TimeoutButton>
                    <TimeoutButton onClick={handleNo} color='error' variant='outlined'>No</TimeoutButton>
                </DialogActions>
            </Dialog>
            <Button 
                onClick={() => {
                    setShowConfirmation(true);
                }}
                {...other} 
            >{text}</Button>
        </>
        )
}