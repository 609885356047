import React, { useState } from "react";
import {Box, TableContainer, Table, TableHead, TableBody, Tabs, Tab, TableRow, TableCell, Paper, Typography, Grid, ToggleButtonGroup, ToggleButton} from '@mui/material';
import { CheckinTableRow } from "./CheckinTableRow";
import { PlayerAccountTableHeaderCell } from "../PlayerAccounts/PlayerAccountTableComponents/PlayerAccountTableHeaderCell";
import { ETERNAL_GREEN } from "../../../../globalConsts.ts";

export const CheckinTable = ({checkinDay, dateKey, fetchCheckins}) => {

    const [sortBy, setSortBy] = useState('checkinDate');
    const [reversed, setReversed] = useState(true);
    const [tab, setTab] = useState('added');
    const [blocks, setBlocks] = useState([]);

    const headerClick = (sort) => {
        if(sortBy === sort)
            setReversed(!reversed);
        else {
            setSortBy(sort)
            if(reversed) setReversed(!reversed);
        }
    }

    const sortedList = checkinDay || [];
    sortedList.sort((a, b) => {
        if(!a[sortBy]) return -1
        if(!b[sortBy]) return 1
        return reversed ? a[sortBy] > b[sortBy] ? 1 : -1 : b[sortBy] > a[sortBy] ? 1 : -1
    });

    const sendTeams = tab === 'added' ? sortedList.filter(c => !c.removed) : sortedList.filter(c => c.removed);

    const blockList = [];
    sendTeams.forEach(scrim => {
        if(!blockList.includes(scrim.scrimBlock))
            blockList.push(scrim.scrimBlock);
    });
    
    if(blocks.length === 0 && blockList.length !== 0)
        setBlocks(blockList);

    const filteredByBlockTeams = sendTeams.filter(c => blocks.includes(c.scrimBlock));

    return (
        <TableContainer component={Paper} elevation={5}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography fontSize={25} textAlign='center'>{dateKey}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Tabs value={tab} variant="scrollable" scrollButtons={false} 
                        onChange ={(event, value) => setTab(value)} textColor='inherit'
                        TabIndicatorProps={{style: {backgroundColor: ETERNAL_GREEN, height: 3}}}
                    >
                        <Tab label="Checked In" value="added"/>
                        <Tab label="Checked Out" value="removed"/>
                    </Tabs>
                </Grid>
                <Grid item xs={8}>
                    <ToggleButtonGroup
                        color='eternal'
                        size='small'
                        value={blocks}
                        onChange={(event, value) => setBlocks(value)}
                    >
                        {blockList.map(block => <ToggleButton value={block}>{block}</ToggleButton>)}
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Box sx={{height: '67vh', overflowY: 'scroll'}}>
                <Table stickyHeader >
                    <TableHead>
                        <TableRow key={"header"}> 
                            <PlayerAccountTableHeaderCell title='Team Name' onClick={() => headerClick('teamname')} label='teamname' sortBy={sortBy} reversed={reversed} cellKey={'teamnameHeader'} />
                            <PlayerAccountTableHeaderCell title='MMR' onClick={() => headerClick('mmr')} label='mmr' sortBy={sortBy} reversed={reversed} cellKey={'mmrHeader'} />
                            <PlayerAccountTableHeaderCell title='Date' onClick={() => headerClick('checkinDate')} label='checkinDate' sortBy={sortBy} reversed={reversed} cellKey={'dateHeader'} />
                            <TableCell>{tab === 'added' ? 'Remove' : 'Add Back'}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            filteredByBlockTeams && filteredByBlockTeams.map(checkin => <CheckinTableRow checkin={checkin} fetchCheckins={fetchCheckins} key={checkin.id}/>)
                        }
                    </TableBody>
                </Table>
            </Box>
        </TableContainer>
    )
}