import React, { useState, useContext } from "react";
import {TableRow, TableCell, TableContainer, Table, TableHead, TableBody, Paper} from "@mui/material";
import { StarFill, Trash3Fill, XOctagonFill, PersonPlus } from "react-bootstrap-icons";
import { PlayerSnackBar } from "../../Confirmations/PlayerSnackbar";
import { TeamSnackbar } from "../../Confirmations/TeamSnackbar";
import { InviteSnackBar } from "../../Confirmations/InviteSnackbar";
import { MobileContext } from "../../../../AppFunctional";
import { PersonOff } from "@mui/icons-material";
import { ETERNAL_GREEN } from "../../../globalConsts.ts";

export const TeammatesTable = ({team, players, isCaptain, discordID, fetchTeam, setShowPlayerModal, sentInvites, onPrivate, listOfPlayers}) => {

    const [player, setPlayer] = useState("");
    const [playerOpen, setPlayerOpen] = useState(false);

    const [invite, setInvite] = useState(null)
    const [inviteOpen, setInviteOpen] = useState(false);

    const [, setTeam] = useState("");
    const [teamOpen, setTeamOpen] = useState(false);

    const isMobile = useContext(MobileContext);

    let width = 500;
    if(isMobile) width = 'auto';

    const rows = players.map(teammate => {
        return(
            <TableRow >
                <TableCell className="select" onClick={() => window.location.href = "/player/"+teammate.discordName} sx={{ fontSize: 20}}>
                    {
                        (teammate.discordID === team.team.captain && (!isCaptain || !onPrivate)) ? 
                            <><StarFill size={25}/> <span>{teammate.discordName}</span></> 
                        :
                            <span>{teammate.discordName}</span>
                    }
                </TableCell>
                <TableCell sx={{ fontSize: 20}}>
                    { 
                        (() => {
                            if((isCaptain || teammate.discordID === discordID) && teammate.discordID !== team.team.captain && onPrivate){
                                return (<PersonOff size={25} className="select" onClick={() => {
                                    setPlayer(teammate);
                                    setPlayerOpen(true);
                                }}/>);
                            } else if (teammate.discordID === team.team.captain && isCaptain && onPrivate){
                                return (<XOctagonFill size={25} className="select" onClick={() => {
                                    setTeam(team);
                                    setTeamOpen(true);
                                }}/>);
                            } else {
                                return ""
                            }
                        })()
                    }
                </TableCell>
            </TableRow>
        );
    });

    if(onPrivate){
        let inviteRows = [];
        if(sentInvites && sentInvites.length > 0){
            inviteRows = sentInvites.map(invite => {
                const recieverName = listOfPlayers.find(player => player[0] === invite.reciever);
                return (
                    <TableRow>
                        <TableCell sx={{fontStyle: "italic"}} className="select" onClick={() => window.location.href = "/player/"+recieverName[1]}>
                            <span style={{color: ETERNAL_GREEN}}>Pending: </span> {recieverName[1]}
                        </TableCell>
                        <TableCell>
                            { 
                                (() => {
                                    if(!onPrivate)
                                        return ""
                                    if(isCaptain)
                                        return <Trash3Fill size={25} className="select" onClick={() => {
                                            setInvite(invite)
                                            setInviteOpen(true);
                                        }}/>
                                    return ""
                                })()
                            }
                        </TableCell>
                    </TableRow>
                );
            })
        }
    
        rows.push(...inviteRows);
        
        if(players.length + sentInvites.length <= 4 && isCaptain){
            rows.push(
                <TableRow>
                    <TableCell colSpan={2}>
                        <PersonPlus size={25} className="select" onClick={() => setShowPlayerModal(true)}/>
                    </TableCell>
                </TableRow>
            )
        }
    }

    
    return (
        <TableContainer className="ms-2" component={Paper} elevation={5} sx={{overflowY: "auto", overflowX: "hidden", maxHeight: 270, width: width, color: "white", borderRadius: 5}}>
            <PlayerSnackBar open={playerOpen} player={player} setPlayer={setPlayer} setPlayerOpen={setPlayerOpen} captainID={team.team.captain} fetchTeam={fetchTeam}/>
            <TeamSnackbar open={teamOpen} team={team} setTeam={setTeam} setTeamOpen={setTeamOpen} captainID={team.team.captain} fetchTeam={fetchTeam}/>
            <InviteSnackBar open={inviteOpen} invite={invite} setInvite={setInvite} setInviteOpen={setInviteOpen} listOfPlayers={listOfPlayers}/>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} sx={{ fontSize: 45, fontFamily: "Orbitron"}}>
                        <div style={{margin: "10px"}}>Teammates:</div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>
    )
}