import React, { FC, useContext } from "react";
import { LegacyTournamentFlow, LegacyTournamentFlowMobile } from "../../../../images/imgIndex";
import { Box } from '@mui/material';
import { MobileContext } from "../../../../AppFunctional";
import { LLBox } from "./LLBox.tsx";

interface Props {
    width?: string | number;
    height?: string | number;
    className?: string;
}

export const LLTourneyFlow: FC<Props> = ({width, height, className}): JSX.Element => {
    width = width || 'auto';
    height = height || 'auto';
    className = className || '';

    const isMobile = useContext(MobileContext);

    return (
        <Box display='flex'>
            {/* <LLBox  */}
                <img alt='TournamentFlow' src={isMobile ? LegacyTournamentFlowMobile : LegacyTournamentFlow} width={width} height={height} className={className} />
            {/* </LLBox> */}
        </Box>
    )
}