import React, { useContext } from "react"
import {Menu, MenuItem} from '@mui/material';
import { currentSeasonContext } from "../BoundPage.tsx";
import { SEASON_NAME_MAP } from "../../../globalConsts.ts";

export const StatCardSeasonDropdown = ({season, setSeason, seasonNames, anchorEl, setAnchorEl, seasonParticipation}) => {

    const currentSeasonName = useContext(currentSeasonContext);

    if(!seasonNames.includes('Lifetime')) {
        seasonNames.unshift(currentSeasonName);
        seasonNames.push('Lifetime');
    }

    const seasonsPlayed = Object.keys(seasonParticipation).filter(k => seasonParticipation[k] === true);

    const disableMap = {}
    seasonNames.forEach(s => {
        disableMap[s] = Boolean(seasonsPlayed.find(k => {
            return s.toLowerCase().includes(k.toLowerCase())
        }));
    });

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
        >
            {
                seasonNames && seasonNames.map(n => {
                    const bool = !disableMap[n] && n !== 'Lifetime';
                    return (<MenuItem key={n} selected={n === 'Lifetime' ? !season : season === n} onClick={() => {
                        n === 'Lifetime' ? 
                        setSeason(null) :
                        setSeason(n); 
                        setAnchorEl(null)
                    }}
                    disabled={bool}
                    >{SEASON_NAME_MAP[n || ''] || n || ''}</MenuItem>)
                })
            }
        </Menu>
    )
}