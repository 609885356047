import React, { FC, useContext } from "react";
import {Dialog, DialogTitle, Box, Paper, Grid, Checkbox, Button, Radio} from "@mui/material"
import { HuePicker } from 'react-color';
import { TwitchBorder, Twitter, TwitterBorder, Twitch } from "../../../../images/imgIndex.js";
import httpCommon from "../../../../http-common.js";
import { colorContext } from "../BoundPage.tsx";
import { playerIDContext } from "../PlayerPageFunctional.tsx";
import { MobileContext } from "../../../../AppFunctional.js";

interface Props {
    statMap: Map<string, string | number | string[]>;
    settingsModal: [boolean, Function];
    displayName: [string, Function];
    connectionSettings: [any, Function];
    connectionList: any;
}

export const SettingsModal: FC<Props> = ({ statMap, settingsModal, displayName, connectionSettings, connectionList}) => {

    const discordID = useContext<number | string>(playerIDContext);
    const [color, setColor] = useContext<[string, Function]>(colorContext);
    const isMobile = useContext<boolean>(MobileContext);

    const [showSettingsModal, setShowSettingsModal] = settingsModal;
    const [shownDisplayedName, setDisplayName] = displayName;
    const [shownConnectionSettings, setConnectionSettings] = connectionSettings;


    if(!showSettingsModal)
        return "";

    const listOfNames = statMap.get("playerNames") as string[];

    return( 
        <Dialog 
            open={showSettingsModal} 
            onClose={() => setShowSettingsModal(false)}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle className="m-0" sx={{fontSize: "200%"}}>
                Settings
            </DialogTitle>
            <Box 
                className="m-3"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Paper elevation={5} sx={{width: "90%", marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "column"}}>
                    <Grid container spacing={4} className="m-2">
                        <Grid item xs={isMobile ? 12 : 3}>
                            <div className="mb-2">Profile Color:</div>
                            <HuePicker
                                width={isMobile ? '50vw' : 150}
                                color={ color }
                                onChangeComplete={(res) => setColor(res.hex)}
                                className="m-2 p-2"
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <div className="mb-2">Display Name:</div>
                            <Box width={isMobile ? '50vw' : 'auto'} className='m-2 p-2' component={Paper} elevation={2}>
                                <Grid container spacing={0} className="m-2 p-2">
                                    {
                                        listOfNames.map((playerName: string) => {
                                            return (
                                                <Grid item xs={12} className="me-2">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={8} sx={{padding: 0.1, textWrap: "nowrap", overflow: "hidden"}}>
                                                            {playerName} 
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            {/* @ts-ignore */}
                                                            <Radio color="eternal" checked={shownDisplayedName === playerName} onClick={() => setDisplayName(playerName)}/>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 3}>
                            <div className="mb-2">Social Media:</div>
                            <Box width={isMobile ? '50vw' : 'auto'} className='m-2 p-2' component={Paper} elevation={2} display='flex' flexDirection='row'>
                                <Checkbox 
                                    hidden={!connectionList.twitter} 
                                    checked={shownConnectionSettings.twitter} 
                                    onClick={() => setConnectionSettings({"twitter": !shownConnectionSettings.twitter, "twitch": shownConnectionSettings.twitch})} 
                                    icon={<img src={TwitterBorder} width={25} alt='twitter'/>} 
                                    checkedIcon={<img src={Twitter} width={25} alt='twitter'/>}
                                />
                                <Checkbox 
                                    hidden={!connectionList.twitch} 
                                    checked={shownConnectionSettings.twitch} 
                                    onClick={() => setConnectionSettings({"twitter": shownConnectionSettings.twitter, "twitch": !shownConnectionSettings.twitch})} 
                                    icon={<img src={TwitchBorder} width={25} alt='twitch'/>} 
                                    checkedIcon={<img src={Twitch} width={25} alt='twitch'/>} 
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    {/* @ts-ignore */}
                    <Button size="large" className="mb-2 ms-auto me-2" sx={{width: "150px"}} variant="outlined" color="eternal" onClick={async () => {
                        setShowSettingsModal(false);
                        const customJSON = {color, displayName, connectionSettings};
                        await httpCommon.post("/eternalAccounts/profile", {discordID, customJSON}).catch(console.error);
                    }}>Save</Button>
                </Paper> 
            </Box>
        </Dialog>
    )
    
}