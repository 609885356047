import React, { useEffect, useState, useCallback } from "react";
import {TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TextField, Grid} from "@mui/material"
import http from "../../../http-common"
import { timeConverter, mapName, useForceRerender } from "../../pubFuncs";
import { DownArrow } from "../../../images/imgIndex";
import { Loading } from "../MiscComponents/Loading";
import {TimeoutButton} from "../Buttons/TimeoutButton";
import { useNavigate } from "react-router";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";

export const MatchesTable  = ({type, isBinding, setBindMatchID}) => {
    const [matches, setMatches] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useState("");
    const forceRender = useForceRerender();
    const navigate = useNavigate();
    const copy = useCopyToClipboard();

    const fetchData = useCallback(async () => {
        const {data: pulledMatches} = await http.get('/matches/category?category='+type);
        setMatches(pulledMatches);
        setLoading(false);
    }, [type]);

    const fetchSearchData = useCallback(async () => {
        const {data: pulledMatches} = await http.get('/matches/filtered?category='+type+'&filter='+searchParams);
        setMatches(pulledMatches);
        setLoading(false);
    }, [type, searchParams]);

    useEffect(() => {
        if(searchParams === "")
            fetchData().catch(console.error);
        else 
            fetchSearchData().catch(console.error);
    }, [type, fetchData, fetchSearchData, searchParams]);

    // is loading
    if(loading){
        return(
            <Loading/>
        )
    }


    const rowOnClick = (key, index) => {
        try {
            const element = document.getElementById(index);
            if(element.classList.contains('button-flip'))
                element.className = 'button-unflip';
            else
                element.classList = 'button-flip';
        } catch(err){
            console.log(err);
        }

        const currentExpand = expandedRows;

        if(currentExpand[key] === undefined || !currentExpand[key]){
            currentExpand[key] = true;
        }else{
            currentExpand[key] = false;
        }
        setExpandedRows(currentExpand);
        forceRender();
    }

    // map days
    const match_days = {};
    if(matches){
        matches.forEach((match) => {
            const date = timeConverter(match.match_start);
            const date_slice = date.split(" ");
            const map_key = date_slice[0] + " " + date_slice[1] + " " + date_slice[2];
            if(match_days[map_key] === undefined){
                match_days[map_key] = [];
            }
            match_days[map_key].push(match);
        });
    }

    const keys = Object.keys(match_days);

    return(
        <>
            <Grid container spacing={3} className="me-5">
                <Grid item xs={9}>
                    <TextField size="small" onChange={(event) => setSearchParams(event.target.value)} fullWidth onKeyDown={(e) => {
                        if(e.key === 'Enter')
                            if(searchParams === "")
                                fetchData()
                            else 
                                fetchSearchData()
                    }}/>
                </Grid>
                <Grid item xs={2}>
                    <TimeoutButton color="eternal" onClick={fetchSearchData}>Search</TimeoutButton>
                </Grid>
            </Grid>
            <TableContainer component={Paper} elevation={5} sx={{overflowY: "auto", overflowX: "hidden",  height: isBinding ? '55vh' : "65vh"}}>
                <Table className="mb-0 select" stickyHeader size="small">
                    <TableHead>
                        <TableRow key={"rowh"}>
                            <TableCell key={"button"} style={{width:"15%"}}></TableCell>
                            <TableCell key={"dateh"} style={{width: "50%", textAlign: "center"}}>Date</TableCell>
                            <TableCell hey={"numh"} style={{width: "50%", textAlign: "center"}}># of Matches</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            keys.map((key, index) => {
                                const matchesDays = [];
                                const icon = DownArrow;
                                if(expandedRows[key] === true){
                                    const nowMatches = match_days[key];
                                    nowMatches.forEach((match, index2) => {
                                        const date = timeConverter(match["match_start"]);
                                        const map_name = mapName(match["map_name"]) || match["map_name"];
                                        const mid = match["mid"];
                                        const indexSend = (index * matches.length) + index2;
                                        const matchKey = date + " " +  map_name + " " + indexSend;
                                        matchesDays.push(
                                            <TableRow className = "bordered border-success" key = {"matches row: " + matchKey} onClick={() =>  {
                                                isBinding ? setBindMatchID(mid)
                                                :
                                                navigate('../matches/id/'+mid);
                                            }}
                                            onMouseDown={(event) => {
                                                if(event.button === 1){
                                                    window.open('../matches/id/'+mid, '_blank');
                                                } else if(event.button === 2) {
                                                    copy(mid);
                                                }
                                            }}
                                            >
                                                <TableCell/>
                                                <TableCell style={{textAlign: "center"}} key={"date: " + matchKey}>{date}</TableCell>
                                                <TableCell style={{textAlign: "center"}} key = {"map: " + matchKey}>{map_name}</TableCell>
                                            </TableRow>
                                        );
                                    });
                                }
                                const ret = [];
                                ret.push(
                                    <TableRow key ={"row: " + index} onClick={() => rowOnClick(key, index)}>
                                        <TableCell key={"button:" + index } style={{ width: "15%"}}>
                                            <img className = "button-unflip" id={index} src={icon} alt="" style={{filter: "invert(100%)", width:"50%"}}/>
                                        </TableCell>
                                        <TableCell key={"date:" + index} style={{textAlign: "center"}}>{key}</TableCell>
                                        <TableCell key = {"map: " + index} style={{textAlign: "center"}}>{match_days[key].length}</TableCell>
                                    </TableRow>
                                );
                                if(matchesDays.length > 0)
                                    ret.push(
                                        <TableRow>
                                            <TableCell colSpan={3}>
                                                <TableContainer component={Paper} elevation={5}>
                                                    <Table>
                                                        <TableBody>
                                                            {matchesDays}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </TableCell>
                                        </TableRow>
                                    );
                                return ret;
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}