import React, { useState } from "react";
import { Check } from '@mui/icons-material';
import { TimeoutButton } from "../Buttons/TimeoutButton";
import httpCommon from "../../../http-common";
import { ConfirmationDialog } from "../Confirmations/ConfirmationSnackbar";

export const NotificationAcceptButton = ({invite, fetchInvites, closeSnackbar}) => {

    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [team, setTeam] = useState(null);
    const [isOnTeam, setIsOnTeam] = useState(false);
    const discordID = invite.reciever;

    const accept = async () => {
        try{
            const {data: foundTeam} = await httpCommon.get('/teams/discordID?discordID='+discordID);
            setTeam(foundTeam.team);
            setMessage(foundTeam.team.captain === discordID ? 
                `You are the CAPTAIN of team ${foundTeam.team.teamname}. Are you sure you want to DELETE that team to join ${invite.teamname}`:
                `You are a PLAYER of team ${foundTeam.team.teamname}. Are you sure you want to LEAVE that team to join ${invite.teamname}`
            );
        } catch(err){
            setIsOnTeam(false);
            setShow(false);
            return await doubleAccept();
        }
        setShow(true);
    }

    const doubleAccept = async () => {
        console.log(isOnTeam);
        try {
            if(isOnTeam) await httpCommon.post('/teams/update', {captain: team.captain, player: discordID, action: 'remove'});
            await httpCommon.post('/teams/acceptinvite', {id: invite.id});
        } catch(err) {
            console.log(err);
        }
        fetchInvites().catch(console.error);
        closeSnackbar(invite.id);
    }

    return (
        <>
            <ConfirmationDialog open={show} message={message} accept={doubleAccept} deny={() => setShow(false)}/>
            <TimeoutButton color="eternal" size="small" onClick={accept}>
                <Check />
            </TimeoutButton>
        </>
    )
}
