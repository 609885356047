import React from "react";
import { DownChevron, UpChevron } from "../../../../../../images/imgIndex";
import {TableCell} from '@mui/material';

export const PlayerAccountTableHeaderCell = (props) => {
    const { onClick, title, sortBy, reversed, label, cellKey } = props;

    return (
        <TableCell onClick={() => onClick()} key={cellKey}>
            {title}
            <img src={reversed ? UpChevron : DownChevron} style={{display: sortBy === label ? '' : 'none', width: '25px', height: '25px', filter: 'invert(180)'}} alt='chevron' />
        </TableCell>
    )
}