import React, { FC, useContext } from "react";
import { Typography, Box } from '@mui/material';
import { ETERNAL_GREEN, ETERNAL_PINK, ETERNAL_YELLOW } from "../../../globalConsts.ts";
import { MobileContext } from "../../../../AppFunctional.js";
import { CountDown } from "./CountDown.tsx";

export const LLTitle: FC<{}> = () : JSX.Element => {
    const isMobile = useContext(MobileContext);

    // 
    return (
        <Box>
            <Typography fontSize={isMobile ? 35 : 65} color='white'>
                eternal
                <Typography component='span' color={ETERNAL_GREEN}>. </Typography> 
                legacy 
                <Typography component='span' color={ETERNAL_PINK}>. </Typography> 
                league
                <Typography component='span' color={ETERNAL_YELLOW}>. </Typography> 
            </Typography>
            <CountDown time={1719442800}/>
        </Box>
    )
}