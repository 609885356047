import React, { useContext, useState } from "react";
import { MobileContext } from "../../../AppFunctional";
import {Card, CardContent, Grid, Tabs, Tab} from '@mui/material'
import { EternalFAQs } from "./FAQPages/EternalFAQs";
import { ScrimFAQs } from "./FAQPages/ScrimFAQs";
import { SoloQFAQs } from "./FAQPages/SoloQFAQs";
import { MMRFAQs } from "./FAQPages/MMRFAQs";
import { useNavigate } from "react-router";
import { ETERNAL_GREEN } from "../../globalConsts.ts";

export const FAQPage = () => {
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);

    const isMobile = useContext(MobileContext);
    const [tab, setTab] = useState(params.get('tab') || "eternal");
    const width = isMobile ? "100%" : "40%";

    const tabOnClick = (tab) => {
        setTab(tab);
        params.set('tab', tab);
        navigate('/faq?'+params, { replace: true });
    }


    return (
        <div className="page">
            <div className="other-page"/>
            <Grid container spacing={0}>
                <Grid item xs={12} className="glow" style={{fontSize: '3em', fontFamily: "Orbitron", textAlign: "center"}}>FAQs</Grid>
                <Grid item xs={12}>
                    <Card style={{width, margin: "auto"}}>
                        <CardContent>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Tabs value={tab} variant="scrollable" scrollButtons={true} allowScrollButtonsMobile onChange ={(event, value) => tabOnClick(value)} textColor='eternal' TabIndicatorProps={{style: {
                                        backgroundColor: ETERNAL_GREEN, height: 3
                                    }}}>
                                        <Tab label="Eternal FAQs" value='eternal' color='eternal'/>
                                        <Tab label="Scrims FAQs" value="scrims" color='eternal'/>
                                        <Tab label="Solo Queue FAQs" value="soloq" color='eternal'/>
                                        <Tab label="MMR FAQs" value="mmr" color='eternal'/>
                                    </Tabs>
                                </Grid>
                                <Grid item xs={12}>
                                    {tab === "eternal" && <EternalFAQs />}
                                    {tab === "scrims" && <ScrimFAQs />}
                                    {tab === "soloq" && <SoloQFAQs />}
                                    {tab === "mmr" && <MMRFAQs />}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card> 
                </Grid>
            </Grid>
        </div>
    )
}