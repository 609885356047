import React, { useState } from "react";
import { Grid, FormControl, TextField, InputLabel, Select, MenuItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ConfirmationButton } from "../../../Buttons/ConfirmationButton";
import httpCommon from "../../../../../http-common";
import { ETERNAL_GREEN } from "../../../../globalConsts.ts";

export const TokenAdd = ({fetchTokens}) => {
    
    const [tokenText, setTokenText] = useState("");
    const [category, setCategory] = useState('scrim');
    const [buttons, setButtons] = useState(['active', 'autopoll']);

    const submitToken = async () => {
        try {
            await httpCommon.post("/statTokens/", {
                statTokenText: tokenText, 
                statTokenType: category, 
                statTokenActive: buttons.includes('active'), 
                statTokenAutopoll: buttons.includes('autopoll')
            });
            fetchTokens();
        } catch(error){
            console.log(error);
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField color='eternal' label='Stat Token' variant='outlined' fullWidth className="m-2" 
                    value={tokenText} onChange={(event) => setTokenText(event.target.value)}
                />
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth className="m-2">
                    <InputLabel id='category-label' color='eternal'>Category</InputLabel>
                    <Select
                        variant='outlined'
                        labelId='category-label'
                        label='Category'
                        color='eternal'
                        value={category}
                        onChange={(event) => setCategory(event.target.value)}
                    >
                        <MenuItem value='scrim'>Scrim</MenuItem>
                        <MenuItem value='soloq'>Solo Queue</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <ToggleButtonGroup className="m-2" size='large' color='eternal'
                    value={buttons}
                    onChange={(event, value) => setButtons(value)}
                >
                    <ToggleButton value='active'>Active</ToggleButton>
                    <ToggleButton value='autopoll'>Autopoll</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12}>
                <ConfirmationButton text='Submit' color='eternal' variant='outlined' className="m-2" size="large" onClick={() => submitToken()}>
                    Are you sure you want to add <br/><span style={{color: ETERNAL_GREEN}}>{tokenText}</span><br/>as a stat token?<br/><br/>
                    It will by default be <span style={{color: ETERNAL_GREEN}}>{buttons.includes('active') ? 'active ' : 'not active '}</span> 
                    and <span style={{color: ETERNAL_GREEN}}>{buttons.includes('autopoll') ? 'autopolling' : 'not autopolling'}</span>
                </ConfirmationButton>
            </Grid>
        </Grid>
    )

}