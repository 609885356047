import React from "react";
import {Button, Dialog, DialogTitle, DialogActions } from "@mui/material"
import { RandomNoGen, RandomYesGen } from "../../randomYesNoGen";
import { TimeoutButton } from "../Buttons/TimeoutButton";

export const ConfirmationDialog = ({open, message, accept, deny}) => {

    const yes = RandomYesGen();
    const no = RandomNoGen();

    return (
        <Dialog
            open={open}
            onClose={() => deny()}
        >
            <DialogTitle sx={{fontFamily: ""}}>
                {message}
            </DialogTitle>
            <DialogActions>
                <TimeoutButton size="small" className="m-1" onClick={() => accept()}>{yes}</TimeoutButton>
                <Button color="error" size="small" variant="contained" className="m-1" onClick={() => deny()}>{no}</Button>
            </DialogActions>
        </Dialog>
            
    )
}