import React, { useContext } from "react";
import {TableRow, TableCell} from '@mui/material';
import { setSelectedTicketContext } from "./HelpInbox";
import { adminPanelPlayers } from "../../AdminPanelFunctional";

export const HelpTableRow = ({letter, listOfPlayers}) => {
    const id = letter.ticket.id;
    const status = letter.ticket.status;

    let gotListOfPlayers = useContext(adminPanelPlayers);
    if(!gotListOfPlayers) gotListOfPlayers = listOfPlayers

    const foundSubmitter = gotListOfPlayers.find(p => p[0] === letter.ticket.discordID);
    const submitter = Boolean(foundSubmitter) ? foundSubmitter[1] : letter.ticket.discordID;

    const foundAssignee = gotListOfPlayers.find(p => p[0] === letter.ticket.assignee);
    const assignee = Boolean(foundAssignee) ? foundAssignee[1] : Boolean(letter.ticket.assignee) ? letter.ticket.assignee : 'unassigned';

    const setSelectedTicket = useContext(setSelectedTicketContext);
    return (
        <TableRow onClick={() => setSelectedTicket(letter)}>
            <TableCell>{id}</TableCell>
            <TableCell>{submitter}</TableCell>
            <TableCell>{status}</TableCell>
            <TableCell>{assignee}</TableCell>
        </TableRow>
    )
}