import React, {useState} from "react";

import {Avatar, AppBar, Toolbar, IconButton, Box, Typography, Button, Badge, Menu} from '@mui/material'
import { DiscordLogo, AnimatedLogo } from "../../images/imgIndex";
import { HeaderItem } from "../../common/components/Header/HeaderItem";
import { HeaderMenu } from "../../common/components/Header/HeaderMenu";
import { HeaderMenuItem } from "../../common/components/Header/HeaderMenuItem";
import { ETERNAL_GREEN } from "../../common/globalConsts.ts";

export const HeaderDesktop = ({username, bound, loading, loggedIn, invites, permLevel, avatarImage, checkinOpen, showNotifications, toggleNotifications, numOpenTickets}) => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorBurger, setAnchorBurger] = useState(null);
    const [anchorHelp, setAnchorHelp] = useState(null);

    let redirectLink = "https://discord.com/api/oauth2/authorize?client_id=1205950724811522129&response_type=token&redirect_uri=https%3A%2F%2Feternalesports.club&scope=identify+email+connections+guilds.members.read+guilds";
    if(window.location.origin.includes("b3140286"))
        redirectLink = "https://discord.com/api/oauth2/authorize?client_id=1205950724811522129&response_type=token&redirect_uri=https%3A%2F%2Fb3140286.eternalesports.club&scope=identify+email+connections+guilds.members.read+guilds";
    else if(window.location.origin.includes("73d9c3fb"))
        redirectLink = "https://discord.com/api/oauth2/authorize?client_id=1205950724811522129&response_type=token&redirect_uri=https%3A%2F%2F73d9c3fb.eternalesports.club&scope=identify+email+connections+guilds.members.read+guilds";

    return (
        <AppBar component="nav" position="static" sx={{ background: 'transparent', boxShadow: 'none', mt: 1}} enableColorOnDark>
        <Toolbar style={{fontSize: '2.2em', fontFamily: "Orbitron", textAlign: "center"}}>
            {/* animated logo */}
            <Box sx={{mr: 4}} className='select'>
                <Box 
                    component='img'
                    className = "logo logo-glow" 
                    src={AnimatedLogo} 
                    alt="Home Page" 
                    sx={{
                        height: 75,
                        width: 75,
                    }}
                    onClick={(event) => {
                        setAnchorBurger(event.currentTarget);
                    }} 
                />  
                <Menu
                    elevation={5}
                    slotProps={{paper: {sx: {borderRadius: 5,padding: 0}}}}
                    anchorEl={anchorBurger}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    keepMounted
                    transformOrigin={{vertical: 'top',horizontal: 'right'}}
                    open={Boolean(anchorBurger)}
                    onClose={() => setAnchorBurger(null)}
                    
                >
                        {/* nav items */}
                    <HeaderMenuItem text="Home" to="/" setAnchorElNav={setAnchorElNav}/>
                    <HeaderMenuItem text="Matches" to="/matches" setAnchorElNav={setAnchorElNav}/>
                    <HeaderMenuItem text="Supporters" to="/supporters" setAnchorElNav={setAnchorElNav}/>
                </Menu>
            </Box>

            {/* other headers */}
            <HeaderItem text="Leaderboard" to="/leaderboard" />
            {/* <HeaderItem text="Store" to="https://raven.gg/stores/eternal-esports-club/" /> */}
            <HeaderItem text='VIP' to='/vip' show={permLevel >= 2}/>
            <Box sx={{mr: 4}} className='select'>
                <Box sx={{mr: 4}} onClick={(event) => setAnchorHelp(event.currentTarget)}>
                    <Typography sx={({ isActive }) => {
                        return {
                            color: (isActive) ? ETERNAL_GREEN : "grey",
                            textDecoration: "none",
                            textShadow: (isActive) ? "0px 0px 25px " + {ETERNAL_GREEN} : "none",
                            "&:hover": { color: ETERNAL_GREEN }
                        }
                    }}>Help</Typography>
                </Box> 
                <Menu
                    elevation={5}
                    slotProps={{paper: {sx: {borderRadius: 5,padding: 0}}}}
                    anchorEl={anchorHelp}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    keepMounted
                    transformOrigin={{vertical: 'top',horizontal: 'right'}}
                    open={Boolean(anchorHelp)}
                    onClose={() => setAnchorHelp(null)}
                    
                >
                    {/* nav items */}
                    <HeaderMenuItem text="Contact Support" to="/help" setAnchorElNav={setAnchorHelp}/>
                    <HeaderMenuItem text="FAQs" to="/faq" setAnchorElNav={setAnchorHelp}/>
                </Menu>
            </Box>
            <HeaderItem text="Bind" to={"/player/"+username} show={!bound && !loading ? "" : "none"} alwaysHighlight={true} />
            
            {/* sign in button */}
            <Box sx={{ml: 'auto', display: loggedIn ? 'none': ''}}>
                <Button 
                    endIcon={
                        <Box 
                            component='img'
                            src={DiscordLogo} 
                            sx={{filter: "invert(100)", width: "45px"}}
                        />  
                    }
                    className="select"
                    sx={{backgroundColor: "grey", "&:hover": {backgroundColor: "#00e682"}}}
                    variant="contained"
                    onClick={() => window.location.href = redirectLink}
                >
                    <Typography fontSize='2em' color="white">
                        Sign In
                    </Typography>
                </Button>
            </Box>
            
            {/* profile */}
            <Box sx={{ml: 'auto', display: loggedIn ? '': 'none'}}>
                <IconButton 
                    className="logo logo-glow" 
                    sx={{ width: 75, height: 75 }}
                    onClick={(event) => {
                        setAnchorElNav(event.currentTarget);
                    }}     
                >   
                    <Badge badgeContent={permLevel > 2 ? numOpenTickets : 0} color='eternal' archorOrigin={{vertical: 'top', horizontal: 'left'}} overlap="circular">
                        <Badge badgeContent={Boolean(anchorElNav) ? 0 : invites.length} color="error" anchorOrigin={{vertical: "top", horizontal: 'right'}} overlap="circular">
                            <Avatar 
                                src={avatarImage} 
                                alt="Player Page"
                                sx={{ width: 75, height: 75 }} 
                            />  
                        </Badge>
                    </Badge>
                </IconButton>
                <HeaderMenu 
                    anchorElNav={anchorElNav} setAnchorElNav={setAnchorElNav} 
                    invites={invites} username={username}
                    bound={bound} loading={loading}
                    loggedIn={loggedIn} permLevel={permLevel}
                    showNotifications={showNotifications} toggleNotifications={toggleNotifications}
                    numOpenTickets={numOpenTickets}
                />
            </Box>
        </Toolbar>
    </AppBar>
    )
}