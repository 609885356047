import { 
    AshIcon, BallisticIcon, BangaloreIcon, BloodhoundIcon, CatalystIcon, CausticIcon, ConduitIcon, CryptoIcon, FuseIcon, GibraltarIcon,
    HorizonIcon, LifelineIcon, LobaIcon, MadMaggieIcon, MirageIcon, NewcastleIcon, OctaneIcon, PathfinderIcon, RampartIcon, RevenantIcon,
    SeerIcon, ValkyrieIcon, VantageIcon, WattsonIcon, WraithIcon,
    TierS, TierG, TierA, TierB, TierC, TierD, TierE, TierF, Top1, Top20,
    AlterIcon
} from "../images/imgIndex";
import React from "react";

export const timeConverter = (unixTimestamp) =>{
    const a = new Date(unixTimestamp * 1000);
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const hour = (a.getHours() > 12 ? a.getHours() - 12 : a.getHours() === 0 ? 12 : a.getHours());
    const min = (a.getMinutes() < 10 ? '0' : '' ) + a.getMinutes();
    return month + ' ' + date + ' ' + year + ' ' + hour + ':' + min + (a.getHours() > 12 ? ' PM' : ' AM');
}

export const survivalTimerConverter = (time) => {
    const minutes = parseInt(time / 60);
    let seconds = parseInt(time % 60);
    if(seconds < 10){
        seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
    
}

export const mapName = (name) => {
    const map_map = {
        "mp_rr_canyonlands_hu": "Kings Canyon (Season 14)",
        "mp_rr_tropic_island_mu1": "Storm Point (Season 13)",
        "mp_rr_tropic_island_mu2": "Storm Point (Season 19)",
        "mp_rr_tropic_island_mu1_storm": "Storm Point (Season 18)",
        "mp_rr_desertlands_mu3": "Worlds Edge (Season 10)",
        "mp_rr_desertlands_mu4": "Worlds Edge (Season 16)",
        "mp_rr_olympus_mu2": "Olympus (Season 12)",
        "mp_rr_divided_moon": "Broken Moon (Season 15)",
        "mp_rr_desertlands_hu": "Worlds Edge (Season 17)"
    }
    return map_map[name] || "";
}

export function useForceRerender() {
    const [, setState] = React.useState({value: 0});

    function rerenderForcefully() {
        setState((prev) => {
            return {...prev}
        });
    }
    return rerenderForcefully;
}

export const buildCategories = (matchStart) => {
    const date = new Date(matchStart * 1000);
    let formattedDate = date.toLocaleDateString('en-US', {day: "2-digit", month: "numeric", year: "2-digit"});
    formattedDate += " " + date.toLocaleTimeString('en-US', {minute: "2-digit", hour12: true, hour: "2-digit"})
    return formattedDate;
}

export const mmrToRankIMG = (currentMMR, lbPosition) => {
    let tierIMG = TierF
    if(lbPosition === 0){
        tierIMG = Top1;
    } else if(lbPosition <= 19 && lbPosition >= 1){
        tierIMG = Top20;
    } else if(currentMMR >= 1800){
        tierIMG = TierS;
    } else if(currentMMR >= 1600){
        tierIMG = TierG;
    } else if(currentMMR >= 1400){
        tierIMG = TierA;
    } else if(currentMMR >= 1200){
        tierIMG = TierB;
    } else if(currentMMR >= 1000){
        tierIMG = TierC;
    } else if(currentMMR >= 800){
        tierIMG = TierD;
    } else if(currentMMR >= 600){
        tierIMG = TierE;
    }

    return tierIMG;
}

export const characterIconSelect = (characterName) => {
    const character_map = {
        "alter": AlterIcon,
        "ash": AshIcon,
        "ballistic": BallisticIcon,
        "bangalore": BangaloreIcon,
        "bloodhound": BloodhoundIcon,
        "catalyst": CatalystIcon,
        "caustic": CausticIcon,
        "conduit": ConduitIcon,
        "crypto": CryptoIcon,
        "fuse": FuseIcon,
        "gibraltar": GibraltarIcon,
        "horizon": HorizonIcon,
        "lifeline": LifelineIcon,
        "loba": LobaIcon,
        "madmaggie": MadMaggieIcon,
        "mirage": MirageIcon,
        "newcastle": NewcastleIcon,
        "octane": OctaneIcon,
        "pathfinder": PathfinderIcon,
        "rampart": RampartIcon,
        "revenant": RevenantIcon,
        "seer": SeerIcon,
        "valkyrie": ValkyrieIcon,
        "vantage": VantageIcon,
        "wattson": WattsonIcon,
        "wraith": WraithIcon
    }
    return character_map[characterName];
}