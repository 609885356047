import React from "react";
import {Typography, Grid} from "@mui/material";
import { QNABox } from "./QNABox";
import { ETERNAL_GREEN } from "../../../globalConsts.ts";

export const ScrimFAQs = () => {
    return (
        <>
            <Typography fontFamily="">
                <QNABox question={"1. Are there scrims today?"}>
                    A. If there is no notice about cancelled or rescheduled scrims in <a href="https://discord.com/channels/1031340622692499506/1100710526243328100" style={{color: ETERNAL_GREEN}}>event-announcements</a>, please refer to the <a href="https://discord.com/channels/1031340622692499506/1224789977137938552" style={{color: ETERNAL_GREEN}}>server calendar</a>.
                </QNABox>
                <QNABox question={"2. At what time does everything happen?"}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            7pm Eastern Scrim Block:
                            <ul>
                                <li>Checkin Open: 1pm Eastern</li>
                                <li>Checkin Close: 5pm Eastern</li>
                                <li>Drops Open: 5:30pm Eastern</li>
                                <li>Drop Priority Lock: 6:15pm Eastern</li>
                                <li>Drops/Cons Close: 6:30pm Eastern</li>
                                <li>Code Sent: 6:50pm Eastern</li>
                                <li>Scrim Start: 7pm Eastern</li>
                            </ul>
                        </Grid>
                        <Grid item xs={6}>
                            10:30pm Eastern Scrim Block:
                            <ul>
                                <li>Checkin Open: 1pm Eastern</li>
                                <li>Checkin Close: 8:30pm Eastern</li>
                                <li>Drops Open: 9pm Eastern</li>
                                <li>Drop Priority Lock: 9:45pm Eastern</li>
                                <li>Drops/Cons Close: 10:00pm Eastern</li>
                                <li>Code Sent: 10:20pm Eastern</li>
                                <li>Scrim Start: 10:30pm Eastern</li>
                            </ul>
                        </Grid>
                    </Grid>
                </QNABox>
                <QNABox question={"3. Where is the leaderboard link for lobbies if I can't see the lobby channel anymore?"}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ul>
                                <li><a href="https://overstat.gg/_6JvJE" style={{color: ETERNAL_GREEN}}>Lobby 1</a></li>
                                <li><a href="https://overstat.gg/_GgKtZ" style={{color: ETERNAL_GREEN}}>Lobby 2</a></li>
                                <li><a href="https://overstat.gg/_DYTL8" style={{color: ETERNAL_GREEN}}>Lobby 3</a></li>
                                <li><a href="https://overstat.gg/_C7qFP" style={{color: ETERNAL_GREEN}}>Lobby 4</a></li>
                            </ul>
                        </Grid>
                        <Grid item xs={6}>
                            <ul>
                                <li><a href="https://overstat.gg/_VgVmJ" style={{color: ETERNAL_GREEN}}>Lobby 5</a></li>
                                <li><a href="https://overstat.gg/_thGJY" style={{color: ETERNAL_GREEN}}>Lobby 6</a></li>
                                <li><a href="https://overstat.gg/_SKM81" style={{color: ETERNAL_GREEN}}>Lobby 7</a></li>
                                <li><a href="https://overstat.gg/_MDvir" style={{color: ETERNAL_GREEN}}>Lobby 8</a></li>
                            </ul>
                        </Grid>
                    </Grid>
                </QNABox>
                <QNABox question={"4. Why did I get waitlisted?"}>
                    A. If you were waitlisted, there were either too many teams with higher MMR than you, or you have <span style={{color: ETERNAL_GREEN}}>@Low priority</span>
                </QNABox>
                <QNABox question={"5. Why did I get Low Priority?"}>
                    A. Assigning low priority to a team or player is left up to the jurisdiction of the staff member, but here are some common reasons you may have gotten it:
                    <ul>
                        <li>Failed to respond to pings to move your drop.</li>
                        <li>Failed to follow the scrim rules list above.</li>
                        <li>Failed to show up to scrims after being placed in a lobby.</li>
                        <li>Dropped from scrims after code was released.</li>
                        <li>Dropped from scrims after game has started.</li>
                        <li>Your team's KP to Placement ratio is > 65%</li>
                    </ul>
                </QNABox>
                <QNABox question={"6. How long does Low Priority last?"}>
                    A. <span style={{color: ETERNAL_GREEN}}>@Low priority</span> is generally assigned in lengths starting with one week. Staff can assign longer periods of <span style={{color: ETERNAL_GREEN}}>@Low priority</span> at their discretion. Dropping out of scrims after a group has already started can result in a much longer low priority time.
                </QNABox>
                <QNABox question={"7. Why is my QualityScore so low? How do I improve it?"}>
                    A. If you have a high rate of kills that do no translate to end game positions or survival time after getting said kills, your QScore will decrease. Focus on making it to endgame, not racking up points like you're grinding RP. Forgiveness for contests is included in your QScore.
                </QNABox>
                <QNABox question={"8. What servers are Eternal scrims hosted on?"}>
                    A. Iowa GCE 1-4
                </QNABox>
                <QNABox question={"9. I got waitlisted as a VIP, how does waitlist work for me?"}>
                    A. Due to a massive influx of VIP teams at times, some VIPs unfortunately are forced to be waitlisted. However VIP teams will never be waitlisted for back to back verified scrim blocks.
                </QNABox>
            </Typography>
        </>
    )
}