import React, { useState } from "react";
import {Button, CircularProgress} from "@mui/material"

export const TimeoutButton = (props) => {
    const [loading, setLoading] = useState(false);
    const children = props.children;
    let onClick = props.onClick || (() => console.log("click"));
    let size = props.size || "large";
    let className = props.className || "ms-3 mb-2 p-2"
    let color = props.color || "eternal"
    let disabled = props.disabled || false;
    let variant = props.variant || 'outlined';

    return (
        <Button 
            size={size} className= {className} color={color} variant={variant}
            disabled={loading || disabled} 
            startIcon={loading ? <CircularProgress color={color} size={25}/> : ""}
            onClick={() => {
                setLoading(true);
                setTimeout(() => {
                    onClick();
                    setLoading(false);
                }, 1000)
            }}
        >{children}</Button>
    )

}