import React, { useEffect, useState } from "react";
import httpCommon from "../../../../../http-common";
import { PlayerAccountTable } from "./PlayerAccountTable";
import { Loading } from "../../../MiscComponents/Loading";

export const PlayerAccounts = () => {
    const [fullAccounts, setFullAccounts] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchFullAccounts = async () => {
        const {data: {playerAccounts: fullAccounts}} = await httpCommon.get('/eternalAccounts/fullprofiles');
        setFullAccounts(fullAccounts);
        setLoading(false);
    }

    useEffect(() => {
        fetchFullAccounts().catch(console.error);
    }, []);

    if(loading) return <Loading/>;

    return (
        <PlayerAccountTable fullAccounts={fullAccounts} fetchFullAccounts={fetchFullAccounts}/>
    )
}