import React from "react";
import {Box, Typography} from '@mui/material';
import { NavLink } from "react-router-dom";
import { ETERNAL_GREEN } from "../../globalConsts.ts";

export const HeaderItem = ({text, to, show, alwaysHighlight}) => {
    const showLabel = show || '';
    const highlight = alwaysHighlight || false;
    return (
        <NavLink 
            style={({ isActive }) => {
                return {
                    color: (isActive || highlight) ? ETERNAL_GREEN : "grey",
                    textDecoration: "none",
                    textShadow: (isActive || highlight) ? "0px 0px 35px " + {ETERNAL_GREEN} : "none"
                }
            }} 
            to={to}>
            <Box sx={{mr: 4, display: showLabel}}>
                <Typography sx={{"&:hover": { color: ETERNAL_GREEN }}}>{text}</Typography>
            </Box> 
        </NavLink>
    )
}