import React from "react";

import { MenuItem, Typography, Box, Badge } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { ETERNAL_GREEN } from "../../globalConsts.ts";
import { CustomBadge, HeaderBadge } from "../Badge/CustomBadge.tsx";

export const HeaderMenuItem = ({text, to, setAnchorElNav, hide, alwaysHighlight, permLevel, numOpenTickets}) => {
    const navigate = useNavigate();
    const hideLabel = hide ? 'none' : '';
    permLevel = permLevel || 0;
    numOpenTickets = numOpenTickets || 0;
    if(hide)
        return ""
    
    return (
        <NavLink 
            style={({ isActive }) => {
                return {
                    color: (isActive || alwaysHighlight) ? ETERNAL_GREEN : "grey",
                    textDecoration: "none",
                    textShadow: (isActive || alwaysHighlight) ? "0px 0px 25px #0fff95" : "none"
                }
            }} 
            onClick={() => {
                setAnchorElNav(null);
                navigate(to);
            }}

            to={to}
        >
            <MenuItem>
                    <Box sx={{display: hideLabel}}>
                        <HeaderBadge badgeContent={permLevel > 2 ? numOpenTickets : 0} color='eternal' archorOrigin={{vertical: 'top', horizontal: 'right'}} overlap="circular">
                            <Typography fontSize='2em' sx={{"&:hover": { color: ETERNAL_GREEN }}}>
                                {text}
                            </Typography>
                        </HeaderBadge>    
                    </Box>
            </MenuItem>
        </NavLink>

    )
}