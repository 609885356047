import React from "react";
import { TimeoutButton } from "../../../../Buttons/TimeoutButton";
import {TableCell} from '@mui/material';
import httpCommon from "../../../../../../http-common";

export const UnbindButton = ({account, fetchFullAccounts, isStaff}) => {
    const onClick = async () => {
        try {
            await httpCommon.post('/players/unbind/', {discordID: account.discordID});
            fetchFullAccounts();
        } catch(err){
            console.log(err);
        }
    }

    return (
        <TableCell>
            <TimeoutButton onClick={onClick} disabled={!Boolean(account.nidHash) || isStaff}>Unbind</TimeoutButton>
        </TableCell>
    )

} 