import React, { FC, useContext, useEffect, useState } from "react";
import {Card, CardHeader, CardContent, IconButton} from "@mui/material"
import httpCommon from "../../../../http-common.js";
import { ArrowDropDownCircle } from "@mui/icons-material";
import { StatCardSeasonDropdown } from "./StatCardSeasonDropdown.js";
import { ETERNAL_GREEN, SEASON_NAME_MAP } from "../../../globalConsts.ts";
import { colorContext } from "../BoundPage.tsx";

interface Props {
    statMap: any;
    title?: string | null;
    width?: number | string | null;
    season?: [string | null, Function];
    isTeamPage?: boolean;
    seasonParticipation: any;
}

export const StatsCard: FC<Props> = ({statMap, title, width, season, isTeamPage, seasonParticipation}) => {

    const [statSelect, setStatSelect] = useState<boolean>(true);
    const [seasonNames, setSeaosnNames] = useState<string[]>([]);
    const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
    const [borderColor, ]= useContext<[string, Function]>(colorContext) || ["#00000000", ];
    const [currentSeason, setSeason] = season || ['', (s: string) => console.log('set season ', s)];
    
    const cardTitle = title || "Career";
    const setWidth = width || "auto"
    isTeamPage = isTeamPage || false;

    useEffect(() => {
        const fetchSeasonNames = async () => {
            const {data: seasonNames} = await httpCommon.get('/track_mmr/archivenames');
            setSeaosnNames(seasonNames);
        }

        fetchSeasonNames().catch(console.error);
    }, []);

    return(
        <Card sx={{borderRadius: 5, border: borderColor + " 2px solid", height: "100%", width: setWidth}}  className="select">
            <CardHeader 
                titleTypographyProps={{
                    fontFamily: "Orbitron",
                    fontSize: 30
                }}
                action={
                    isTeamPage ? "" :
                    <IconButton className="mt-1" onClick={(event) => setAnchorEl(event.target)}>
                        <ArrowDropDownCircle />
                    </IconButton>
                }
                title={cardTitle}
                subheader={SEASON_NAME_MAP[currentSeason || ''] || currentSeason || ''}
            />
            <CardContent onClick={() => setStatSelect(!statSelect)}>
                <StatCardSeasonDropdown anchorEl={anchorEl} setAnchorEl={setAnchorEl} seasonNames={seasonNames} season={currentSeason} setSeason={setSeason} seasonParticipation={seasonParticipation} />
                {
                    statSelect ? 
                        (<div className="ms-2">
                            <div className="mb-1" style={{ fontSize: '1em' }}>Games Played: <span style={{color: ETERNAL_GREEN}}>{statMap.get("num_matches")}</span></div>
                            <div className="mb-1" style={{ fontSize: '1em' }}>AVG Kills: <span style={{color: ETERNAL_GREEN}}>{statMap.get("avg_kills")}</span></div>
                            <div className="mb-1" style={{ fontSize: '1em' }}>AVG DMG: <span style={{color: ETERNAL_GREEN}}>{statMap.get("avg_dmg")}</span></div>
                            <div className="mb-1" style={{ fontSize: '1em' }}>AVG Placement: <span style={{color: ETERNAL_GREEN}}>{statMap.get("avg_place")}</span></div>
                        </div>)
                    :
                        (<div className="ms-2">
                            <div className="mb-1" style={{ fontSize: '1em' }}>Games Played: <span style={{color: ETERNAL_GREEN}}>{statMap.get("num_matches")}</span></div>
                            <div className="mb-1" style={{ fontSize: '1em' }}>Total Kills: <span style={{color: ETERNAL_GREEN}}>{statMap.get("total_kills")}</span></div>
                            <div className="mb-1" style={{ fontSize: '1em' }}>Total DMG: <span style={{color: ETERNAL_GREEN}}>{statMap.get("total_dmg")}</span></div>
                            <div className="mb-1" style={{ fontSize: '1em' }}>Accuracy: <span style={{color: ETERNAL_GREEN}}>{statMap.get("avg_acc")}%</span></div>
                        </div>)
                }
            </CardContent>
        </Card>
    )
}