import React, { FC, useEffect, useState, createContext } from 'react';
import { Box } from '@mui/material';
import { LobbyCardNav } from './LobbyPanelComponents/LobbyCardNav.tsx';
import httpCommon from '../../../../../http-common.js';
import { Lobby, Lobbies } from './LobbyTypes.tsx';
import { useNavigate } from 'react-router';
import { Loading } from '../../../MiscComponents/Loading.js';
import { SelectedLobby } from './LobbyPanelComponents/SelectedLobby.tsx';

export const pullLobbiesContext = createContext<Function>(() => console.log('pull lobbies'));
export const setSelectedLobbyContext = createContext<Function>(() => console.log('set selected lobby'));

export const LobbyPanel: FC<{}> = () : JSX.Element=> {
    const [selectedLobby, setSelectedLobby] = useState<Lobby>({} as Lobby);
    const [lobbies, setLobbies] = useState<Lobbies>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    const pullLobbies = async () => {
        const {data: lobbies} = await httpCommon.get('/lobbies');
        setLobbies(lobbies);
        setLoading(false);
    }

    const lobbyOnClick = (lobby: Lobby | null) => {
        if(!lobby){
            urlParams.delete('lobby');
            setSelectedLobby({} as Lobby);
        }
        else {
            urlParams.set('lobby', lobby.name)
            setSelectedLobby(lobby);
        }
        navigate('/adminpanel?'+urlParams)
    }

    useEffect(() => {
        pullLobbies().catch(console.error);
    }, []);

    useEffect(() => {
        if(urlParams.get('lobby') && lobbies.length > 0){
            const foundLobby = lobbies.find(l => l.name === urlParams.get('lobby'));
            if(foundLobby) setSelectedLobby(foundLobby as Lobby);
        }
    }, [urlParams])


    if(loading) return <Loading />


    return (
        <pullLobbiesContext.Provider value={pullLobbies}>
            <setSelectedLobbyContext.Provider value={lobbyOnClick}>
                <Box display='flex' flexDirection='row'>
                    <LobbyCardNav lobbies={lobbies}/>
                    {Object.keys(selectedLobby).length > 0 ? <SelectedLobby lobby={selectedLobby}/> : 'Select or Create a lobby'}
                </Box>
            </setSelectedLobbyContext.Provider>
        </pullLobbiesContext.Provider>

    )
}