import React from "react";
import {TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper} from '@mui/material';
import { HelpTableRow } from "./HelpTableRow";

export const HelpTable = ({mail, listOfPlayers}) =>{
    return (
        <TableContainer component={Paper} elevation={5} sx={{height: 'auto', maxHeight: "67vh", overflowX: "hidden", overflowY: "scroll"}}>
            <Table stickyHeader >
                <TableHead>
                    <TableRow>
                        <TableCell>Ticket #</TableCell>
                        <TableCell>Submitted By</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Assignee</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        mail && mail.map(letter => <HelpTableRow letter={letter} listOfPlayers={listOfPlayers}/>)
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}