import React from "react";
import { ConfirmationDialog } from "./ConfirmationSnackbar";
import httpCommon from "../../../http-common";

export const InviteSnackBar = ({open, invite, setInvite, setInviteOpen, listOfPlayers}) => {
    let reciever = "";
    if(invite){
        reciever = listOfPlayers.filter(player => player[0] === invite.reciever)[0][1];
    }
    
    const message = "Are you sure you want to cancel " + (invite ? reciever : "") + "'s invite to " + (invite ? invite.teamname : "");
    const accept = async () => {
        try {
            await httpCommon.delete('/teams/cancelinvite?id='+invite.id);
        } catch(err) {
            console.log(err);
        }

        setInviteOpen(false);
        setTimeout(() => {
            setInvite(null);
        }, 500)
    }

    const deny = () => {
        setInviteOpen(false);
        setTimeout(() => {
            setInvite(null);
        }, 500)
    }

    return (
        <ConfirmationDialog open={open} message={message} accept={accept} deny={deny} />
    )
}