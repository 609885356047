import React, { FC, useContext, useEffect, useLayoutEffect, useState } from "react";
import {Card, CardHeader, CardContent} from "@mui/material";
import { ChartContainer, ChartsReferenceLine, ChartsTooltip, ChartsXAxis, ChartsYAxis, LinePlot} from "@mui/x-charts"
import { buildCategories } from "../../../pubFuncs.js";
import { HiddenElement } from "./HiddenElement.js";
import { useWindowSize } from "../../../hooks/useWindowSize.js";
import httpCommon from "../../../../http-common.js";
import { ETERNAL_GREEN, TRACK_MMR_TYPE } from "../../../globalConsts.ts";
import { colorContext, currentSeasonContext } from "../BoundPage.tsx";
import { onUserProfileContext, permLevelContext, playerIDContext } from "../PlayerPageFunctional.tsx";

interface Props {
    trackData: TRACK_MMR_TYPE[];
    startingMMR: number;
    season?: string | null;
}

export const GraphCard: FC<Props> = ({trackData, startingMMR, season}) => {
    const [windowWidth, ] = useWindowSize();
    const [width, setWidth] = useState<number>(500);
    const [testTrack, setTestTrack] = useState<any[]>([]);

    const [color, ] = useContext<[string, Function]>(colorContext);
    const permLevel = useContext<number>(permLevelContext);
    const onUserProfile = useContext<boolean>(onUserProfileContext);
    const discordID = useContext<number | string>(playerIDContext);

    useEffect(() => {
        const getNewTrackMMR = async () => {
            const {data: trackMMR} = await httpCommon.get('/track_mmr/new/discordID?discordID='+discordID);
            setTestTrack(trackMMR);
        }

        getNewTrackMMR().catch(console.error);
    },[discordID]);

    useLayoutEffect(() => {
        const graphDiv = document.getElementById('graph-div');
        if(graphDiv) setWidth(graphDiv.offsetWidth);
    },[windowWidth]);

    let calcStarting = startingMMR;
    const xDataMap = trackData.map(match => {return {match_start: buildCategories(match.match_start), mmr: calcStarting += match.matchMMR}});
    if(season !== 'LegacyLeague') xDataMap.unshift({match_start: 'Starting MMR', mmr: startingMMR});
    else xDataMap.unshift({match_start: 'Starting LP', mmr: startingMMR});

    let calcStartingTest = startingMMR;
    const xDataMapTestWhole = testTrack.map(match => {return {match_start: buildCategories(match.match_start), matchMMR: match.matchMMR}});
    const xDataMapTest = xDataMapTestWhole.filter(m => Boolean(xDataMap.find(f => f.match_start === m.match_start))).map(m => {return {match_start: m.match_start, mmr: calcStartingTest += m.matchMMR}});
    if(xDataMap.length !== xDataMapTest.length)
        xDataMapTest.unshift({match_start: 'Starting MMR', mmr: startingMMR});

    while(xDataMap.length > xDataMapTest.length)
        xDataMapTest.push({match_start: xDataMapTest[xDataMapTest.length - 1].match_start, mmr: xDataMapTest[xDataMapTest.length - 1].mmr,});

    while(xDataMap.length < xDataMapTest.length)
        xDataMap.push({match_start: xDataMap[xDataMap.length - 1].match_start, mmr: xDataMap[xDataMap.length - 1].mmr,});

    console.log(xDataMap.length / 10)

    if((permLevel >= 2) || (permLevel === 1 && onUserProfile)){
        return (
            <Card sx={{borderRadius: 5, border: color + " 2px solid"}}>
                <CardHeader 
                    titleTypographyProps={{
                        fontFamily: "Orbitron",
                        fontSize: 30
                    }}
                    title={"Games Played: " + trackData.length} 
                />
                <CardContent>
                    <div id="graph-div" style={{display: "flex", flexDirection:"column"}}>
                        <ChartContainer
                            width={width + 25}
                            height={300}
                            series={[
                                {data: xDataMap.map(m => m.mmr), label: season !== 'LegacyLeague' ? 'MMR' : 'League Points', type: "line", color: ETERNAL_GREEN},
                                // {data: xDataMapTest.map(m => m.mmr), label: 'TEST MMR', type: 'line', color: '#ff0000'}
                            ]}
                            xAxis={[{scaleType: "point", data: xDataMap.map(m => m.match_start), tickInterval: (label, index) => index % Math.round(xDataMap.length / 10) === 0 && index !== 0}]}
                            sx={{marginTop: -4, marginBottom: -2}}
                        >
                            <LinePlot/>
                            <ChartsXAxis/>
                            <ChartsYAxis/>
                            <ChartsTooltip trigger="axis"/>
                            {
                                season !== 'LegacyLeague'? 
                                    <>
                                        <ChartsReferenceLine y={1800} label="S Tier" lineStyle={{ stroke: 'red' }} />
                                        <ChartsReferenceLine y={1600} label="G Tier" lineStyle={{ stroke: '#ff44ff' }} />
                                        <ChartsReferenceLine y={1400} label="A Tier" lineStyle={{ stroke: '#2222ff' }} />
                                        <ChartsReferenceLine y={1200} label="B Tier" lineStyle={{ stroke: '#8888ff' }} />
                                        <ChartsReferenceLine y={1000} label="C Tier" lineStyle={{ stroke: '#ffd700' }} />
                                        <ChartsReferenceLine y={800} label="D Tier" lineStyle={{ stroke: '#c0c0c0' }} />
                                        <ChartsReferenceLine y={600} label="E Tier" lineStyle={{ stroke: '#cd7f32' }} />
                                        <ChartsReferenceLine y={400} label="F Tier" lineStyle={{ stroke: 'black' }} />
                                    </>
                                    :
                                    <></>
                            }
                            
                        </ChartContainer>
                    </div>
                </CardContent>
            </Card>
        )
    }
    
    return (
        <HiddenElement permLevel={permLevel} onUserProfile={onUserProfile} color={color}/>
    )
}