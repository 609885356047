import React from "react";
import {Typography} from "@mui/material";
import { Link } from "react-router-dom";
import { QNABox } from "./QNABox";
import { ETERNAL_GREEN } from "../../../globalConsts.ts";

export const EternalFAQs = () => {
    return (
        <>
            <Typography fontFamily="">
                <QNABox question={"1. Can I sign up for EEC scrims if I'm not from NA?"}>
                    A. Yes!. EEC open scrims are currently open to players from all regions. Only EEC verified scrims are NA only.
                </QNABox>
                <QNABox question={"2. Do I need both my teammates in the Discord Server to sign up for scrims?"}>
                    A. Yes.
                </QNABox>
                <QNABox question={"3. How do I bind my account?"}>
                    A. You need to play at least one EEC game and head to <a href="https://discord.com/channels/1031340622692499506/1192705204169232384" style={{color: ETERNAL_GREEN}}>#mmr-account-bind</a> to get started.
                </QNABox>
                <QNABox question={"4. How do I know if I paired my account correctly?"}>
                    A. If you've played in at least one EEC game and used the /bind command in <a href="https://discord.com/channels/1031340622692499506/1192705204169232384" style={{color: ETERNAL_GREEN}}>#mmr-account-bind</a>, if you <span style={{fontWeight: 'bold'}}>DO NOT</span> have the <span style={{color: ETERNAL_GREEN}}>@Not Paired</span> role, you have successfully bound your account. You can also check if your name appears on the <Link to="/leaderboard" style={{color: ETERNAL_GREEN}}>MMR Leaderbaord</Link>
                </QNABox>
                <QNABox question={"5. What is the purpose of @VIP and what does it offer me?"}>
                    A. VIP is a way to provide lesser-experienced players the opportunity to practice against some of the best talent in CC and PL or against like-minded individuals who are serious about improving. It provides a better scrim experience, the ability to promote yourself as a player to the upper echelons of competitive Apex, and priority over non-VIPs during most EEC events!
                </QNABox>
                <QNABox question={"6. When is the next Eternal event happening?"}>
                    A. You can find out when we plan on doing things via the <a href="https://discord.com/channels/1031340622692499506/1224789977137938552" style={{color: ETERNAL_GREEN}}>server calendar</a>.
                </QNABox>
            </Typography>
        </>
    )
}