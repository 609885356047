import { useContext, useEffect, useState } from "react"
import httpCommon from "../../../http-common";
import { Box, Button, Card, CardHeader, CardContent, Table, TableContainer, TableRow, TableCell, TableHead, TableBody, Paper, Collapse, Autocomplete, TextField } from '@mui/material';
import { MobileContext } from "../../../AppFunctional";
import { Delete } from "@mui/icons-material";
import { TimeoutButton } from "../Buttons/TimeoutButton";

export const VIPPanel = () => {
    const [teammates, setTeammates] = useState([]);
    const [listOfPlayers, setListOfPlayers] = useState([]);
    const [showAddPlayer, setShowAddPlayer] = useState(false);

    const [selectedPlayers, setSelectedPlayers] = useState([]);

    const isMobile = useContext(MobileContext);

    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    const loggedIn = localStorage.getItem('loggedIn');
    let name = "";
    let discordID = -1;
    if(loggedIn){ 
        name = loginInfo.username;
        discordID = loginInfo.id;
    }

    const fetchListOfPlayers = async () => {
        const {data: listOfPlayers} = await httpCommon.get("/eternalAccounts/discordMembers");
        setListOfPlayers(listOfPlayers);
        await fetchTeammates();
    }

    const fetchTeammates = async () => {
        if(discordID !== -1){
            const {data: teammates} = await httpCommon.get('/vip_teammates/vip?vipID='+discordID);
            setTeammates(teammates);
        }
    }

    useEffect(() => {
        fetchListOfPlayers().catch(console.error);
    }, [discordID]);

    const listMinusMe = listOfPlayers.filter(p => p[0] !== discordID);

    const handleSubmit = async (player) => {
        try {
            await httpCommon.post('/vip_teammates/add', {player});
        } catch(err){
            console.log(err);
        }
    }

    const handleDelete = async (player) => {
        try {
            await httpCommon.post('/vip_teammates/remove', {player});
            await fetchTeammates();
        } catch(err){
            console.log(err);
        }
    }
    
    return (
        <Box>
            <Box className='other-page'/>
            <Card sx={{width: isMobile ? '100%': '70%', marginLeft: 'auto', marginRight: 'auto'}}>
                <CardHeader title={name} titleTypographyProps={{fontSize: '2em'}}
                    subheader='VIPs can have up to three teammates. Those teammates get the same perks as a VIP minus the cool discord badge. Thank you for your support of Eternal Esports Club'
                    action={<Button color='eternal' variant='contained' onClick={() => setShowAddPlayer(!showAddPlayer)}>Add Teammate</Button>}
                />
                <CardContent>
                    <Collapse in={showAddPlayer} className="mb-3">
                        <Paper elevation={2}>
                            <Autocomplete
                                multiple
                                size="small"
                                options={listMinusMe.map(member => member[1])}
                                getOptionDisabled={() => (teammates.length + selectedPlayers.length) >= 3 ? true : false}
                                renderInput={(params) => <TextField {...params} label="Discord Name" color="eternal"/>}
                                id="Autocomplete"
                                onChange={(event, value) => setSelectedPlayers(value)}
                                className="m-2 p-2"
                            />
                            <TimeoutButton 
                                onClick={async () => {
                                    const addPlayers = selectedPlayers.map(player => listMinusMe.find((member) => player === member[1]));

                                    console.log(addPlayers);

                                    for(const index in addPlayers){
                                        const player = addPlayers[index];
                                        await handleSubmit(player);
                                    }
                                    setShowAddPlayer(false);
                                    await fetchTeammates();
                                }}
                            >Add</TimeoutButton>
                        </Paper>
                    </Collapse>
                    <TableContainer component={Paper} elevation={5} sx={{overflowY: "auto", overflowX: "hidden", maxHeight: 270, color: "white", borderRadius: 5}}>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Teammate Name</TableCell>
                                    <TableCell>REMOVE</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {teammates && teammates.map(tm => {
                                    return (
                                        <TableRow>
                                            <TableCell>{tm.vip_name}</TableCell>
                                            <TableCell><Delete onClick={() => handleDelete([tm.discordID, tm.vip_name])}/></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Box>
    )
}