import React, { useEffect, useState } from "react";
import {TableCell, TextField} from '@mui/material';
import httpCommon from "../../../../../../http-common";

export const QSCell = ({account, isStaff}) => {

    const [qScore, setQScore] = useState(-1);

    useEffect(() => {
        const fetchQS = async () => {
            const {data: {qualityScore: qs}} = await httpCommon.get('/players/quality?discordID='+account.discordID);
            setQScore(qs);
        }

        fetchQS().catch(console.error);
    }, [account.discordID])

    return (
        <TableCell>
            <TextField
                inputProps={{
                    readOnly: true
                }}
                size="small"
                color='eternal'
                value={qScore.toFixed(4)}
                label='Quality Score'
                sx={{width: 100}}
                disabled={isStaff}
            />
        </TableCell>
    )
}