import React from "react";
import { ConfirmationDialog } from "./ConfirmationSnackbar";
import httpCommon from "../../../http-common";

export const TeamSnackbar = ({open, team, setTeam, setTeamOpen, fetchTeam}) => {
    const message = "Are you sure you want to delete " + (team.team.teamname ||  "") + "?";
    const accept = async () => {
        console.log("accept ", team);
        try {
            await httpCommon.delete("/teams/delete?teamname="+team.team.teamname+"&captain="+team.team.captain);
            window.location.href = "/team";
        } catch(err) {
            console.log(err);
        }
        
        setTeamOpen(false);
        setTimeout(() => {
            setTeam("");
        }, 500)
        await fetchTeam().catch(console.error);
    }
    
    const deny = () => {
        setTeamOpen(false);
        setTimeout(() => {
            setTeam("");
        }, 500)
    }

    return (
        <ConfirmationDialog open={open} message={message} accept={accept} deny={deny} />
    )
}