import React, { FC, useContext } from "react";
import "../Pages.css"
import { Grid, Box } from "@mui/material";
import { LegacyLeaderboardCard } from "./LegacyLeaderboardCard.tsx";
import { LLTitle } from "../HomePage/LegacyLeague/LLTitle.tsx";

export const LegacyLeaderboardPage: FC<{}> = (): JSX.Element => {
    return (
        <Box className = "page">
            <Box className="other-page"/>
            <Grid container spacing={1}>
                <Grid item xs={12}display='flex'>
                    <Box className='ms-auto me-auto' textAlign='center'>
                        <LLTitle/>
                    </Box>
                </Grid>
                <Grid item xs={12} style={{ alignContent: "center"}}>
                    <LegacyLeaderboardCard />
                </Grid>
            </Grid>
        </Box>
    );
}