import React, { FC, useState } from "react";
import { Box, Paper } from '@mui/material';
import { Lobbies } from "../LobbyTypes.tsx";
import { LobbyCard } from "./LobbyCard.tsx";
import { NewLobbyDialog } from "./NewLobbyDialog.tsx";

interface Props {
    lobbies: Lobbies;
}

export const LobbyCardNav: FC<Props> = ({lobbies}): JSX.Element => {
    const [showLobbyCreate, setShowLobbyCreate] = useState(false);

    return (
        <Box display='flex' flexDirection='column' width='15%' className="me-3">
            <LobbyCard showCreate={setShowLobbyCreate}/>
            <NewLobbyDialog open={showLobbyCreate} setOpen={setShowLobbyCreate} />
            <Box component={Paper} elevation={5} height='64vh' sx={{overflowY: 'auto'}} hidden={lobbies.length === 0}>
                {
                    lobbies.map(lobby => <LobbyCard lobby={lobby}/>)
                }
            </Box>
        </Box>
    )
}