import React from "react";
import {Typography, Grid} from "@mui/material";
import { Link } from "react-router-dom";
import { QNABox } from "./QNABox";
import { ETERNAL_GREEN } from "../../../globalConsts.ts";

export const MMRFAQs = () => {
    return (
        <>
            <Typography fontFamily="">
                <QNABox question={"1. What tier do non verified players start at?"}>
                    A. New players start at <span style={{color: ETERNAL_GREEN}}>Tier D</span>. By playing in EEC Scrims/SoloQ, you can earn MMR to increase your tier and gain access to exclusive scrims. Players who <a href="https://discord.com/channels/1031340622692499506/shop" style={{color: ETERNAL_GREEN}}>purchase VIP</a> gain access instantly and start at Tier C
                </QNABox>
                <QNABox question={"2. What are the requirements for verification?"}>
                    A. At a minimum, EEC requires 3x CC Finals appearances (in Y3/Y4 ALGS) for verification. For per-tier and per-region requirements, head over to <a href="https://discord.com/channels/1031340622692499506/1134756146222202890" style={{color: ETERNAL_GREEN}}>#verify-here</a>!
                </QNABox>
                <QNABox question={"3. What do I do if I don't meet the minimum requirements for verification and I want to play in verified scrims?"}>
                    A. If you have your account bound correctly and you're earning MMR from playing in EEC open scrims, hitting Tier B will unlock verified scrims. Otherwise, head over to our shop to <a href="https://discord.com/channels/1031340622692499506/shop" style={{color: ETERNAL_GREEN}}>purchase VIP</a> and gain access instantly.
                </QNABox>
                <QNABox question={"4. Can't you just give me roles so I can play? I don't want to fill out the application."}>
                    A. Nope!
                </QNABox>
                <QNABox question={"5. How do I see how much MMR everyone else has?"}>
                    A. View our <Link to="/leaderboard" style={{color: ETERNAL_GREEN}}>leaderboard</Link>!
                </QNABox>
                <QNABox question={"6. How does MMR work?"}>
                    A. MMR is calculated per game. It accounts for the MMR of all other players in the lobby and weighs your chances of performing well to determine your gains and losses.
                </QNABox>
                <QNABox question={"7. What are the MMR tiers I can apply for?"}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <span style={{fontWeight: "bold"}}>NA Tiers:</span>
                                <ul>
                                    <li><span style={{fontWeight: "bold", color: '#d20303'}}>Tier: S</span> - 1800 <span style={{fontSize: '75%', color: '#dddddd'}}>(Current PL)</span></li>
                                    <li><span style={{fontWeight: "bold", color: '#b447fc'}}>Tier: G</span> - 1600 <span style={{fontSize: '75%', color: '#dddddd'}}>(Ex-PL, top 20 team overall most recent PSQ/CC)</span></li>
                                    <li><span style={{fontWeight: "bold", color: '#47b5f0'}}>Tier: A</span> - 1400 <span style={{fontSize: '75%', color: '#dddddd'}}>(6x finalist min.)</span></li>
                                    <li><span style={{fontWeight: "bold", color: '#1dd6da'}}>Tier: B</span> - 1200 <span style={{fontSize: '75%', color: '#dddddd'}}>(3x finalist min.)</span></li>
                                    <li><span style={{fontWeight: "bold", color: '#eedda8'}}>Tier: C</span> - 1000 <span style={{fontSize: '75%', color: '#dddddd'}}>(2x Semis min.)</span></li>
                                </ul>
                            </Grid>
                            <Grid item xs={4}>
                            <span style={{fontWeight: "bold"}}>EMEA Tiers:</span>
                                <ul>
                                    <li><span style={{fontWeight: "bold", color: '#d20303'}}>Tier: S</span> - 1800 <span style={{fontSize: '75%', color: '#dddddd'}}>(Top 22 Most recent PL split)</span></li>
                                    <li><span style={{fontWeight: "bold", color: '#b447fc'}}>Tier: G</span> - 1600 <span style={{fontSize: '75%', color: '#dddddd'}}>(Year4 PL starter)</span></li>
                                    <li><span style={{fontWeight: "bold", color: '#47b5f0'}}>Tier: A</span> - 1400 <span style={{fontSize: '75%', color: '#dddddd'}}>(ex-PL, Top 10 overall CC finish)</span></li>
                                    <li><span style={{fontWeight: "bold", color: '#1dd6da'}}>Tier: B</span> - 1200 <span style={{fontSize: '75%', color: '#dddddd'}}>(6x finals min.)</span></li>
                                    <li><span style={{fontWeight: "bold", color: '#eedda8'}}>Tier: C</span> - 1000 <span style={{fontSize: '75%', color: '#dddddd'}}>(2x Finalist)</span></li>
                                </ul>
                            </Grid>
                            <Grid item xs={4}>
                            <span style={{fontWeight: "bold"}}>SA Tiers:</span>
                                <ul>
                                    <li><span style={{fontWeight: "bold", color: '#47b5f0'}}>Tier: A</span> - 1400 <span style={{fontSize: '75%', color: '#dddddd'}}>(Most recent LAN qualifiers)</span></li>
                                    <li><span style={{fontWeight: "bold", color: '#1dd6da'}}>Tier: B</span> - 1200 <span style={{fontSize: '75%', color: '#dddddd'}}>(Match point in the most recent Regional Finals)</span></li>
                                    <li><span style={{fontWeight: "bold", color: '#eedda8'}}>Tier: C</span> - 1000 <span style={{fontSize: '75%', color: '#dddddd'}}>(Qualified for Regional Finals)</span></li>
                                </ul>
                            </Grid>
                        </Grid>
                </QNABox>
            </Typography>
        </>
    )
}