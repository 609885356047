import { Box, Grid, Typography } from '@mui/material';
import React, { FC, useContext, useRef, useState } from 'react';
import { LLTitle } from './LegacyLeague/LLTitle.tsx';
import { LLCalendar } from './LegacyLeague/LLCalendar.tsx';
import { LLPoints } from './LegacyLeague/LLPoints.tsx';
import { LLLogoBox } from './LegacyLeague/LLLogoBox.tsx';
import { LLQualBox } from './LegacyLeague/LLQualBox.tsx';
import { LLTourneyFlow } from './LegacyLeague/LLTourneyFlow.tsx';
import { MobileContext } from '../../../AppFunctional.js'
import { DownArrow } from '../../../images/imgIndex.js';
import { LeagueBlurb } from './LegacyLeague/LeagueBlurb.tsx';

export const LegacyLeague: FC<{}> = () : JSX.Element => {

    const isMobile = useContext(MobileContext);
    const [calWidth, setCalWidth] = useState<number>(0);

    const blurbRef = useRef<HTMLDivElement | null>(null);
    const rulesRef = useRef<HTMLDivElement | null>(null);
    const calRef = useRef<HTMLDivElement | null>(null);
    const bracketRef = useRef<HTMLDivElement | null>(null);

    if(isMobile)
        return (
        <Box className='mb-5'>
            <Box className='other-page' boxShadow='0 0 200px rgba(0, 0, 0, 0.9) inset'/>
            <Grid container spacing={1}>
                <Grid item xs={12} alignContent='center' textAlign='center' >
                    <LLTitle />
                </Grid>
                <Grid item xs={12} alignContent='center' textAlign='center' display='flex' flexDirection='column'>
                    <LeagueBlurb height={'100%'} className='mb-4'/>
                    <LLLogoBox className='mb-4' />
                    <LLQualBox className='mb-4' width='100%'/>
                    <LLCalendar setCalWidth={setCalWidth} className='mb-4'/>
                    <LLTourneyFlow width={'100%'} className='mb-4'/>
                </Grid>
            </Grid>

        </Box>
        )

    return (
        <Box className='mb-5'>
            <Box className='other-page' boxShadow='0 0 200px rgba(0, 0, 0, 0.9) inset'/>
            <Grid container spacing={1}>
                <Grid item xs={12} alignContent='center' textAlign='center' height='82vh'>
                    <LLTitle />
                </Grid>
                <Grid item xs={12} alignContent='center' textAlign='center' height='10vh' onClick={() => blurbRef.current?.scrollIntoView({behavior: 'smooth'})} ref={blurbRef}>
                    <Typography color='white' fontSize={30}>What is Legacy League?</Typography>
                    <img src={DownArrow} style={{ filter: "invert(180)", width: "50px" }} alt='down arrow'/>
                </Grid>
                <Grid item xs={12} alignContent='center' textAlign='center'>
                    <LeagueBlurb height={'80vh'}/>
                </Grid>
                <Grid item xs={12} alignContent='center' textAlign='center' height='10vh' onClick={() => rulesRef.current?.scrollIntoView({behavior: 'smooth'})} ref={rulesRef}>
                    <Typography color='white' fontSize={30}>Rules</Typography>
                    <img src={DownArrow} style={{ filter: "invert(180)", width: "50px" }} alt='down arrow'/>
                </Grid>
                <Grid item xs={12} height='80vh' display='flex' flexDirection='row'>
                    <Box width='40%' className='ms-auto me-4' >
                        <LLLogoBox className='mb-3 mt-auto' fontSize={25}/>
                        <LLQualBox className='mt-3 mb-auto' width='100%' fontSize={25}/>
                    </Box>
                    <LLPoints imgHeight='70vh' className='me-auto'/>
                </Grid>
                <Grid item xs={12} alignContent='center' textAlign='center' height='10vh' onClick={() => calRef.current?.scrollIntoView({behavior: 'smooth'})} ref={calRef}>
                    <Typography color='white' fontSize={30}>Calendar</Typography>
                    <img src={DownArrow} style={{ filter: "invert(180)", width: "50px" }} alt='down arrow'/>
                </Grid>
                <Grid item xs={12} alignContent='center' textAlign='center'>
                    <LLCalendar imgHeight='80vh' setCalWidth={setCalWidth}/>
                </Grid>
                <Grid item xs={12} alignContent='center' textAlign='center' height='10vh' onClick={() => bracketRef.current?.scrollIntoView({behavior: 'smooth'})} ref={bracketRef}>
                    <Typography color='white' fontSize={30}>Bracket</Typography>
                    <img src={DownArrow} style={{ filter: "invert(180)", width: "50px" }} alt='down arrow'/>
                </Grid>
                <Grid item xs={12} height='80vh'>
                    <LLTourneyFlow width={'90%'} className='me-auto ms-auto mb-3'/>
                </Grid>
                
            </Grid>
        </Box>
    )
}