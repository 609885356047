import React from "react";
import {Card, CardContent, Typography, Paper} from '@mui/material';

export const CheckinDayCard = ({checkinDay, dateKey}) => {
    
    const numChecks = Boolean(checkinDay) ? checkinDay.length : 0;
    if(numChecks === 0) return "";

    return (
        <Card component={Paper} elevation={5}>
            <CardContent>
                <Typography>
                    {dateKey}
                </Typography>
                Number of Teams: {numChecks}
            </CardContent>
        </Card>
    )
}