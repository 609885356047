import React, { FC, useContext, useEffect, useState } from "react";
import {Grid, Paper, Card, CardContent} from "@mui/material"
import { Twitch, Twitter } from "../../../../images/imgIndex";
import httpCommon from "../../../../http-common";
import { playerIDContext } from "../PlayerPageFunctional.tsx";
import { colorContext } from "../BoundPage.tsx";
import { CONNECTION_TYPE } from "../../../globalConsts.ts";

interface Props {
    connections: [any, Function];
}  

export const SocialsCard: FC<Props> = ({connections}) => {

    const [twitterConnection, setTwitterConnection] = useState<CONNECTION_TYPE | null>(null);
    const [twitchConnection, setTwitchConnection] = useState<CONNECTION_TYPE | null>(null);

    const discordID = useContext<number | string>(playerIDContext);
    const [color, ] = useContext<[string, Function]>(colorContext);
    const [connectionSettings, setConnectionList] = connections;

    useEffect(() =>  {
        const fetchSocials = async () => {
            const {data: {socialsJSON}} = await httpCommon.get("/eternalAccounts/socials?discordID="+discordID);
            if(socialsJSON){
                const socials = JSON.parse(socialsJSON);
                const twitchConnection = socials.filter((s: CONNECTION_TYPE) => s.type === "twitch")[0];
                if(twitchConnection)
                    setTwitchConnection(twitchConnection);

                const twitterConnection = socials.filter((s: CONNECTION_TYPE) => s.type === "twitter")[0];
                if(twitterConnection)
                    setTwitterConnection(twitterConnection);
                setConnectionList({"twitch": twitchConnection, "twitter": twitterConnection});
            }
        }

        fetchSocials().catch(console.error);
    }, [discordID, setConnectionList]);

    const connectionList: React.JSX.Element[] = [];
    if(twitchConnection && connectionSettings && connectionSettings.twitch){
        const twitchLink = "https://twitch.tv/" + twitchConnection.name;
        connectionList.push(
            <Grid item>
                <Paper elevation={5} sx={{width: 75, height: 75}} className = "select" onClick={() => window.open(twitchLink, "_blank")}>
                    <img style={{maxWidth: "50px", marginLeft: "15px", marginTop: "15px"}} src = {Twitch} alt = "Twitch"  />
                </Paper>
            </Grid>
        );
    }

    if(twitterConnection && connectionSettings && connectionSettings.twitter){
        const twitterLink = "https://twitter.com/" + twitterConnection.name;
        connectionList.push(
            <Grid item>
                <Paper elevation={5} sx={{width: 75, height: 75}} className = "select" onClick={() => window.open(twitterLink, "_blank")}>
                    <img style={{maxWidth: "50px", marginLeft: "15px", marginTop: "15px"}} src = {Twitter} alt = "Twitter" />
                </Paper>
            </Grid>
            );
    }

    if(connectionList.length > 0){
        return(
            <Card sx={{borderRadius:5, border: color + " 2px solid"}}>
                <CardContent>
                    <Grid container spacing={3}>
                        {connectionList}
                    </Grid>
                </CardContent>
            </Card>
        );
    } 
    return "";

}