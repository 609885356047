import React, { useState } from "react";
import {TableCell} from '@mui/material';

export const BlurCell = ({account, attribute}) => {
    const info = account[attribute];
    const [isBlurred, setIsBlurred] = useState(true);
    
    if(!info) return <TableCell></TableCell>

    return (
        <TableCell onClick={() => setIsBlurred(!isBlurred)} sx={{color: isBlurred ? "transparent" : 'white', textShadow: isBlurred ? "2px 2px 10px white" : 'none', maxWidth: 150, overflow: 'hidden'}}>
            {info}
        </TableCell>
    )
}