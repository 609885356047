import React, { useContext } from "react";
import {Grid, Paper, Box, Button, IconButton, Typography} from '@mui/material';
import AutoLinkText from 'react-autolink-text2';
import {TimeoutButton} from "../../../Buttons/TimeoutButton";
import { fetchMailContext } from "./HelpInbox";
import httpCommon from "../../../../../http-common";
import { ArrowBack } from "@mui/icons-material";
import TimeAgo from 'react-timeago'
import { timeConverter } from "../../../../pubFuncs";
import { adminPanelPlayers } from "../../AdminPanelFunctional";
import { ETERNAL_GREEN } from "../../../../globalConsts.ts";

export const HelpTicket = ({ticket, clearTicket, listOfPlayers}) => {
    const type = ticket.report.type;
    const desc = ticket.report.data.Description;
    const clips = ticket.report.data.ClipLinks || ticket.report.data.Clips;
    const screenshots = ticket.report.data.ScreenshotLinks;
    const achievements = ticket.report.data.Achievements;

    const oldDiscord = ticket.report.data.OldDiscord;
    const newDiscord = ticket.report.data.NewDiscord;
    const oldApex = ticket.report.data.OldApex;
    const newApex = ticket.report.data.NewApex;
    
    const ReportedUsers = ticket.report.data.ReportedUsers;
    const addInfo = ticket.report.data.AdditionalInfo;

    const logs = ticket.ticket.discordChannelLog;
    const status = ticket.ticket.status;


    const fetchMail = useContext(fetchMailContext);
    let gotListOfPlayers = useContext(adminPanelPlayers);
    if(!gotListOfPlayers) gotListOfPlayers = listOfPlayers;

    const foundAssignee = gotListOfPlayers.find(p => p[0] === ticket.ticket.assignee);
    const assignee = Boolean(foundAssignee) ? foundAssignee[1] : null;

    const author = gotListOfPlayers.find(p => p[0] === ticket.ticket.discordID);
    const authorName = Boolean(author) ? author[1] : "";

    const discordID = JSON.parse(localStorage.getItem('loginInfo')).id;

    const claimTicket = async () => {
        await httpCommon.post('/help/assign', {discordID, ticketID: ticket.ticket.id});
        await fetchMail();
    }

    const unclaimTicket = async () => {
        await httpCommon.post('/help/unassign', {ticketID: ticket.ticket.id});
        await fetchMail();
    }

    const closeTicket = async () => {
        await httpCommon.post('/help/complete', {ticketID: ticket.ticket.id});
        await fetchMail();
    }

    const reopenTicket = async () => {
        await httpCommon.post('/help/reopen', {ticketID: ticket.ticket.id, discordID});
        await fetchMail();
    }

    return (
        <Paper elevation={5} className="mt-3" >
            <Grid container spacing={1} className="ms-2">
                <Grid item xs={8} className="mb-3">
                    <Grid container spacing={1}>
                        <Grid item>
                            <IconButton color='eternal' variant='outlined' onClick={() => clearTicket()}>
                                <ArrowBack fontSize='large' />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <span style={{fontSize:45, fontFamily: 'Orbitron'}}>
                                {type} 
                                <span style={{fontSize:30, color: ETERNAL_GREEN, marginLeft: '15px'}}>{status}</span> 
                                <span style={{fontSize:25, marginLeft: '15px'}}>{authorName}</span>
                            </span>
                        </Grid>
                        <Grid item>
                            <span style={{fontSize:45, fontFamily: 'Orbitron'}}>
                                <span style={{fontSize:15, marginLeft: '15px'}}>
                                    {
                                        Boolean(ticket.ticket.timeClosed) ?
                                            <span>
                                                Closed: <span style={{color: ETERNAL_GREEN}}> {timeConverter(ticket.ticket.timeClosed/1000)}</span> by {assignee}
                                            </span>
                                        :
                                            <span>
                                                Created: <span style={{color: ETERNAL_GREEN}}> <TimeAgo date={new Date(ticket.ticket.timeCreated)}/> </span>
                                            </span>
                                    }
                                </span>
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{textAlign: 'right'}} className='me-4'>
                        {Boolean(assignee) ? 
                            status !== 'closed' ?
                                ticket.ticket.assignee === discordID ?
                                    <>
                                        <TimeoutButton color='warning' variant='outlined' onClick={() => unclaimTicket()}>Unclaim Ticket</TimeoutButton> 
                                        <TimeoutButton color='error' variant='outlined' onClick={() => closeTicket()}>Close Ticket</TimeoutButton> 
                                    </>
                                :
                                    <Button color='eternal' disabled variant='outlined'>Claimed By {assignee}</Button>
                            :
                            <TimeoutButton color='eternal' variant='outlined' onClick={() => reopenTicket()}>Reopen</TimeoutButton>
                        :
                            <TimeoutButton color='eternal' variant='outlined' onClick={() => claimTicket()}>Claim</TimeoutButton>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} className="mb-3">
                    {
                        !type==='Staff' ? 
                            `Description: ${desc}` 
                            : ''
                        }
                    
                </Grid>
                {Boolean(ReportedUsers) && 
                    ReportedUsers.map(r => 
                        <Grid item xs={12} className="mb-3">Reported User: {r.discordName} ({r.discordID})</Grid>
                    ) 
                }
                {Boolean(clips) && <Grid item xs={12} className="mb-3">Clips: <AutoLinkText text={clips} linkProps={{target:'_blank', background: ETERNAL_GREEN}} /></Grid> }
                {Boolean(screenshots) && <Grid item xs={12} className="mb-3">Screenshots: <AutoLinkText text={screenshots}/></Grid> }
                {Boolean(achievements) && <Grid item xs={12} className="mb-3">Achievements: <AutoLinkText text={achievements}/></Grid> }
                {Boolean(oldDiscord) && <Grid item xs={6} className="mb-3">Old Discord: <AutoLinkText text={oldDiscord}/></Grid> }
                {Boolean(newDiscord) && <Grid item xs={6} className="mb-3">New Discord: <AutoLinkText text={newDiscord}/></Grid>}
                {Boolean(oldApex) && <Grid item xs={6} className="mb-3">Old Apex Account: <AutoLinkText text={oldApex}/></Grid> }
                {Boolean(newApex) && <Grid item xs={6} className="mb-3">New Apex Account: <AutoLinkText text={newApex}/></Grid>}
                {Boolean(addInfo) && <Grid item xs={6} className="mb-3">Additional Info: <AutoLinkText text={addInfo}/></Grid>}
                {
                    logs && 
                    <>
                        <Typography fontSize={'2em'}>Logs:</Typography>
                        <Grid container spacing={1} sx={{height: '45vh', overflowY: 'scroll', overflowX: 'hidden'}}>
                        {
                            logs.map(m => {
                                return (
                                    <Grid item xs={12}><span style={{color: ETERNAL_GREEN}}> {m.date}</span>{m.author}: {m.content}</Grid>
                                )
                            })
                        }
                        </Grid>
                    </>
                }
                

            </Grid>
        </Paper>

    )

}