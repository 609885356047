import React, { useState, useContext } from "react";
import {Card, CardContent, Grid, Tabs, Tab} from "@mui/material";
import { MatchesTable } from "../../MatchesPage/MatchesTable";
import { MobileContext } from "../../../../AppFunctional";

export const BindTable = ({setBindMatchID}) => {
    const[tab, setTab] = useState('scrims');

    const isMobile = useContext(MobileContext);
    const width = isMobile ? "100%" : "40%";

    return (
        <Card style={{width, margin: "auto", marginBottom: "10px", height:"70vh"}}>
            <CardContent>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Tabs value={tab} onChange ={(event, value) => setTab(value)}>
                            <Tab label="Scrims" value="scrims"/>
                            <Tab label="Solo Queue" value="soloq"/>
                        </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                        <MatchesTable type={tab} isBinding={true} setBindMatchID={setBindMatchID}/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card> 
    )
}