import React from "react";
import { Close as IconClose } from '@mui/icons-material';
import { TimeoutButton } from "../Buttons/TimeoutButton";
import httpCommon from "../../../http-common";

export const NotificationCloseButton = ({invite, fetchInvites, closeSnackbar}) => {

    const deny = async () => {
        try {
            await httpCommon.post('/teams/denyinvite', {id: invite.id});
        } catch(err) {
            console.log(err);
        }
        fetchInvites().catch(console.error);
        closeSnackbar(invite.id);
    }

    return (
        <TimeoutButton color="error" size="small" onClick={deny}>
            <IconClose />
        </TimeoutButton>
    )
}
