import React, { useState } from "react";
import {Box, Popover} from "@mui/material";
import { Gem } from "react-bootstrap-icons";
import { ShowChartRounded } from "@mui/icons-material";
import { useCopyToClipboard } from "../../../hooks/useCopyToClipboard";

export const TeamPageHeader = ({team, players}) => {

    const [showPopup, setShowPopup] = useState(false);
    const [popupAnchorEl, setPopupAnchorEl] = useState(null);
    const copy = useCopyToClipboard();

    const isVIP = players.map(p => p.VIP).some(vip => vip);

    let teamMMR = 0;
    players.forEach(p => teamMMR += p.MMR);
    teamMMR /= players.length;

    const copyToClipboard = (event) => {
        const link = window.location.href.includes(team.team.teamname) ? window.location : window.location + "/" + team.team.teamname;
        copy(link);
        setPopupAnchorEl(event.target);
        setShowPopup(true);
        setTimeout(() => {
            setShowPopup(false);
            setPopupAnchorEl(null);
        }, 1000);   
    }

    return (
        <Box sx={{textWrap: "nowrap"}} onClick={copyToClipboard}>            
            {!isVIP ? <Gem size={45} color="white" className="me-3 mb-5 ms-2"/> : ""}
            <span style={{fontFamily: "Orbitron", fontSize: 70}}>
                {team.team.teamname}
            </span>
            <span style={{color: "white", fontSize: 30, fontFamily: "Orbitron"}} className="ms-3">
                {Math.round(teamMMR)}
            </span>
            <ShowChartRounded size={30} className="mb-3"/>
            <Popover open={showPopup} anchorEl={popupAnchorEl}>Copied to Clipboard</Popover>
        </Box>

    )

}