import React from "react";
import { Typography } from "@mui/material";
import { QNABox } from "./QNABox";
import { Grid } from "@mui/material";
import { ETERNAL_GREEN } from "../../../globalConsts.ts";

export const SoloQFAQs = () => {
    return (
        <>
            <Typography fontFamily="">
                <QNABox question={"1. How are drops determined?"}>
                    A. Drops are randomly assigned to team numbers each game. YOU MUST DROP AT YOUR ASSIGNED POI!!!
                </QNABox>
                <QNABox question={"2. How are teams determined?"}>
                    A. SoloQ uses eternals MMR system to balance all 60 players in queue evenly across the board.
                </QNABox>
                <QNABox question={"3. How is scoring broken down for the scoreboards?"}>
                    A. It uses ALGS point format and all 3 teammates will earn the same number of points for a match. (Example: 1st with 3kp means all 3 players will receive 15pts)
                </QNABox>
                <QNABox question={"4. Is Eternal SoloQ NA only?"}>
                    A. No, Eternal SoloQ is not NA only BUT we will be playing on NA servers and you must be able to speak English since that is the primary language of our server.
                </QNABox>
                <QNABox question={"5. Why can I not see any of the Solo Queue channels?"}>
                    A. Current queue lobbies are hidden to only the players in that queue, If you are trying to find the general channel make sure all channels are enabled via the "Browse Channels" in the top left of Discord.
                </QNABox>
                <QNABox question={"6. How can I bind my account to start tracking MMR/Stats?"}>
                    A. You need to play at least one EEC game and head to <a href="https://discord.com/channels/1031340622692499506/1192705204169232384" style={{color: ETERNAL_GREEN}}>#mmr-account-bind</a> to get started.
                </QNABox>
                <QNABox question={"7. What time does Solo Queue run?"}>
                    A. Every other Saturday, 7pm Eastern to 11pm Eastern. Check the <a href="https://discord.com/channels/1031340622692499506/1224789977137938552" style={{color: ETERNAL_GREEN}}>server calendar</a> to see the schedule for the month.
                </QNABox>
                <QNABox question={"8. What servers are SoloQ hosted on?"}>
                    A. Iowa GCE 1-4
                </QNABox>
                <QNABox question={"9. What is ALGS point format?"}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ul>
                                <li>1st: 12</li>
                                <li>2nd: 9</li>
                                <li>3rd: 7</li>
                                <li>4th: 5</li>
                                <li>5th: 4</li>
                            </ul>
                        </Grid>
                        <Grid item xs={6}>
                                <li>6th - 7th: 3</li>
                                <li>8th - 10th: 2</li>
                                <li>11th - 15th: 1</li>
                                <li>16th - 20th: 0</li>
                                <li>1 pt per kill</li>
                        </Grid>
                    </Grid>
                </QNABox>
            </Typography>
        </>
    )
}