import React, { useState } from "react";
import {Grid, Card, CardContent, Tabs, Tab, TableContainer, Paper, Table} from "@mui/material";
import { timeConverter, mapName } from "../../../common/pubFuncs";
import { PlayerTableMobile } from "./MatchViewPageComponents/PlayerTableMobile";
import { TeamTableMobile } from "./MatchViewPageComponents/TeamTableMobile";
import { ETERNAL_GREEN } from "../../../common/globalConsts.ts";

export const MatchViewPageMobile = ({match, matchData}) => {

    const [currentTab, setCurrentTab] = useState("team");

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} className="glow" style={{fontSize: '1.5em', fontFamily: "Orbitron", textAlign: "center"}}>
                <div className="ms-2">{mapName(match.map_name)}<br/>{timeConverter(match.match_start)}</div>
            </Grid>
            <Grid item xs={12}>
                <Card sx={{width: "98%", margin: "auto", height:"78vh"}}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Tabs value={currentTab} onChange={(event, value) => setCurrentTab(value)} textColor='eternal' TabIndicatorProps={{style: {
                                    backgroundColor: ETERNAL_GREEN, height: 3
                                }}}>
                                    <Tab label="Team Scores" value="team"/>
                                    <Tab label = "Player Scores" value = "player"/>
                                </Tabs>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper} elevation={5} sx={{overflowY: "auto", overflowX: "hidden", 
                                    maxHeight: "69vh", width: "90%", marginLeft: "auto", marginRight: "auto"}}>
                                    <Table size="small">
                                        {
                                            currentTab === "team" ?
                                            <TeamTableMobile matchData={matchData} />
                                            :
                                            <PlayerTableMobile matchData={matchData}/>
                                        }       
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}