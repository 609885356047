import React, { FC } from "react";
import { Box } from '@mui/material';
import { LEGACY_BACKGROUND } from "../../../globalConsts.ts";

interface Props {
    width?: string | number;
    maxWidth?: string | number;
    height?: string | number;
    children?: React.ReactNode;
    className?: string;
}

export const LLBox: FC<Props> = ({width, maxWidth, height, children, className}) : JSX.Element => {

    width = width || 'auto';
    height = height || 'auto';
    maxWidth = maxWidth || 'auto';

    return (
        <Box bgcolor={LEGACY_BACKGROUND} border='3px solid black' borderRadius={5} width={width} height={height} maxWidth={maxWidth} display='flex' flexDirection='row' className={className}>
            {children}
        </Box>
    )
}