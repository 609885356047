import React, { createContext, useContext, useEffect, useState } from "react";
import httpCommon from "../../../../../http-common";
import { Loading } from "../../../MiscComponents/Loading";
import {Grid, Tabs, Tab, ToggleButton, ToggleButtonGroup, Box} from '@mui/material';
import { HelpTable } from "./HelpTable";
import { HelpTicket } from "./HelpTicket";
import { useNavigate } from "react-router";
import { RefreshContext } from "../../../../../AppFunctional";
import { ETERNAL_GREEN } from "../../../../globalConsts.ts";

export const setSelectedTicketContext = createContext(null);
export const fetchMailContext = createContext(null);

export const HelpInbox = ({listOfPlayers}) => {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    const [mail, setMail] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState(urlParams.get('inboxTab') || 'PlayerMisconduct');
    const [selectedTicket, setSelectedTicket] = useState(urlParams.get('ticket'));

    const [toggleButtons, setToggleButtons] = useState(['open', 'pending']);
    const refreshToggle = useContext(RefreshContext);

    // clears params and state when using back button
    if(!urlParams.get('ticket') && selectedTicket !== null)
        setSelectedTicket(null);
    

    const tabOnClick = (value) => {
        setTab(value);
        urlParams.set('inboxTab', value);
        navigate('.?'+urlParams, {replace: true});
    }

    const ticketOnClick = (ticket) => {
        setSelectedTicket(ticket.ticket.id);
        urlParams.set('ticket', ticket.ticket.id);
        navigate('.?'+urlParams);
    }

    const clearTicket = () => {
        urlParams.delete('ticket');
        navigate('.?'+urlParams);
    }


    const fetchMail = async () => {
        const {data: {sendTickets: mail}} = await httpCommon.get('/help/');
        setMail(mail);
        setLoading(false);
    }

    useEffect(() => {
        fetchMail().catch(console.error);
    }, [refreshToggle]);

    if(loading) return <Loading />

    const filterStatus = mail.filter(m => toggleButtons.includes(m.ticket.status));
    const filteredMail = filterStatus.filter(m => {
        try {
            return m.report.type === tab;
        } catch(err){
            return false;
        }
    });

    const sendTicket = Boolean(selectedTicket) ? mail.find(m => m.ticket.id === Number(selectedTicket)) : null; 

    const PMCount = filterStatus.filter(m => {
        try {return m.report.type === 'PlayerMisconduct'}catch(err){return false}
    }).length;

    const LPACount = filterStatus.filter(m => {
        try {return m.report.type === 'LowPriorityAppeal'} catch(err){return false}
    }).length;

    const BindCount = filterStatus.filter(m => {
        try {return m.report.type === 'Binding'} catch(err){return false}
    }).length;

    const TACount = filterStatus.filter(m => {
        try {return m.report.type === 'TierApplication'} catch(err){return false}
    }).length;

    const GenCount = filterStatus.filter(m => {
        try {return m.report.type === 'General'} catch(err){return false}
    }).length;

    const StaffCount = filterStatus.filter(m => {
        try {return m.report.type === 'Staff'} catch(err){return false}
    }).length;

    return (
        <setSelectedTicketContext.Provider value={ticketOnClick}>
            <fetchMailContext.Provider value={fetchMail}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {
                            Boolean(selectedTicket) ? 
                                <HelpTicket ticket={sendTicket} clearTicket={clearTicket} listOfPlayers={listOfPlayers}/>
                            :
                            (
                                <Grid container spacing={0}>
                                    <Grid item xs={10} >
                                        <Tabs value={tab} variant="scrollable" scrollButtons={true} allowScrollButtonsMobile onChange ={(event, value) => tabOnClick(value)} textColor='inherit'
                                            TabIndicatorProps={{style: {backgroundColor: ETERNAL_GREEN, height: 3}}}
                                        >
                                            <Tab label={"Player Misconduct " + PMCount} value="PlayerMisconduct"/>
                                            <Tab label={"Low Priority Appeal " + LPACount} value="LowPriorityAppeal"/>
                                            <Tab label={"Binding " + BindCount} value='Binding' />
                                            <Tab label={"Tier Application " + TACount} value='TierApplication' />
                                            <Tab label={"General " + GenCount} value='General' />
                                            <Tab label={"Staff Reports " + StaffCount} value='Staff' />
                                        </Tabs>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box sx={{alignContent: 'right'}}>
                                            <ToggleButtonGroup size='small' value={toggleButtons} onChange={(event, format) => setToggleButtons(format)} color='eternal'>
                                                <ToggleButton value='open'>Open</ToggleButton>
                                                <ToggleButton value='pending'>Pending</ToggleButton>
                                                <ToggleButton value='closed'>Closed</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <HelpTable mail={filteredMail} listOfPlayers={listOfPlayers}/>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </fetchMailContext.Provider>
        </setSelectedTicketContext.Provider>
    )

}