import React, {useEffect, useState, useContext} from "react";
import { PlayerTable } from "../../../../components/desktop/pages/MatchViewComponents/PlayerTable";
import {Card, CardContent, Button } from '@mui/material';
import httpCommon from "../../../../http-common";
import { MobileContext } from "../../../../AppFunctional";

export const BindPlayerTable = ({mid, onClick, setBindMatchID}) => {
    const [matchData, setMatchData] = useState([]);
    const isMobile = useContext(MobileContext);
    const width = isMobile ? "100%" : "40%";

    useEffect(() => {
        const fetchMatchData = async () => {
            const {data: matchData} = await httpCommon.get("/match_data/did?mid="+mid);
            setMatchData(matchData);
        }
        if(matchData.length === 0)
            fetchMatchData().catch(console.error);
    }, [mid, matchData.length]);

    return (
        <Card style={{width, margin: "auto", height:"65vh"}}>
            <CardContent>
                <Button onClick={() => setBindMatchID(null)} color='error' size='medium' variant="contained" className="mb-2">Back</Button>
                <PlayerTable matchData={matchData} isBinding={true} onClick={onClick}/>
            </CardContent>
        </Card> 
    )
}