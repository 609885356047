import React, {useContext, useState} from "react";
import {Grid, TextField, Button} from "@mui/material";
import httpCommon from "../../../../http-common";
import { createdTeamContext } from "../TeamPageFunctional";

export const NoTeam = ({discordID}) => {
    const [newTeamName, setNewTeamName] = useState("");
    const [newTeamErr, setNewTeamErr] = useState('');
    const setCreatedTeam = useContext(createdTeamContext);

    const errors = {
        'noname': (<div style={{color: "red", fontSize: 20}}>Please Add a Team Name</div>),
        'team name is taken': (<div style={{color: "red", fontSize: 20}}>This teamname has already been taken</div>),
        'good': (<div style={{color: "green", fontSize: 20}}>Created team</div>)
    }

    const createTeam = async () => {
        if(newTeamName === "") return setNewTeamErr('noname');

        try {
            const createResp = await httpCommon.post('/teams/create', {teamname: newTeamName, captain: discordID});
            setNewTeamErr(createResp.data);
            setCreatedTeam(true);
        } catch(err){
            console.log(err);
            setNewTeamErr(err.response.data);
        }
    }

    return( 
        <Grid container spacing={5} className="mt-4 ms-auto me-auto" sx={{width: "90%"}}>
            <Grid item xs={12} textAlign={'center'}>
                <h1>You do not have a team! Please create one here:</h1>
            </Grid>
            <Grid item xs={12} textAlign={'center'}>
                <TextField
                    required
                    label="Team Name"
                    onChange={(event) => setNewTeamName(event.target.value)}
                    color='eternal'
                />
                <Button size="large" className="ms-3 p-2 mt-1" variant="outlined" color="eternal" onClick={() => createTeam()}>Add</Button>
                {
                    errors[newTeamErr] || <div style={{color: "red", fontSize: 20}}>{newTeamErr}</div>
                }
            </Grid>
        </Grid>
    )
}