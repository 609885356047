import React, { useEffect, useState, useContext } from "react";
import httpCommon from "../../../http-common";
import { VIPCard } from "./VIPCard";
import {Grid } from "@mui/material";
import { MobileContext } from "../../../AppFunctional";

export const SupportersPageFunctional = () => {

    const [vips, setVIPs] = useState([]);
    const [vipTeammates, setVIPTeammates] = useState([]);
    const isMobile = useContext(MobileContext);

    useEffect(() => {
        const fetchVIPs = async () => {
            const {data: fetchedVIPs} = await httpCommon.get("/players/vips");
            setVIPs(fetchedVIPs);
        }

        const fetchVIPTeammates = async () => {
            const {data: fetchedTeammates} = await httpCommon.get("/vip_teammates");
            setVIPTeammates(fetchedTeammates);
        }

        fetchVIPs().catch(console.error);
        fetchVIPTeammates().catch(console.error);
    }, []);

    vips.sort((a, b) => a.discordName.localeCompare(b.discordName));

    return(
        <div className = "page">
            <div className="other-page"/>
            <Grid container spacing={1} sx={{overflow: "hidden", width: "70%", marginLeft: "auto", marginRight: "auto"}}>
                <Grid item xs={12}>
                    <span className="glow"style={{fontSize: isMobile ? '2em' : '3em', fontFamily: "Orbitron"}}>Eternal Supporters</span>
                </Grid>
                <Grid item xs={12}>
                    <span className="text-light" style={{fontSize: '1.5em', fontFamily: "Orbitron"}}>Thank you from our staff and everyone in the eternal community</span>
                </Grid>
                <Grid item xs={12} style={{overflowY: "auto", overflowX: "hidden"}}>
                    <Grid container spacing={1}>
                        {
                            vips.map((vip) => {
                                const rowList = [];
                                rowList.push(
                                    <Grid item xs={isMobile ? 12 : 2}>
                                        <VIPCard vip={vip} vipTeammates={vipTeammates} />
                                    </Grid>
                                );
                                return rowList;
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}