import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper} from '@mui/material';
import React, { FC, memo } from 'react';
import { LegacyLeaderboardRow } from './LegacyLeaderboardRow.tsx';
import { PLAYER_TYPE, TEAM_TYPE } from '../../globalConsts.ts';

interface Props {
    searchText: string;
    teams: Array<TEAM_TYPE>;
}

export const LegacyLeagueTable: FC<Props> = memo(function LeaderboardTable ({searchText, teams}){
    let filterTeams = teams.filter((t) => 
        {
            if(t){
                const players = Object.values(t.players);
                const teamname = t.teamName.toLowerCase();
                const lowerFilt = searchText.toLowerCase();
                return teamname.includes(lowerFilt) || Boolean(players.find((p: PLAYER_TYPE) => p.discordName.toLowerCase().includes(lowerFilt)));
            } 
            return false;
        }
    );

    return (
        <TableContainer component={Paper} elevation={5} sx={{overflowX: "hidden", color: "white", overflowY: "scroll", height: "67vh"}} >
        <Table size="small" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell style={{textAlign: "center", fontSize: "25px"}}>Rank</TableCell>
                    <TableCell style={{textAlign: "center", fontSize: "25px"}}>Team Name</TableCell>
                    <TableCell style={{textAlign: "center", fontSize: "25px"}}>LP</TableCell>
                    <TableCell/>
                </TableRow>
            </TableHead>
            <TableBody>
                {filterTeams && filterTeams.map((t) => <LegacyLeaderboardRow team={t} rank={teams.indexOf(t) + 1}/>)}
            </TableBody>
        </Table>
    </TableContainer>
    )
});