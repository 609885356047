import React, { useContext, useEffect, useState } from "react";
import httpCommon from "../../../http-common";
import { HeaderDesktop } from "../../../components/desktop/HeaderDesktop";
import { HeaderMobile } from "../../../components/mobile/HeaderMobile";
import { NotificationFunctional } from "../Notifications/NotificationFunctional";
import { MobileContext, RefreshContext } from "../../../AppFunctional";

export const HeaderFunctional = () => {
    const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
    const loggedIn = JSON.parse(localStorage.getItem("loggedIn"));

    const [profileLink, setProfileLink] = useState("/");
    const [discordID, setDiscordID] = useState(-1);
    const [avatar, setAvatar] = useState("");
    const [avatarImage, setAvatarImage] = useState("");
    const [username, setUsername] = useState("");
    const [showNotifications, setShowNotifications] = useState(false);

    const [bound, setBound] = useState(false);
    const [loading, setLoading] = useState(true);
    const [invites, setInvites] = useState([]);
    const [checkinOpen, setCheckinOpen] = useState(false);
    const [permLevel, setPermLevel] = useState(0);
    const [listOfPlayers, setListOfPlayers] = useState([]);

    const [numOpenTickets, setNumOpenTickets] = useState(0);

    const isMobile = useContext(MobileContext);
    const refreshToggle = useContext(RefreshContext);

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    }


    if(loggedIn && (profileLink === "/" || discordID === -1 || avatar === "" || username === "")){
        setProfileLink("/player/"+loginInfo.username);
        setDiscordID(loginInfo.id);
        setAvatar(loginInfo.avatar);
        setUsername(loginInfo.username);
    }

    
    useEffect(() => {
        const fetchBound = async () => {
            const {data: player} = await httpCommon.get("/Players/name?discordName=" + username);
            setBound(player.bound);
            await fetchPerms().catch(console.error);
            await fetchAvatar().catch(console.error);
            await fetchInvites().catch(console.error);
            await fetchCheckin().catch(console.error);
            await fetchListOfPlayers().catch(console.error);
            setLoading(false);
        }

        const fetchPerms = async () => {
            const {data: {permLevel}} = await httpCommon.get("/eternalAccounts/perms");
            setPermLevel(permLevel);
            if(permLevel > 2) await fetchOpenTickets();
        }

        const fetchOpenTickets = async () => {
            const {data: {numTickets}} = await httpCommon.get('/help/num');
            setNumOpenTickets(numTickets);
        }

        const fetchAvatar = async () => {
            const avatarResponse = await fetch("https://cdn.discordapp.com/avatars/" + discordID + "/" + avatar);
            const imgBlob = await avatarResponse.blob();
            const imageURL = URL.createObjectURL(imgBlob);
            setAvatarImage(imageURL);
        }

        const fetchInvites = async () => {
            const {data: {invites: myInvites}} = await httpCommon.get("teams/myinvites?discordID="+discordID);
            setInvites(myInvites);
        }

        const fetchCheckin = async () => {
            const {data: {isOpen: checkin}} = await httpCommon.get("/checkin/isopen");
            setCheckinOpen(checkin);
        }

        const fetchListOfPlayers = async () => {
            const {data: listOfPlayers} = await httpCommon.get("/eternalAccounts/discordMembers");
            setListOfPlayers(listOfPlayers);
        }

        fetchBound().catch(console.error);
    }, [refreshToggle, avatar, discordID, username]);

    return (
        <>
            <NotificationFunctional discordID={discordID} listOfPlayers={listOfPlayers} showNotifications={showNotifications}/>
            {
                isMobile ? 
                <HeaderMobile 
                    username={username} bound={bound} 
                    loading={loading} loggedIn={loggedIn} 
                    invites={invites} permLevel={permLevel}
                    avatarImage={avatarImage} checkinOpen={checkinOpen}
                    showNotifications={showNotifications} toggleNotifications={toggleNotifications}
                    numOpenTickets={numOpenTickets}
                />
            : 
                <HeaderDesktop 
                    username={username} bound={bound} 
                    loading={loading} loggedIn={loggedIn} 
                    invites={invites} permLevel={permLevel}
                    avatarImage={avatarImage} checkinOpen={checkinOpen}
                    showNotifications={showNotifications} toggleNotifications={toggleNotifications}
                    numOpenTickets={numOpenTickets}
                />
            }
        </>
    )
}