
export const ETERNAL_GREEN = '#0FFF95';
export const ETERNAL_YELLOW = '#FFE342';
export const ETERNAL_RED = '#E81313';
export const ETERNAL_PINK = '#FE7070';

export const LEGACY_BACKGROUND = '#6E7581FF'

export type PLAYER_TYPE = {
    IGN: string;
    lowprio: boolean;
    LeaguePoints: number;
    MMR: number;
    VIP: boolean;
    discordID: number | string;
    discordName: string;
    lastMMRChange: number;
    lastTeam: string;
    startingMMR: number;
}

export type TEAM_TYPE = {
    teamName: string;
    teamLP: number;
    players: PLAYER_TYPE[];
}

export type DISCORD_INFO_TYPE = {
    accent_color: number;
    avatar: string;
    avatar_decoration_data: any | null;
    banner: any | null;
    banner_color: string | null;
    clan: any | null;
    discriminator: string | number | null;
    email?: string;
    flags: number;
    global_name: string | null;
    id: number | string;
    locale: string;
    mfa_enabled: boolean;
    premium_type: number;
    public_flags: number;
    username: string;
    verified: boolean;
}

export type CONNECTION_TYPE = {
    friend_sync: boolean;
    id: string;
    metadata_visibility: number;
    name: string;
    show_activity: boolean;
    two_way_link: boolean;
    type: string;
    verified: boolean;
    visibility: number;
}

export type TRACK_MMR_TYPE = {
    matchMMR: number;
    match_start: number;
    match_add?: number;
    mid: string;
}

export type MATCH_DATA_TYPE = {
    assists?: number;
    characterName: string;
    damageDealt: number;
    hardware: string;
    headshots: number;
    hits: number;
    kills: number;
    knockdowns: number;
    mid: string;
    playerName: string;
    revivesGiven: number;
    shots: number;
    survivalTime: number;
    teamName: string;
    teamNum: number;
    teamPlacement: number;
}

export const SEASON_NAME_MAP = {
    'Season1': 'Season 1',
    'LegacyLeague': 'Legacy League 1',
    'legacyLeague': 'Legacy League 1',
    'current': 'Season 2 (Current)'
}

export const SEASON_NAME_MAP_2 = {
    'Season1': 'Season1',
    'legacyLeague': 'LegacyLeague',
    'LegacyLeague': 'LegacyLeague',
    'Legacy League 1': 'LegacyLeague',
    'current': 'Season 2 (Current)'
}

export const PLACEMENT_POINTS = {1: 12, 2: 9, 3: 7, 4: 5, 5: 4, 
    6: 3, 7: 3, 8: 2, 9: 2, 10: 2, 
    11: 1, 12: 1, 13: 1, 14: 1, 15: 1, 
    16: 0, 17: 0, 18: 0, 19: 0, 20: 0};