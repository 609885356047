import React, { useContext, useEffect, useState } from "react";
import httpCommon from "../../../../../http-common";
import { Box, Grid } from '@mui/material';
import { TeamCard } from "./TeamCard";
import { useNavigate } from "react-router";
import { SelectedTeam } from "./SelectedTeam";
import { Loading } from "../../../MiscComponents/Loading";
import { MobileContext } from "../../../../../AppFunctional";

export const Teams = () => {
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);

    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(params.get('team') || null);
    const [loading, setLoading] = useState(true);

    const isMobile = useContext(MobileContext);

    // clears params and state when using back button
    if(!params.get('team') && selectedTeam !== null)
        setSelectedTeam(null);

    const fetchTeams = async () => {
        const {data: {fullTeams: fetchedTeams}} = await httpCommon.get('/teams/allteams');
        setTeams(fetchedTeams);
        setLoading(false);
    }

    useEffect(() => {
        fetchTeams().catch(console.error);
    }, []);

    const teamCardOnClick = (teamname) => {
        setSelectedTeam(teamname);
        params.set('team', teamname);
        navigate('/adminpanel?'+params);
    }

    return (
        <Box sx={{height: '72vh', overflowY: 'scroll'}} className="mt-2">
            {
                !Boolean(selectedTeam) ? ( 
                    <Grid container spacing={2}>
                        {
                            teams && teams.map(team => {
                                if(team) return (
                                    <Grid item xs={isMobile ? 6 : 2} className='select' onClick={() => {teamCardOnClick(team.team.teamname)}} >
                                        <TeamCard team={team} />
                                    </Grid>
                                );
                                else return ""
                            })
                        }
                    </Grid>
                ) : (
                    loading ? 
                        <Loading /> 
                    :
                    (
                        <SelectedTeam team={teams.filter(t => t.team.teamname === selectedTeam)[0]} setSelectedTeam={setSelectedTeam}/>
                    )
                )
            }
        </Box>

    )
}