import React, {useDeferredValue, useEffect, useState, FC, useContext} from "react";
import http from "../../../http-common";
import { Loading } from "../MiscComponents/Loading";
import { Card, CardContent, Grid, TextField } from "@mui/material";
import { MobileContext, RefreshContext } from "../../../AppFunctional";
import { LegacyLeagueTable } from "./LegacyLeagueTable.tsx";

export const LegacyLeaderboardCard: FC<{}> = (): JSX.Element => {
    const [teams, setTeams] = useState<[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    const deferredSearchText = useDeferredValue<string>(searchText);
    const isStale = searchText !== deferredSearchText;

    const isMobile = useContext(MobileContext);
    const width = isMobile ? '100%' : '40%';

    const shouldRefresh = useContext(RefreshContext);

     // component did mount
    useEffect(() => {
        const fetchData = async () => {
            const {data: teams} = await http.get('/players/legacy');
            setTeams(teams);
            setLoading(false);
        }
        fetchData().catch(console.error);
    }, [shouldRefresh]);

    // is loading
    if(loading) return <Loading/>

    return (
        <Card sx={{borderRadius: 5, width, margin: "auto", height:"75vh"}}>
            <CardContent id="leaderboardPane" className = "select">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* @ts-ignore */}
                        <TextField fullWidth color='eternal' label={isStale ? 'loading' : 'Team / Player Search'} variant='outlined' size='small' onChange={(event) => setSearchText(event.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <LegacyLeagueTable searchText={deferredSearchText} teams={teams}/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}