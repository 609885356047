import React from "react";
import {Typography} from '@mui/material';

export const NotLoggedInPage = () => {
    return (
        <div className="page">
            <div className="other-page"/>
            <Typography textAlign='center' fontSize='3em' marginTop={'25vh'} className="glow text-light">
                Log into an Admin account to access
            </Typography>
        </div>
    )
}