let socket = undefined;
let rerenderFunction = undefined;

const createWebSocket = (discordID, rerender) => {
    const header = process.env.REACT_APP_BACKEND;    
    // Connect to server
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    const id = loginInfo ? loginInfo.id : -1;
    socket = new WebSocket("wss://"+header+".eternalesports.club/ws/?discordID="+ id + "&apiKey=" + localStorage.getItem('apiKey'));
    rerenderFunction = rerender;
    socket.addEventListener('message', (event) => parseMessage(event.data));
    socket.addEventListener('close', () => {
        setTimeout(function() {
            createWebSocket(discordID, rerender);
        }, 1000);
    });
    socket.addEventListener('error', (event) => console.log("socket error: ", event));
}

function socketSend(text){
    if(!socket) return 
    waitForConnection(() => {
        socket.send(JSON.stringify(text));
    }, 1000);
}

const waitForConnection = (callback, interval) => {
    if(socket.readyState === 1){
        callback();
    } else {
        console.log("ws not ready: waiting")
        setTimeout(() => {
            waitForConnection(callback, interval);
        }, interval);
    }
}

async function parseMessage(text){
    try {
        const json = JSON.parse(text);

        // refresh me
        if(json.code === 1){
            rerenderFunction();
        }

    } catch(err){
        console.log(err);
        console.log(text);
    }
}

module.exports = {createWebSocket, socketSend}