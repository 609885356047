import React, { useState } from "react";
import {Grid, Card, CardContent, Tabs, Tab, TableContainer, Paper} from "@mui/material";
import { TeamTable } from "./MatchViewComponents/TeamTable";
import { PlayerTable } from "./MatchViewComponents/PlayerTable";
import { timeConverter, mapName } from "../../../common/pubFuncs";
import { ETERNAL_GREEN } from "../../../common/globalConsts.ts";

export const MatchViewPageDesktop = ({match, matchData}) => {

    const [currentTab, setCurrentTab] = useState("team");

    return (
        <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign: "center", fontSize: '3em', fontFamily: "Orbitron"}} className="glow">
                    {timeConverter(match.match_start) + " " + mapName(match.map_name)}
                </Grid>
                <Grid item xs={12}>
                    <Card sx={{ width: "60%"}} className="ms-auto me-auto">
                        <CardContent sx={{height: "75vh"}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Tabs value={currentTab} onChange={(event, value) => setCurrentTab(value)} textColor="eternal" TabIndicatorProps={{style: {
                                        backgroundColor: ETERNAL_GREEN, height: 3
                                    }}}>
                                        <Tab label="Team Scores" value="team"/>
                                        <Tab label = "Player Scores" value = "player"/>
                                    </Tabs>
                                </Grid>
                                <Grid item xs={12}>   
                                    {
                                        currentTab === "team" ?
                                            <TableContainer component={Paper} elevation={5} sx={{overflowY: "auto", overflowX: "auto",  height:"65vh"}}>
                                                <TeamTable matchData={matchData} />
                                            </TableContainer>
                                        :
                                        <PlayerTable matchData={matchData} />
                                    }
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
    )
}