import React, { useState } from "react";
import {Grid } from "@mui/material";
import { TeamPageHeader } from "../../../common/components/TeamPage/TeamPageOptions/TeamPageHeader";
import { NoTeam } from "../../../common/components/TeamPage/TeamPageOptions/NoTeam";
import { AddPlayerModal } from "../../../common/components/TeamPage/TeamPageOptions/AddPlayerModal";
import { TeammatesTable } from "../../../common/components/TeamPage/TeamPageOptions/TeammatesTable";
import { PlayerCard } from "../../../common/components/TeamPage/TeamPageOptions/PlayerCard";

export const TeamPageMobile = ({team, players, discordID, isCaptain, fetchTeam, listOfPlayers, sentInvites, onPrivate}) => {

    const [showPlayerModal, setShowPlayerModal] = useState(false);

    if(!team){
        return <NoTeam discordID={discordID}/>;
    }

    return (
        <Grid container spacing={3} className="mt-4" sx={{width: "100%"}}>
            <Grid item xs={12}>
                <TeamPageHeader team={team} players={players}/>
            </Grid>
            <Grid item xs={12} className="mb-1">
                <AddPlayerModal open={showPlayerModal} onClose={() => setShowPlayerModal(false)} teammates={players} 
                    listOfPlayers={listOfPlayers} team={team} sentInvites={sentInvites} discordID={discordID}/>
                <TeammatesTable team={team} players={players} isCaptain={isCaptain} discordID={discordID} 
                    fetchTeam={fetchTeam} setShowPlayerModal={setShowPlayerModal} sentInvites={sentInvites} 
                    listOfPlayers={listOfPlayers} onPrivate={onPrivate} isMobile={true}/>
            </Grid>
            <Grid item xs={12}>
                <PlayerCard players={players}/>
            </Grid>
        </Grid>
    );
}