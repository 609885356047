import React, { FC, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { ETERNAL_GREEN } from '../../../globalConsts.ts';
import { MobileContext } from '../../../../AppFunctional.js';

export const LeagueBlurb: FC<{height: string | number, className?: string}> = ({height, className}): React.ReactNode => {

    const isMobile = useContext<boolean>(MobileContext);

    return (
        <Box height={height} alignContent='center' className={className}>
            <Typography fontSize={isMobile ? 30 : 45} color='white'>we are so back<Typography component='span' color={ETERNAL_GREEN}>.</Typography></Typography>
            <Box height={ isMobile? '1vh' : '8vh'}/>
            <Typography color='white' fontSize={isMobile? 15 : 35} className='ms-auto me-auto' width={isMobile ? '100%' : '50%' }>eternal legacy league is the most competitive community-led league format tournament for cc players<Typography component='span' color={ETERNAL_GREEN}>.</Typography> Compete against the top talent outside PL in a high stakes battle to the top<Typography component='span' color={ETERNAL_GREEN}>.</Typography></Typography>
            <Box height={ isMobile? '1vh' : '8vh'}/>
            <Typography color='white' fontSize={isMobile? 15 : 35} className='ms-auto me-auto' width={isMobile ? '100%' : '50%' }>Interested<Typography component='span' color={ETERNAL_GREEN}>?</Typography></Typography> 
            <Typography color='white' fontSize={isMobile? 15 : 35} className='ms-auto me-auto' width={isMobile ? '100%' : '50%' }>Check out the info below to get started<Typography component='span' color={ETERNAL_GREEN}>.</Typography></Typography>
        </Box>
    )
}