import React, { FC, useContext, useEffect, useState } from "react";
import httpCommon from "../../../../http-common";
import {Card, CardHeader, CardContent, Grid, Box} from "@mui/material";
import { mmrToRankIMG } from "../../../pubFuncs";
import { useNavigate } from "react-router";
import { playerIDContext } from "../PlayerPageFunctional.tsx";
import { colorContext } from "../BoundPage.tsx";

export const TeammatesCard: FC<{}> = () => {
    const [teammateIDList, setTeammateIDList] = useState([]);
    const [teammateList, setTeammateList] = useState([]);
    const [teamname, setTeamName] = useState("");
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const discordID = useContext<number | string>(playerIDContext);
    const [color, ] = useContext<[string, Function]>(colorContext);

    useEffect(() => {
        const fetchTeammates = async () => {
            const {data: fetchedTeam} = await httpCommon.get("/teams/discordID?discordID="+discordID);
            const fetchedIDList = fetchedTeam.teammates.map(p => p.player);
            setTeamName(fetchedTeam.team.teamname);
            const captainID = fetchedTeam.team.captain;
            // add captain to team
            fetchedIDList.push(captainID);

            // remove looking at player from team
            const filtedIDList = fetchedIDList.filter(id => id !== discordID);
            setTeammateIDList(filtedIDList);
        }
        if(teammateIDList.length === 0)
            fetchTeammates().catch(console.error);
    }, [discordID, teammateIDList.length]);

    useEffect(() => {
        const fetchTeammateInfo = async (playerID: string | number) => {
            const {data: {leaderboardPosition: position}} = await httpCommon.get("/players/position?discordID="+playerID);
            const {data: fetchedPlayer} = await httpCommon.get("/players/id?discordID="+playerID);

            const currentTeammateList = teammateList;
            const currentPlayer = fetchedPlayer;
            currentPlayer.position = position;
            if(!currentTeammateList.map(p => p.discordID).includes(currentPlayer.discordID))
                currentTeammateList.push(currentPlayer);
            setTeammateList(currentTeammateList);
        }

        const fetchAllTeammateInfo = async () => {
            if(teammateIDList.length > 0){
                const promiseList = teammateIDList.map(playerID => fetchTeammateInfo(playerID).catch(console.error));
                await Promise.all(promiseList);
                setLoading(false);
            }
        }
        fetchAllTeammateInfo().catch(console.error);
    }, [teammateIDList, teammateList])

    if(loading){
        return ""
    }
    return (
        <Card sx={{borderRadius:5, border: color + " 2px solid"}}>
            <CardHeader 
                className="select"
                titleTypographyProps={{
                    fontFamily: "Orbitron",
                    fontSize: 25
                }}
                onClick={() => window.location.href = "/team/"+teamname}
                title={"Team: " + teamname}
            />
            <CardContent sx={{display: "flex", flexWrap: "wrap"}}>
                <Grid container spacing={2}>
                    {
                        teammateList.map(teammate => {
                            const discordName = teammate.discordName;
                            const MMR = teammate.MMR;
                            const position = teammate.position;
                            const tierIMG = mmrToRankIMG(MMR, position);
                            return (
                                <Grid item className="mb-2">
                                    <Box sx={{width: 150, display: "flex", flexDirection: "column"}}>
                                        <img style={{width: "100px", marginLeft: "auto", marginRight: "auto"}} src={tierIMG} alt = "" className = 'img-fluid select mt-2' key={"teammate: " + discordName} 
                                            onClick={() =>  navigate('../player/'+discordName)} />
                                        <div className="mb-2" style={{margin: "auto", overflow: "hidden", textWrap: "nowrap", width: "150px", textAlign: "center" }}>
                                            {discordName}
                                        </div>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}