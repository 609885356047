import React, { useEffect, useMemo, useState } from "react";
import {TableCell, ToggleButtonGroup, ToggleButton} from '@mui/material';
import httpCommon from "../../../../../../http-common";

export const CrudPermissionsCell = ({account}) => {
    const valueList = useMemo(() => {return ['Create', 'Read', 'Update', 'Destroy']},[]);

    const [disabled, setDisabled] = useState(false);
    const [crudList, setCrudList] = useState(null);
    const [crudPerms, setCrudPerms] = useState(account.crudPerms ? account.crudPerms.split("") : []);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handleCrudList = (newPerms) => {
            let i = 0;
            const tempList = [];
            newPerms.forEach(perm => {
                if(perm === 'Y')
                    tempList.push(valueList[i]);
                i++;
            });
            setCrudList(tempList);
        }

        handleCrudList(crudPerms);
        setLoading(false);
    },[account, crudPerms, valueList])

    const handleClick = async (event, newFormats) => {
        setDisabled(true);
        let newPerms = "NNNN";
        newFormats.forEach(perm => {
            const index = valueList.indexOf(perm);
            newPerms = newPerms.substring(0, index) + 'Y' + newPerms.substring(index+1, newPerms.length)
        });
        
        await httpCommon.post("/eternalAccounts/perms", {apiKey: account.apiKey, newPerms});
        setCrudPerms(newPerms.split(""));
        setDisabled(false);
    }
    
    if(!account.crudPerms) return <TableCell></TableCell>
    if(loading) <TableCell/>

    return (
        <TableCell colSpan={2}>
            <ToggleButtonGroup
                color='eternal'
                value={crudList}
                onChange={handleClick}
            >
                <ToggleButton value='Create' disabled={disabled}>
                    C
                </ToggleButton>
                <ToggleButton value='Read' disabled={disabled}>
                    R
                </ToggleButton>
                <ToggleButton value='Update' disabled={disabled}>
                    U
                </ToggleButton>
                <ToggleButton value='Destroy' disabled={disabled}>
                    D
                </ToggleButton>
            </ToggleButtonGroup>
        </TableCell>
    )

}