import React, { useContext, useEffect, useCallback } from "react";
import {useSnackbar} from 'notistack';
import httpCommon from "../../../http-common";
import { NotificationAcceptButton } from "./NotificationAcceptButton";
import { NotificationCloseButton } from "./NofiticationCloseButton";
import { MobileContext } from "../../../AppFunctional";

export const NotificationFunctional = ({discordID, listOfPlayers, showNotifications}) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const isMobile = useContext(MobileContext);

    const queueInvites = useCallback((invites) => {
        const invitesToQueue = invites.splice(0, isMobile ? 1 : 4);
        invitesToQueue.forEach((invite) => {
            const listFilter = listOfPlayers.filter(player => player[0] === invite.sender)[0];
            const sender = listFilter ? listFilter[1] : "";
            const message = "You have been invited to team " + invite.teamname + " by " + sender;
            const action = () => (
                <>
                    <NotificationAcceptButton invite={invite} fetchInvites={fetchInvites} closeSnackbar={closeSnackbar}/>
                    <NotificationCloseButton invite={invite} fetchInvites={fetchInvites} closeSnackbar={closeSnackbar}/> 
                </>
            );
            if(showNotifications)
                enqueueSnackbar(message, {
                    key: invite.id,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    persist: true,
                    action: action
                });
        });
    // eslint-disable-next-line
    }, [closeSnackbar, enqueueSnackbar, isMobile, listOfPlayers, showNotifications]);

    const fetchInvites = useCallback(async () => {
        const {data: {invites: myInvites}} = await httpCommon.get("teams/myinvites?discordID="+discordID);
        queueInvites(myInvites);
    }, [discordID, queueInvites]);

    useEffect(() => {
        if(!showNotifications)
            closeSnackbar();

        if(discordID !== -1){
            fetchInvites().catch(console.error);
        }
    },[discordID, showNotifications, closeSnackbar, fetchInvites]);
}