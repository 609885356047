import React from "react";
import {Grid, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import { TokenRow } from "./TokenRow";

export const TokenTable = ({tokens, fetchTokens}) => {

    const sortedTokens = tokens.sort((x, y) => (x.active === y.active)? 0 : x.active? -1 : 1);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TableContainer sx={{height: "65vh"}} component={Paper} elevation={5} >
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>Stat Token</TableCell>
                                <TableCell>Active</TableCell>
                                <TableCell>Autopolling</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedTokens && sortedTokens.map((token, index) => <TokenRow token={token} index={index + 1} fetchTokens={fetchTokens}/>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}