import { useCallback } from "react";

export const useCopyToClipboard = () => {
    const copy = useCallback((text) => {
        if(navigator.clipboard){
            navigator.clipboard.writeText(text)
            .catch((err) => {
                console.log(err);
            });
        }
    }, []);

    return copy;
}