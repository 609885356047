import React, { useState } from "react";
import {Dialog, DialogTitle, DialogContent, Paper, Autocomplete, TextField} from "@mui/material";
import { TimeoutButton } from "../../Buttons/TimeoutButton";
import httpCommon from "../../../../http-common";

export const AddPlayerModal = ({open, onClose, teammates, sentInvites, listOfPlayers, team, discordID}) => {
    const [selectedPlayers, setSelectedPlayers] = useState([]);

    const listMinusMe = listOfPlayers.filter(p => p[0] !== discordID);

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true}>
            <DialogTitle>
                Add Players
            </DialogTitle>
            <DialogContent>
                <Paper elevation={2}>
                    <Autocomplete
                        multiple
                        size="small"
                        options={listMinusMe.map(member => member[1])}
                        getOptionDisabled={() => (teammates.length + selectedPlayers.length + sentInvites.length) >= 5 ? true : false}
                        renderInput={(params) => <TextField {...params} label="Discord Name" color="eternal"/>}
                        id="Autocomplete"
                        onChange={(event, value) => setSelectedPlayers(value)}
                        className="m-2 p-2"
                    />
                    <TimeoutButton 
                        onClick={async () => {
                            setSelectedPlayers([]);
                            const addPlayers = selectedPlayers.map(player => {
                                const playerList = listMinusMe.filter((member) => player === member[1]);
                                const retPlayer = {id: playerList[0][0], name: playerList[0][1]}
                                return retPlayer;
                            });

                            for(const index in addPlayers){
                                const player = addPlayers[index];
                                try {
                                    await httpCommon.post('/teams/createInvite', {teamname: team.team.teamname, sender: team.team.captain, reciever: player.id});
                                } catch(err) {
                                    console.log(err);
                                }
                            }
                            onClose();
                        }}
                    >Add</TimeoutButton>
                </Paper>
            </DialogContent>
        </Dialog>
    );
}