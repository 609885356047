import React, { useContext, useEffect, useState } from "react";
import httpCommon from "../../../http-common";
import { MatchViewPageDesktop } from "../../../components/desktop/pages/MatchViewPageDesktop";
import { MatchViewPageMobile } from "../../../components/mobile/pages/MatchViewPageMobile";
import { MobileContext } from "../../../AppFunctional";
import { useHref } from "react-router";

export const MatchViewPageFunctional = () => {
    const matchID = useHref().replace("/matches/id/", "");
    
    const [match, setMatch] = useState([]);
    const [matchData, setMatchData] = useState([]);
    const isMobile = useContext(MobileContext);

    useEffect(() => {
        const fetchMatch = async () => {
            const {data: match} = await httpCommon.get("/matches/id?mid="+matchID);
            setMatch(match);
        }

        const fetchMatchData = async () => {
            const {data: matchData} = await httpCommon.get("/match_data/did?mid="+matchID);
            setMatchData(matchData);
        }
        if(matchData.length === 0)
            fetchMatch().then(fetchMatchData()).catch(console.error);
    }, [matchID, matchData.length]);

    if(isMobile){
        return (
        <div>
            <div className = "other-page"/>
            <MatchViewPageMobile match={match} matchData={matchData} />
        </div>
        )

    }

    return (
        <div>
            <div className = "other-page"/>
            <MatchViewPageDesktop match={match} matchData={matchData} />
        </div>
    );
}