import React from "react";
import {Dialog, DialogTitle, DialogActions} from '@mui/material';
import { TimeoutButton } from "../../Buttons/TimeoutButton";

export const ConfirmationDialog = ({open, text, onClick, hasDeny}) => {
    return (
        <Dialog open={open} onClose={() => onClick()}>
            <DialogTitle fontSize={30}>{text}</DialogTitle>
            <DialogActions>
                <TimeoutButton color='eternal' onClick={() => onClick()}>Thanks</TimeoutButton>
                {hasDeny ? <TimeoutButton color='error'>No Thanks</TimeoutButton> : ''}
            </DialogActions>
        </Dialog>
    )
}