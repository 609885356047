import React, { useEffect, useState } from "react";
import httpCommon from "../../../../../http-common";
import {Grid, Box} from '@mui/material';
import { CheckinDayCard } from "./CheckinDayCard";
import { useNavigate } from "react-router";
import { CheckinTable } from "./CheckinTable";
import { Loading } from "../../../MiscComponents/Loading";

export const Checkin = () => {
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);

    const [checkins, setCheckins] = useState([]);
    const [day, setDay] = useState(params.get('day') || null);
    const [loading, setLoading] = useState(true);

    // clears params and state when using back button
    if(!params.get('day') && day !== null)
        setDay(null);
    
    const fetchCheckins = async () => {
        const {data: checkins} = await httpCommon.get('/checkin');
        setCheckins(checkins);
        setLoading(false);
    }
    
    useEffect(() => {
        fetchCheckins().catch(console.error);
    }, []);

    if(loading) return <Loading />

    const daysMap = new Map();
    checkins.forEach(check => {
        const date = new Date(check.checkinDate * 1000);
        const timeKey = date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });

        if(!daysMap.get(timeKey)) {
            daysMap.set(timeKey, [check]);
        } else {
            const currentTeams = daysMap.get(timeKey);
            daysMap.set(timeKey, [...currentTeams, check]);
        }
    });

    const cardOnClick = (key) => {
        setDay(key);
        params.set('day', key);
        navigate('.?'+params);
    }

    const keys = Array.from(daysMap.keys());
    keys.reverse();

    // const open = async () => {
    //     console.log(await httpCommon.post('/checkin/open/', ['7pm', '1030pm']));
    // }

    // const close = async () => {
    //     console.log(await httpCommon.post('/checkin/close', ['7pm', '1030pm']))
    // }

    return (
            day === null ? (
                <Box sx={{height: '72vh', overflowY: 'scroll'}}>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12}>
                            <TimeoutButton color='eternal' onClick={() => open()}>Open Check Test</TimeoutButton>
                            <TimeoutButton color='eternal' onClick={() => close()}>Close Check Test</TimeoutButton>
                        </Grid> */}
                        {
                            keys.map(key => {
                                if(key) return (
                                    <Grid item xs={2} className='select' onClick={() => cardOnClick(key)}>
                                        <CheckinDayCard checkinDay={daysMap.get(key)} dateKey={key} /> 
                                    </Grid>
                                );
                                else return ""
                            })
                        }
                    </Grid>
                </Box>
            ) 
                : 
                <CheckinTable checkinDay={daysMap.get(day)} dateKey={day} fetchCheckins={fetchCheckins} />
            
    )
}