import React, { FC } from "react"
import { LLBox } from "./LLBox.tsx"
import { Typography, List, ListItem, Link } from '@mui/material';
import { ETERNAL_GREEN, ETERNAL_PINK } from "../../../globalConsts.ts";
import { StaticLogo } from "../../../../images/imgIndex";

interface Props {
    className?: string;
    width?: number | string;
    fontSize?: number;
}

export const LLLogoBox: FC<Props> = ({className, width, fontSize}) : JSX.Element => {
    width = width || '100%';
    fontSize = fontSize || 20;

    return (
        <LLBox className={className} width={width}>
            <Typography className='me-3 mt-auto mb-auto ms-3' color='white' textAlign='left' fontSize={fontSize}>
                <List sx={{listStyleType: 'disc', listStylePosition: 'inside', '& .MuiListItem-root': {display: 'list-item', padding: 0, margin: 0, marginLeft: 1}}}>
                    <ListItem><Typography component='span' color={ETERNAL_GREEN}>Top 20</Typography> captains from <Typography component='span' color={ETERNAL_GREEN}>Split 2 NA CC after CC2</Typography> have <Typography component='span' color={ETERNAL_GREEN}>free access</Typography></ListItem>
                    <ListItem><Typography component='span' color={ETERNAL_GREEN}>All</Typography> EEC <Typography component='span' color={ETERNAL_GREEN}>VIPs</Typography> have <Typography component='span' color={ETERNAL_GREEN}>free access</Typography></ListItem>
                    <ListItem><Typography component='span' color={ETERNAL_GREEN}>Buy</Typography> your <Typography component='span' color={ETERNAL_GREEN}>ticket</Typography> or <Typography component='span' color={ETERNAL_GREEN}>VIP</Typography> in the <Link href='https://discord.com/channels/1031340622692499506/shop' color={ETERNAL_PINK}>Eternal Shop</Link> today!</ListItem>
                    <ListItem>The <Typography component='span' color={ETERNAL_GREEN}>above methods</Typography> grant access to <Typography component='span' color={ETERNAL_GREEN}>all 26 qualifiers</Typography></ListItem>
                </List>
                Players who <Typography component='span' color={ETERNAL_GREEN}>meet 1 or more</Typography> of the above criteria are referred to as <Typography component='span' color={ETERNAL_GREEN}>"Captain"</Typography>
            </Typography>
        </LLBox>
    )

} 
