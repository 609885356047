import React from "react";
import {TableRow, TableCell, Checkbox} from '@mui/material';
import { Cancel, CheckCircle } from "@mui/icons-material";
import { ConfirmationButton } from "../../../Buttons/ConfirmationButton";
import httpCommon from "../../../../../http-common";
import { ETERNAL_GREEN } from "../../../../globalConsts.ts";

export const TokenRow = ({index, token, fetchTokens}) => {
    const toggle = async (button) => {
        const autopoll = button === 'autopoll' ? !token.autopoll : token.autopoll;
        const active = button === 'active' ? !token.active : token.active;

        await httpCommon.post('/statTokens/token', {statToken: token.statToken, autopoll, active})
        fetchTokens();
    }

    const deleteToken = async () => {
        await httpCommon.delete('/statTokens/token?statToken='+token.statToken);
        fetchTokens();
    }

    return (
        <TableRow>
            <TableCell>{index}</TableCell>
            <TableCell>{token.statToken}</TableCell>
            <TableCell>
                <Checkbox 
                    checked={token.active}
                    icon={<Cancel fontSize="large" color="warning" />} 
                    checkedIcon={<CheckCircle fontSize="large" color="eternal" />}
                    onClick={() => toggle('active')}
                />
            </TableCell>
            <TableCell>
                <Checkbox 
                    checked={token.autopoll}
                    icon={<Cancel fontSize="large" color="warning" />} 
                    checkedIcon={<CheckCircle fontSize="large" color="eternal" />}
                    onClick={() => toggle('autopoll')}
                />
            </TableCell>
            <TableCell>
                <ConfirmationButton text="Delete" color='error' onClick={() => deleteToken()} variant="contained">
                    Are you sure you want to delete<br/><span style={{color: ETERNAL_GREEN}}>{token.statToken}</span>?
                </ConfirmationButton>
            </TableCell>
        </TableRow>
    )
}