import React from "react"
import {Typography} from '@mui/material';
import { Link } from "react-router-dom";
import { ETERNAL_GREEN } from "../../globalConsts.ts";

export const HelpPageLogin = () => {
    return (
        <div className="page">
            <div className="other-page"/>
            <Typography textAlign='center' fontSize='3em' marginTop={'20vh'} className="glow text-light">
                Please log in to Discord to submit a help request.
                <Typography fontSize='1em'>Or you could check out our <Link to='/faq' style={{color: ETERNAL_GREEN}}>FAQ Page</Link></Typography>
            </Typography>
        </div>
    )
}