import React, { FC, useContext, useEffect, useState } from "react";
import {Card, CardHeader, CardContent} from "@mui/material"
import httpCommon from "../../../../http-common";
import { mmrToRankIMG } from "../../../pubFuncs";
import { leaguePointsContext, playerIDContext } from "../PlayerPageFunctional.tsx";
import { colorContext } from "../BoundPage.tsx";
import { LegacyLeagueLargeBadge } from "../../../../images/imgIndex.js";

interface Props {
    currentMMR: number;
    season: string | null;
}

export const RankCard: FC<Props> = ({currentMMR, season}) => {
    const [lbPosition, setLBPosition] = useState<number>(-1);
    const discordID = useContext<number | string>(playerIDContext);
    const [color, ] = useContext<[string, Function]>(colorContext);
    const leaguePoints = useContext<number>(leaguePointsContext);

    useEffect(() => {
        const fetchLeaderboardPosition = async () => {
            const {data: leaderboardPosition} = await httpCommon.get("/players/position?discordID="+discordID);
            setLBPosition(leaderboardPosition.leaderboardPosition);
        }
        fetchLeaderboardPosition().catch(console.error);
    }, [discordID]);

    let imgSRC = mmrToRankIMG(currentMMR, lbPosition);
    let showMMR = currentMMR;
    if(season === 'LegacyLeague') {
        imgSRC = LegacyLeagueLargeBadge;
        showMMR = leaguePoints;
    }

    return(
        <Card sx={{borderRadius: 5, border: color + " 2px solid", height: "100%"}}>
            <CardHeader 
                titleTypographyProps={{
                    fontFamily: "Orbitron",
                    fontSize: 30
                }}
                title={season === 'LegacyLeague' ? 'League Points' : "Tier"}
            />
            <CardContent sx={{display: "flex", flexDirection: "column"}}>
                <img src={imgSRC} alt="" className='img-fluid mb-1' style={{width: "50%", margin: "auto"}}/>  
                <span className="mb-1" style={{textAlign: "center", fontSize: "1.2em", textShadow: "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000"}}>
                    {showMMR}
                </span>  
            </CardContent>
        </Card>
    );
}