import React, { FC, useEffect, useState, createContext } from "react";
import httpCommon from "../../../http-common";
import { UnBoundPage } from "./UnboundPage";
import { BoundPage } from "./BoundPage.tsx";
import { Loading } from "../MiscComponents/Loading";
import { useLocation } from "react-router";
import { DISCORD_INFO_TYPE } from "../../globalConsts.ts";

export const playerDiscordContext = createContext<string>('');
export const playerIDContext = createContext<number | string>(-1);
export const setJustBoundContext = createContext<Function | null>(null);
export const permLevelContext = createContext<number>(0);
export const onUserProfileContext = createContext<boolean>(false);
export const isVIPContext = createContext<boolean>(false);
export const leaguePointsContext = createContext<number>(0);

export const PlayerPageFunctional: FC<{}> = (): React.ReactNode => {
    const lookingAt: string = useLocation().pathname?.split('/')[2];

    const [lookingAtID, setLookingAtID] = useState<number>(-1);
    const [lookingAtMMR, setLookingAtMMR] = useState<number>(0);
    const [lookingAtStartingMMR, setLookingAtStartingMMR] = useState<number>(0);
    const [bound, setBound] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [lookingAtLP, setLookingAtLP] = useState<number>(0);

    const [permLevel, setPermLevel] = useState<number>(0);
    const [vip, setVIP] = useState<boolean>(false);

    const loggedIn: boolean = JSON.parse(localStorage.getItem("loggedIn") || 'false');
    const loginInfo: DISCORD_INFO_TYPE = JSON.parse(localStorage.getItem("loginInfo") || '{}');
    const onUserProfile: boolean = loggedIn ? lookingAt === loginInfo.username : false;


    useEffect(() => {
        const fetchPermLevel = async () => {
            const {data: {permLevel}} = await httpCommon.get("/eternalAccounts/perms");
            setPermLevel(permLevel);
        }

        const fetchPlayer = async () => {
            const {data: resultPlayer} = await httpCommon.get("/players/name?discordName=" + lookingAt);
            try {
                const discordID = resultPlayer.discordID;
                setBound(resultPlayer.bound);
                setLookingAtID(discordID);
                setLookingAtMMR(resultPlayer.MMR);
                setLookingAtStartingMMR(resultPlayer.startingMMR);
                setVIP(resultPlayer.VIP);
                setLookingAtLP(resultPlayer.LeaguePoints);
            } catch(err){}
            setLoading(false);
        }

        fetchPermLevel().catch(console.error);
        fetchPlayer().catch(console.error);
    }, [lookingAt, bound]);

    if(loading){
        return (
            <div className = "page">
                <div className="other-page"/>  
                <Loading /> 
            </div>
        )
    }

    return (
        <div className = "page">
            <playerDiscordContext.Provider value={lookingAt}>
                <playerIDContext.Provider value={lookingAtID}>
                    <permLevelContext.Provider value={permLevel}>
                        <onUserProfileContext.Provider value={onUserProfile}>
                            <leaguePointsContext.Provider value={lookingAtLP}>
                                <isVIPContext.Provider value={vip}>
                                    <div className="other-page"/>  
                                    {
                                        bound ? 
                                            <BoundPage 
                                                lookingAtMMR={lookingAtMMR} 
                                                startingMMR={lookingAtStartingMMR}
                                            />
                                        :
                                        <setJustBoundContext.Provider value={setBound}>
                                            <UnBoundPage 
                                                loggedInUser={loginInfo.username} 
                                                discordID={loginInfo.id} 
                                            />
                                        </setJustBoundContext.Provider>
                                    }
                                </isVIPContext.Provider>
                            </leaguePointsContext.Provider>
                        </onUserProfileContext.Provider>
                    </permLevelContext.Provider>
                </playerIDContext.Provider>
            </playerDiscordContext.Provider>
        </div>
    );
}