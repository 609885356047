// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/EEC_Wallpaper.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Manrope&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body, html {
    font-family: 'Manrope', sans-serif;
    font-style: bold;
    color: #76fb9e;
    overscroll-behavior: none;
}

.other-page {
    padding: 0%;
    position: fixed;
    width: 100%;
    height: 100%;
    /* height: 120%; */
    /* background-image: url("./images/eec007_-_Background2.png"); */
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    bottom: 0px;
    z-index: -1;
}


.full-page-div{
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header-div {
    flex: 1 1;
    min-height: 100px;
    min-width: 100%;
    /* position: fixed; */

    /* border: 2px orangered dotted; */
    margin: 1px;
}

.page-div {
    min-height: 80%;
}

.footer-div{ 
    flex: 1 1;
    min-height: 5%;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AAIA;IACI,kCAAkC;IAClC,gBAAgB;IAChB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,eAAe;IACf,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,gEAAgE;IAChE,yDAAmD;IACnD,sBAAsB;IACtB,WAAW;IACX,WAAW;AACf;;;AAGA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,iBAAiB;IACjB,eAAe;IACf,qBAAqB;;IAErB,kCAAkC;IAClC,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,SAAO;IACP,cAAc;AAClB","sourcesContent":["\n@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');\n\nbody, html {\n    font-family: 'Manrope', sans-serif;\n    font-style: bold;\n    color: #76fb9e;\n    overscroll-behavior: none;\n}\n\n.other-page {\n    padding: 0%;\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    /* height: 120%; */\n    /* background-image: url(\"./images/eec007_-_Background2.png\"); */\n    background-image: url('./images/EEC_Wallpaper.png');\n    background-size: cover;\n    bottom: 0px;\n    z-index: -1;\n}\n\n\n.full-page-div{\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n}\n\n.header-div {\n    flex: 1;\n    min-height: 100px;\n    min-width: 100%;\n    /* position: fixed; */\n\n    /* border: 2px orangered dotted; */\n    margin: 1px;\n}\n\n.page-div {\n    min-height: 80%;\n}\n\n.footer-div{ \n    flex: 1;\n    min-height: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
