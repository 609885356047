import React, { FC, useContext, useState } from 'react';
import { Lobby } from '../LobbyTypes.tsx';
import { Card, CardContent, Typography, Paper } from '@mui/material';
import { AddBox } from '@mui/icons-material';
import { timeConverter } from '../../../../../pubFuncs.js';
import { setSelectedLobbyContext } from '../LobbyPanel.tsx';
import { ETERNAL_YELLOW } from '../../../../../globalConsts.ts';

interface Props {
    lobby?: Lobby;
    showCreate?: Function;
}

export const LobbyCard: FC<Props> = ({lobby, showCreate}) : JSX.Element => {
    showCreate = showCreate || (() => console.log('create click'));
    const [fontColor, setFontColor] = useState<string>('white');
    const setSelectedLobby = useContext(setSelectedLobbyContext);

    if(lobby) return (
        <Card className='m-2' component={Paper} elevation={5} sx={{cursor: 'pointer'}} onClick={() => setSelectedLobby(lobby)} onMouseEnter={() => setFontColor(ETERNAL_YELLOW)} onMouseLeave={() => setFontColor('white')}>
            <CardContent>
                <Typography fontSize={14} color={fontColor}>{lobby.name}</Typography>
                {lobby.autopollUntil ?
                    <Typography fontFamily='unset' fontSize={12}> 
                        Autopolling until: {timeConverter(lobby.autopollUntil)}
                    </Typography>
                    : "" 
                }
            </CardContent>
        </Card>
    )

    return (
        <Card component={Paper} elevation={5} className='m-2' sx={{cursor: 'pointer'}} onClick={() => showCreate(true)} onMouseEnter={() => setFontColor(ETERNAL_YELLOW)} onMouseLeave={() => setFontColor('white')}>
            <Typography fontSize={14} color={fontColor}>
                <CardContent>
                    <AddBox /> New Lobby
                </CardContent>
            </Typography>
        </Card>
    )
}