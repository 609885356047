import React, { useContext } from "react";
import {Grid, Typography} from '@mui/material';
import { MobileContext } from "../../../AppFunctional";
import { ETERNAL_GREEN } from "../../globalConsts.ts";

export const NotInDiscord = () => {
    const isMobile = useContext(MobileContext);
    return (
        <div>
            <div className="other-page"/>
            <Grid container spacing={2}>
                <Grid item xs={isMobile ? 1 : 3} />
                <Grid item xs={isMobile ? 10 : 6}>
                    <Typography color="white" fontSize={45} textAlign='center' marginTop={isMobile ? 0 : 25}>
                        Please join the <a style={{color: ETERNAL_GREEN}} className="glow" href="https://discord.gg/eternaleec">eternal esports Discord</a> then resignin to access our website.
                    </Typography>
                </Grid>
                <Grid item xs={isMobile ? 1 : 3} />
            </Grid>
        </div>
    )
}