import React from "react";
import {Card, CardContent, Grid } from "@mui/material";
import { useNavigate } from "react-router";

export const VIPCard = ({vip, vipTeammates}) => {
    const vips_teammates = vipTeammates.filter(t => t.vip_id === vip.discordID);
    const navigate = useNavigate();

    return(
        <Card sx={{height: 150}}>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} className="small-glow" 
                        sx={{fontSize: '1.2em', fontFamily: "Orbitron", textWrap: "nowrap", overflow: "hidden"}}
                        onClick={() => navigate('../player/'+vip.discordName)}
                    >
                        {vip.discordName}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {
                                vips_teammates.map((teammate) => 
                                    <Grid item xs={12} onClick={() =>  navigate('../player/'+teammate.vip_name)}> • {teammate.vip_name}</Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}