import React, { useEffect, useState, useContext } from "react";
import { StatsCard } from "../../PlayerPage/PlayerPageComponents/StatsCard.tsx";
import httpCommon from "../../../../http-common";
import { Loading } from "../../MiscComponents/Loading";
import {Pagination, Box} from "@mui/material"
import { MobileContext } from "../../../../AppFunctional";

export const PlayerCard = ({players}) => {
    const [statMap, setStatMap] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedPlayer, setSelectedPlayer] = useState(-1);

    const isMobile = useContext(MobileContext);

    let width = 500;
    if(isMobile)
        width = 'auto';

    useEffect(() => {
        const fetchPlayerStats = async () => {
            const {data: resultStats} = await httpCommon.get("/match_data/stats?discordID=" + selectedPlayer.discordID);
            const numGames = resultStats.numGames || 0;
            const total_kills = resultStats.kills || 0;
            const total_dmg = resultStats.damageDealt || 0;
            const total_placement = resultStats.teamPlacement || 0;
            const character_list = resultStats.characterName || [];
            const avg_accuracy = (resultStats.hits || 0) / (resultStats.shots || 1) * 100;

            const avg_kills = total_kills / numGames;
            const avg_dmg = total_dmg / numGames;
            const avg_placement = total_placement / numGames;

            const statMap = new Map();
            statMap.set('avg_kills', Number((avg_kills).toFixed(1)));
            statMap.set('avg_dmg',  Number((avg_dmg).toFixed(1)));
            statMap.set('avg_acc',  Number((avg_accuracy).toFixed(1)));
            statMap.set('char', character_list);
            statMap.set('num_matches', numGames);
            statMap.set('avg_place', Number((avg_placement).toFixed(1)));
            statMap.set('total_kills', total_kills);
            statMap.set('total_dmg', total_dmg);
            statMap.set('playerNames', resultStats.playerName || []);

            setStatMap(statMap);
            setLoading(false);
        }

        if(selectedPlayer !== -1)
            fetchPlayerStats().catch(console.error);
    }, [selectedPlayer]);

    if(players.length > 0 && selectedPlayer===-1)
        setSelectedPlayer(players[0]);

    if(selectedPlayer===-1) return ""

    if(loading){
        return <Loading />
    }

    return (
        <Box className="ms-2">
            <StatsCard statMap={statMap} title={selectedPlayer.discordName} width={width} isTeamPage={true}/>
            <Pagination className="m-1" onChange={(event, value) => setSelectedPlayer(players[value - 1])} count={players.length} variant="outlined"/>
        </Box>
    )
}