import React, {createContext, useContext, useEffect, useState} from "react";
import { Grid, Tabs, Tab, Card, CardContent } from '@mui/material';
import httpCommon from "../../../http-common";
import { NotLoggedInPage } from "./NotLoggedInPage";
import { Loading } from "../MiscComponents/Loading";
import { MissingPermsPage } from "./MissingPermsPage";
import { MobileContext } from "../../../AppFunctional";
import { PlayerAccounts } from "./AdminPanelComponents/PlayerAccounts/PlayerAccounts";
import { Checkin } from "./AdminPanelComponents/Checkin/Checkin";
import { useNavigate } from "react-router-dom";
import { Tokens } from "./AdminPanelComponents/Tokens/Tokens";
import { Teams } from "./AdminPanelComponents/Teams/Teams";
import { Jail } from "./AdminPanelComponents/Jail/Jail";
import { HelpInbox } from "./AdminPanelComponents/HelpInbox/HelpInbox";
import { ETERNAL_GREEN } from "../../globalConsts.ts";
import { LobbyPanel } from "./AdminPanelComponents/LobbyPanel/LobbyPanel.tsx";
import { TabBadge } from "../Badge/CustomBadge.tsx";

export const adminPermsContext = createContext(0);
export const adminPanelPlayers = createContext([]);

export const AdminPanelFunctional = () => {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    const loggedIn = JSON.parse(localStorage.getItem("loggedIn"));
    const isMobile = useContext(MobileContext);
    const width = isMobile ? "100%" : "80%";

    const [permLevel, setPermLevel] = useState(0);
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState(urlParams.get('tab') || 'accounts');

    const [listOfPlayers, setListOfPlayers] = useState([]);
    const [numOpenTickets, setNumOpenTickets] = useState(0);
    

    useEffect(() => {
        const fetchPerms = async () => {
            const {data: {permLevel}} = await httpCommon.get("/eternalAccounts/perms");
            setPermLevel(permLevel);
            await fetchListOfPlayers();
            await fetchOpenTickets();
            setLoading(false);
        }

        const fetchListOfPlayers = async () => {
            const {data: listOfPlayers} = await httpCommon.get('/eternalaccounts/discordmembers');
            setListOfPlayers(listOfPlayers);
        }

        const fetchOpenTickets = async () => {
            const {data: {numTickets}} = await httpCommon.get('/help/num');
            setNumOpenTickets(numTickets);
        }

        fetchPerms().catch(console.error);
    }, []);

    const tabOnClick = (value) => {
        setTab(value);
        urlParams.set('tab', value)
        navigate('/adminpanel?'+urlParams, { replace: true })
    }

    // various cases of other pages 
    if(loading) return (<div className="page"><div className="other-page"><Loading/></div></div>)
    if(!loggedIn) return (<NotLoggedInPage />);
    if(permLevel < 4) return (<MissingPermsPage />);

    

    return (
        <div className = "page">
            <div className="other-page"/> 
            <adminPermsContext.Provider value={permLevel}>
                <adminPanelPlayers.Provider value={listOfPlayers}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} className="glow" style={{fontSize: '3em', fontFamily: "Orbitron", textAlign: "center"}}>Admin Panel</Grid>
                        <Grid item xs={12}>
                            <Card style={{width, margin: "auto", height:"80vh"}}>
                                <CardContent>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Tabs value={tab} variant="scrollable" scrollButtons={true} allowScrollButtonsMobile 
                                                onChange ={(event, value) => tabOnClick(value)} textColor='inherit'
                                                TabIndicatorProps={{style: {backgroundColor: ETERNAL_GREEN, height: 3}}}
                                            >
                                                <Tab label="Accounts" value="accounts"/>
                                                <Tab label="Checkin" value="checkin"/>
                                                <Tab label="Tokens" value="tokens"/>
                                                <Tab label="Teams" value="teams"/>
                                                <Tab label={
                                                    <TabBadge badgeContent={permLevel > 2 ? numOpenTickets : 0} color='eternal' archorOrigin={{vertical: 'top'}} overlap="circular">
                                                        Help Inbox
                                                    </TabBadge>
                                                } value="help"/>
                                                <Tab label="JAIL" value="jail"/>
                                                <Tab label="Lobbies" value='lobbies' />
                                            </Tabs>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {tab === "accounts" && <PlayerAccounts />}
                                            {tab === "checkin" && <Checkin />}
                                            {tab === "tokens" && <Tokens />}
                                            {tab === "teams" && <Teams />}
                                            {tab === "help" && <HelpInbox />}
                                            {tab === "jail" && <Jail />}
                                            {tab === 'lobbies' && <LobbyPanel />}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card> 
                        </Grid>
                    </Grid>
                </adminPanelPlayers.Provider>
            </adminPermsContext.Provider>
        </div>
    )

}