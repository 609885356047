import React, { useState } from "react";
import {TableCell, TextField} from "@mui/material";
import httpCommon from "../../../../../../http-common";

export const MMRCell = ({account, isStarting, fetchFullAccounts, isStaff}) => {
    const [editText, setEditText] = useState(isStarting ? account.startingMMR : account.MMR);

    const handleSubmit = async () => {
        try {
            const newMMR = editText === "" ? 0 : parseInt(editText);
            const url = isStarting ? "/players/setStartingMMR" : "/players/setMMR";
            await httpCommon.post(url, {discordID: account.discordID, newMMR});
            fetchFullAccounts();
        } catch(error){
            console.log(error);
        }
    }

    return (
        <TableCell>
            <TextField
                type="tel"
                size="small"
                color='eternal'
                value={editText}
                label={isStarting ? "Starting MMR" : "MMR"}
                onKeyDown={(k) => {if(k.key === 'Enter'){handleSubmit()}}}
                onChange={(event) => setEditText(event.target.value)} 
                sx={{width: 100}}
                disabled={isStaff}
            />
        </TableCell>
    )
}